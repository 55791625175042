// useSearch.js

import { useState, useEffect } from 'react';
import { ref, get } from 'firebase/database';
import { realtimeDb } from '../firebase';
import { states as allStates, cities } from '../components/data/statesAndCities'; // Importing state data

const useSearch = () => {
    const [state, setState] = useState(null);
    const [city, setCity] = useState(null);
    const [filteredCities, setFilteredCities] = useState(cities);
    const [filteredCars, setFilteredCars] = useState([]);
    const [cars, setCars] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [make, setMake] = useState(null);
    const [model, setModel] = useState(null);
    const [filteredModels, setFilteredModels] = useState([]);
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [carMakes, setCarMakes] = useState([]); // For storing unique car makes
    const [isSearchTriggered, setIsSearchTriggered] = useState(false);
    const [cryptoAccepted, setCryptoAccepted] = useState(false); // 🔹 Crypto filter

    const states = allStates;

    // Fetch cars from Firebase
    useEffect(() => {
        const fetchCarsFromFirebase = async () => {
            setLoading(true);
            try {
                const carsRef = ref(realtimeDb, 'listings');
                const snapshot = await get(carsRef);
                if (snapshot.exists()) {
                    const carData = snapshot.val();
                    const carArray = Object.keys(carData).reduce((acc, userId) => {
                        const userListings = Object.keys(carData[userId]).map(listingId => {
                            const listing = carData[userId][listingId];

                            // Ensure cryptoAccepted is always a boolean
                            return {
                                id: listingId,
                                ...listing,
                                cryptoAccepted: listing.cryptoAccepted === true, // Ensure boolean value
                            };
                        });
                        return [...acc, ...userListings];
                    }, []);
                    
                    setCars(carArray);
                    
                    // Extract unique car makes from the cars data
                    const uniqueMakes = [...new Set(carArray.map(car => car.make))].filter(Boolean);
                    const formattedMakes = uniqueMakes.map(make => ({ label: make }));
                    setCarMakes(formattedMakes);
                } else {
                    console.error('No car data available');
                }
            } catch (error) {
                console.error('Error fetching car data:', error);
            }
            setLoading(false);
        };

        fetchCarsFromFirebase();
    }, []);

    // Handle state selection and update available cities
    const handleStateChange = (event, value) => {
        setState(value);
        if (value) {
            const filtered = cities.filter(city => city.state === value.label);
            setFilteredCities(filtered);
        } else {
            setFilteredCities(cities);
        }
        setCity(null);
    };

    // Handle make selection and update available models
    const handleMakeChange = (event, value) => {
        setMake(value);
        const models = cars
            .filter(car => car.make && car.make.startsWith(value.label))
            .map(car => car.model || '');
        const uniqueModels = [...new Set(models)].map(model => ({ label: model }));
        setFilteredModels(uniqueModels);
        setModel(null);
    };

    // Initiate search
    const handleSearch = () => {
        setIsSearchTriggered(true);
    };

    // Apply filters when search is triggered
    useEffect(() => {
        if (!isSearchTriggered) return;

        setLoading(true);
        let filtered = cars;

        if (state) {
            filtered = filtered.filter(car => car.state && car.state.trim().toLowerCase() === state.label.toLowerCase());
        }
        if (city) {
            filtered = filtered.filter(car => car.city && car.city.trim().toLowerCase() === city.label.toLowerCase());
        }
        if (make) {
            filtered = filtered.filter(car => car.make && car.make.startsWith(make.label));
        }
        if (model) {
            filtered = filtered.filter(car => car.model && car.model.includes(model.label));
        }
        if (minPrice) {
            filtered = filtered.filter(car => car.pricePerDay && car.pricePerDay >= parseFloat(minPrice));
        }
        if (maxPrice) {
            filtered = filtered.filter(car => car.pricePerDay && car.pricePerDay <= parseFloat(maxPrice));
        }
        if (cryptoAccepted) {
            filtered = filtered.filter(car => car.cryptoAccepted === true);
        }

        setFilteredCars(filtered);
        setLoading(false);
        setIsSearchTriggered(false);
    }, [isSearchTriggered, state, city, make, model, minPrice, maxPrice, cryptoAccepted, cars]);

    return {
        state,
        setState,
        city,
        setCity,
        filteredCities,
        filteredCars,
        cars,
        loading,
        showAdvanced,
        setShowAdvanced,
        make,
        setMake,
        model,
        setModel,
        minPrice,
        setMinPrice,
        maxPrice,
        setMaxPrice,
        filteredModels,
        carMakes,
        handleStateChange,
        handleMakeChange,
        handleSearch,
        states,
        cryptoAccepted, // 🔹 Return crypto filter state
        setCryptoAccepted, // 🔹 Return function to update it
    };
};

export default useSearch;





// import { useState, useEffect } from 'react';
// import { ref, get } from 'firebase/database';
// import { realtimeDb } from '../firebase';
// import { states as allStates, cities } from '../components/data/statesAndCities'; // Importing state data

// const useSearch = () => {
//     const [state, setState] = useState(null);
//     const [city, setCity] = useState(null);
//     const [filteredCities, setFilteredCities] = useState(cities);
//     const [filteredCars, setFilteredCars] = useState([]);
//     const [cars, setCars] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [showAdvanced, setShowAdvanced] = useState(false);
//     const [make, setMake] = useState(null);
//     const [model, setModel] = useState(null);
//     const [filteredModels, setFilteredModels] = useState([]);
//     const [minPrice, setMinPrice] = useState('');
//     const [maxPrice, setMaxPrice] = useState('');
//     const [carMakes, setCarMakes] = useState([]); // For storing unique car makes
//     const [isSearchTriggered, setIsSearchTriggered] = useState(false);

//     const states = allStates;

//     // Fetch cars from Realtime Database and extract unique car makes
//     useEffect(() => {
//         const fetchCarsFromFirebase = async () => {
//             setLoading(true);
//             try {
//                 const carsRef = ref(realtimeDb, 'listings');
//                 const snapshot = await get(carsRef);
//                 if (snapshot.exists()) {
//                     const carData = snapshot.val();
//                     const carArray = Object.keys(carData).reduce((acc, userId) => {
//                         const userListings = Object.keys(carData[userId]).map(listingId => ({
//                             id: listingId,
//                             ...carData[userId][listingId],
//                         }));
//                         return [...acc, ...userListings];
//                     }, []);
                    
//                     setCars(carArray);
                    
//                     // Extract unique car makes from the cars data
//                     const uniqueMakes = [...new Set(carArray.map(car => car.make))].filter(Boolean);
//                     const formattedMakes = uniqueMakes.map(make => ({ label: make }));
//                     setCarMakes(formattedMakes); // Set carMakes for the dropdown
//                 } else {
//                     console.error('No car data available');
//                 }
//             } catch (error) {
//                 console.error('Error fetching car data:', error);
//             }
//             setLoading(false);
//         };

//         fetchCarsFromFirebase();
//     }, []);

//     const handleStateChange = (event, value) => {
//         setState(value);
//         if (value) {
//             const filtered = cities.filter(city => city.state === value.label);
//             setFilteredCities(filtered);
//         } else {
//             setFilteredCities(cities);
//         }
//         setCity(null);
//     };

//     const handleMakeChange = (event, value) => {
//         setMake(value);
//         const models = cars
//             .filter(car => car.make && car.make.startsWith(value.label))
//             .map(car => car.model || '');
//         const uniqueModels = [...new Set(models)].map(model => ({ label: model }));
//         setFilteredModels(uniqueModels);
//         setModel(null);
//     };

//     const handleSearch = () => {
//         setIsSearchTriggered(true);
//     };

//     useEffect(() => {
//         if (!isSearchTriggered) return;

//         setLoading(true);
//         let filtered = cars;

//         if (state) {
//             filtered = filtered.filter(car => car.state && car.state.trim().toLowerCase() === state.label.toLowerCase());
//         }
//         if (city) {
//             filtered = filtered.filter(car => car.city && car.city.trim().toLowerCase() === city.label.toLowerCase());
//         }
//         if (make) {
//             filtered = filtered.filter(car => car.make && car.make.startsWith(make.label));
//         }
//         if (model) {
//             filtered = filtered.filter(car => car.model && car.model.includes(model.label));
//         }
//         if (minPrice) {
//             filtered = filtered.filter(car => car.pricePerDay && car.pricePerDay >= parseFloat(minPrice));
//         }
//         if (maxPrice) {
//             filtered = filtered.filter(car => car.pricePerDay && car.pricePerDay <= parseFloat(maxPrice));
//         }

//         setFilteredCars(filtered);
//         setLoading(false);
//         setIsSearchTriggered(false);
//     }, [isSearchTriggered, state, city, make, model, minPrice, maxPrice, cars]);

//     return {
//         state,
//         setState,
//         city,
//         setCity,
//         filteredCities,
//         filteredCars,
//         cars,
//         loading,
//         showAdvanced,
//         setShowAdvanced,
//         make,
//         setMake,
//         model,
//         setModel,
//         minPrice,
//         setMinPrice,
//         maxPrice,
//         setMaxPrice,
//         filteredModels,
//         carMakes, // Make sure carMakes is returned
//         handleStateChange,
//         handleMakeChange,
//         handleSearch,
//         states,
//     };
// };

// export default useSearch;







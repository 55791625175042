// textValidation.jsx

// textValidation.js

/**
 * Checks if the text contains any potential email addresses or phone numbers.
 * Returns true if contact info is found, otherwise false.
 */
export function hasContactInfo(text) {
    if (!text) return false;
  
    // Very basic email pattern:
    const emailRegex = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/i;
  
    // A simple phone pattern (international optional, parentheses, dashes/spaces)
    // Matches forms like: 123-456-7890, (123) 456 7890, +1 123 456 7890, etc.
    const phoneRegex = /(\+?\d{1,3}[-.\s()]*)?(?:\(\d{1,4}\)|\d{1,4})[-.\s()]*\d{1,4}[-.\s()]*\d{1,4}[\s]*(?:ext|x)?\.?\s*\d{0,4}/i;
  
    // Check for email or phone in the text
    return emailRegex.test(text) || phoneRegex.test(text);
  }
  
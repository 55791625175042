// leadUtils.jsx



import React from "react";
import { Modal, Typography, Box, Button, TextField, IconButton, ListItemText } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { StyledListItem, StyledDivider, LeadQualityBadge, ModalContent } from "./PendingRentalsStyles";

// --- Utility Functions ---

export const formatCustomerData = (name, state, phone) => {
  const firstName = name.split(" ")[0];
  const lastTwoDigitsOfPhone = phone.slice(-2);
  return `${firstName} from ${state}, Phone ending in ${lastTwoDigitsOfPhone}`;
};

export const getLeadQualityCategory = (score) => {
  if (score >= 80) return { label: "🔥 Excellent", color: "#4caf50" }; // Green
  if (score >= 60) return { label: "✅ Good", color: "#2196f3" }; // Blue
  if (score >= 40) return { label: "⚠️ Fair", color: "#ff9800" }; // Orange
  if (score > 0) return { label: "❌ Low", color: "#f44336" }; // Red
  return { label: "N/A", color: "#9e9e9e" }; // Gray for missing values
};


// --- Filtering Functions ---

export const filterExclusiveLeads = (pendingRentalsData) => {
    const now = new Date();
    return pendingRentalsData.filter((rental) => {
      const timeDiff = now - new Date(rental.createdAt);
      return timeDiff <= 72 * 60 * 60 * 1000 && rental.unlockedCount === 0;
    });
  };
  
  export const filterMarketLeads = (pendingRentalsData, exclusiveLeads) => {
    // Exclude exclusive leads from the market leads
    const exclusiveLeadIds = new Set(exclusiveLeads.map((lead) => lead.id));
  
    return pendingRentalsData.filter((rental) => {
      return !exclusiveLeadIds.has(rental.id); // Remove duplicates
    });
  };
  
// --- Presentational Components ---

// Reusable list item for each rental lead
export const RentalItem = ({ rental, cost, onOpen, onAddToCart, isFree, section }) => {
  // const leadQualityCategory = getLeadQualityCategory(rental.leadQualityScore);
  // ✅ Get lead quality label & color
  const leadQuality = getLeadQualityCategory(rental.leadQualityScore);
  // Set color based on section
  const textColor = section === "market" ? "#f72585" : "#23d5ab";


  return (
    <>
      <StyledListItem>
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Box onClick={() => onOpen(rental)} flexGrow={1}>
            <ListItemText
              primary={formatCustomerData(rental.customerName, rental.state, rental.phone)}
              secondary={
                <>
                  {/* Display Vehicle Details */}
                  <span style={{ display: "block", color: textColor }}>
                    {`Requested Rental: ${rental.brand} ${rental.model}`}
                  </span>
                  <span style={{ display: "block", color: textColor }}>
                    {`Renting in: ${rental.rentingCity}, ${rental.rentingState}`}
                  </span>

                  {/* Display Rental Duration */}
                  <span style={{ display: "block", color: textColor }}>
                    {`Rental Duration: ${rental.numberOfDays} days`}
                  </span>

                  {/* ✅ Show Lead Quality Text for ALL Leads */}
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", color: leadQuality.color, marginTop: "4px" }}
                  >
                    Lead Quality: {leadQuality.label}
                  </Typography>

                  {/* ✅ Show Lead Quality Badge for Exclusive Leads Only */}
                  {section === "exclusive" && (
                    <LeadQualityBadge quality={leadQuality.label}>
                      {`Lead Quality: ${leadQuality.label}`}
                    </LeadQualityBadge>
                  )}

                  {/* Show the cost */}
                  <span style={{ display: "block", color: textColor, fontWeight: "bold" }}>
                    Cost: {isFree ? "FREE" : `$${cost.toFixed(2)}`}
                  </span>
                </>
              }
              primaryTypographyProps={{ style: { color: "#fff", fontWeight: "bold" } }}
            />
          </Box>

          {/* Cost Display */}
          <Typography variant="body2" sx={{ marginRight: "15px", fontWeight: "bold", color: textColor }}>
            {`Cost: $${cost.toFixed(2)}`}
          </Typography>

          {/* Action Buttons */}
          {isFree ? (
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                onOpen(rental);
              }}
              style={{ backgroundColor: textColor }}
            >
              Open Lead
            </Button>
          ) : (
            <IconButton
              edge="end"
              onClick={(e) => {
                e.stopPropagation();
                onAddToCart(rental);
              }}
              style={{ color: textColor }}
            >
              <ShoppingCartIcon />
            </IconButton>
          )}
        </Box>
      </StyledListItem>
      <StyledDivider />
    </>
  );
};
// Modal component for approving/declining a rental lead
export const RentalModal = ({
  selectedRental,
  declineReason,
  setDeclineReason,
  handleApprove,
  handleDecline,
  handleClose,
}) => {
  return (
    <Modal open={Boolean(selectedRental)} onClose={handleClose}>
      <ModalContent>
        {selectedRental && (
          <>
            <Typography variant="h6" gutterBottom>
              Approve or Deny Rental
            </Typography>
            <Typography variant="body1" gutterBottom>
              {formatCustomerData(selectedRental.customerName, selectedRental.state, selectedRental.phone)}
            </Typography>
            <Typography variant="body2" gutterBottom>
              Car: {selectedRental.brand} {selectedRental.model}
            </Typography>
            <Typography variant="body2" gutterBottom>
              Rental Period: {selectedRental.fromDate} to {selectedRental.toDate}
            </Typography>
            <Typography variant="body2" gutterBottom>
              Number of Days: {selectedRental.numberOfDays}
            </Typography>
            <Box mt={3} display="flex" justifyContent="center" gap={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleApprove}
                style={{ backgroundColor: "#23d5ab", boxShadow: "0 0 10px #23d5ab" }}
              >
                Approve
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDecline}
                style={{ backgroundColor: "#f72585", boxShadow: "0 0 10px #f72585" }}
              >
                Decline
              </Button>
            </Box>
            <Box mt={2}>
              <Typography variant="subtitle1" gutterBottom>
                Reason for Decline:
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                rows={3}
                value={declineReason}
                onChange={(e) => setDeclineReason(e.target.value)}
                placeholder="Enter reason..."
                sx={{
                  backgroundColor: "#222",
                  color: "#fff",
                  "& .MuiInputBase-input": { color: "#fff" },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "#23d5ab" },
                    "&:hover fieldset": { borderColor: "#f72585" },
                    "&.Mui-focused fieldset": { borderColor: "#23d5ab" },
                  },
                }}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDecline}
                style={{ backgroundColor: "#f72585", boxShadow: "0 0 10px #f72585", marginTop: "10px" }}
              >
                Confirm Decline
              </Button>
            </Box>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

// PendingRentals.jsx



import React, { useState } from "react";
import { Typography, List } from "@mui/material";
import { StyledBox, NeonText, SubText } from "./PendingRentalsStyles";
import { useNavigate } from "react-router-dom";
import { useCart } from "../../context/CartContext";
import calculateLeadCost from "../../utils/leadCostCalculator";
import useFetchLeads from "../../hooks/useFetchLeads";
import PurchaseModal from "../purchase/PurchaseModal";

// Helper functions / components
import {
  filterExclusiveLeads,
  RentalItem,
  RentalModal,
  filterMarketLeads
} from "./leadUtils";

const PendingRentals = () => {
  const [purchaseModalOpen, setPurchaseModalOpen] = useState(false);
  const [selectedRental, setSelectedRental] = useState(null);
  const [declineReason, setDeclineReason] = useState("");

  const navigate = useNavigate();
  const { addToCart } = useCart();

  // Removed userGeneratedLeads usage from here
  const {
    leads: pendingRentalsData,
    marketLeads,
    loading,
    error,
    declineLead,
    currentUser,
  } = useFetchLeads();

  if (loading) return <Typography>Loading leads...</Typography>;
  if (error) return <Typography>Error loading leads: {error}</Typography>;
  if (!pendingRentalsData || pendingRentalsData.length === 0) {
    return <Typography>No pending rentals found.</Typography>;
  }

  const handleOpen = (rental) => {
    if (!rental) {
      console.error("Tried to open a null rental!");
      return;
    }

    const leadCost = calculateLeadCost(
      rental.dailyRate,
      rental.numberOfDays,
      1,
      rental.unlockedCount,
      rental.leadQualityScore,
      rental.createdAt,
      rental.ownerId,
      currentUser?.uid
    );

    if (leadCost === 0) {
      setSelectedRental({ ...rental, price: 0 });
      setPurchaseModalOpen(true);
    } else {
      setSelectedRental(rental);
    }
    setDeclineReason("");
  };

  const handleClose = () => {
    setSelectedRental(null);
    setPurchaseModalOpen(false);
  };

  const handleApprove = () => {
    if (!selectedRental) {
      console.error("No rental selected!");
      return;
    }

    const leadCost = calculateLeadCost(
      selectedRental.dailyRate,
      selectedRental.numberOfDays,
      1,
      selectedRental.unlockedCount,
      selectedRental.leadQualityScore,
      selectedRental.createdAt,
      selectedRental.ownerId,
      currentUser?.uid
    );

    if (leadCost === 0) {
      setSelectedRental({ ...selectedRental, price: 0 });
      return;
    }

    navigate("/purchase", {
      state: {
        cartItems: [{ ...selectedRental, price: leadCost }],
        totalPrice: leadCost,
      },
    });

    handleClose();
  };

  const handleDecline = async () => {
    if (!declineReason.trim()) {
      alert("Please provide a reason for declining the lead.");
      return;
    }
    await declineLead(selectedRental.id, declineReason);
    handleClose();
  };

  const handleAddToCart = (rental) => {
    const leadCost = calculateLeadCost(
      rental.dailyRate,
      rental.numberOfDays,
      1,
      rental.unlockedCount,
      rental.leadQualityScore,
      rental.createdAt
    );

    const rentalItem = {
      id: rental.id,
      name: `${rental.brand} ${rental.model}`,
      dailyRate: rental.dailyRate,
      numberOfDays: rental.numberOfDays,
      isExclusive: rental.unlockedCount === 0,
      unlockedCount: rental.unlockedCount,
      leadQualityScore: rental.leadQualityScore,
      price: leadCost,
      rentingCity: rental.rentingCity,
      rentingState: rental.rentingState,
    };

    addToCart(rentalItem);
  };

  // Separate exclusive leads from the rest
  const exclusiveLeads = filterExclusiveLeads(pendingRentalsData);

  // Exclude duplicates from market leads
  const cleanMarketLeads = filterMarketLeads(marketLeads, exclusiveLeads);

  
  return (
    <StyledBox>
      <NeonText variant="h4">Pending Leads</NeonText>
      <SubText variant="subtitle1">
        These are your rental leads awaiting confirmation:
      </SubText>

      {/* Exclusive Leads Section */}
      <Typography variant="h6" sx={{ color: "#23d5ab", marginBottom: "10px" }}>
        Exclusive Leads (72 hours):
      </Typography>
      <List>
        {exclusiveLeads.map((rental) => {
          const leadCost = calculateLeadCost(
            rental.dailyRate,
            rental.numberOfDays,
            1,
            rental.unlockedCount,
            rental.leadQualityScore,
            rental.createdAt
          );

          return (
            <RentalItem
              key={rental.id}
              rental={rental}
              cost={leadCost}
              onOpen={handleOpen}
              onAddToCart={handleAddToCart}
              isFree={leadCost === 0}
              section="exclusive"
            />
          );
        })}
      </List>

      {/* Market Leads Section */}
      <Typography
        variant="h6"
        sx={{ color: "#f72585", marginBottom: "10px", marginTop: "20px" }}
      >
        Market Leads:
      </Typography>
      <List>
        {cleanMarketLeads.map((rental) => {
          const leadCost = calculateLeadCost(
            rental.dailyRate,
            rental.numberOfDays,
            1,
            rental.unlockedCount,
            rental.leadQualityScore,
            rental.createdAt
          );

          return (
            <RentalItem
              key={rental.id}
              rental={rental}
              cost={leadCost}
              onOpen={handleOpen}
              onAddToCart={handleAddToCart}
              isFree={false}
              section="market"
            />
          );
        })}
      </List>

      {/* Approve/Decline Modal */}
      <RentalModal
        selectedRental={selectedRental}
        declineReason={declineReason}
        setDeclineReason={setDeclineReason}
        handleApprove={handleApprove}
        handleDecline={handleDecline}
        handleClose={handleClose}
      />

      {/* Purchase Modal */}
      {purchaseModalOpen && selectedRental && (
        <PurchaseModal
          modalOpen={purchaseModalOpen}
          handleModalClose={handleClose}
          cartItems={[selectedRental]}
          totalPrice={0}
          copied={false}
          handleCopyToClipboard={() => console.log("Copy to clipboard triggered")}
        />
      )}
    </StyledBox>
  );
};

export default PendingRentals;


// import React, { useState } from "react";
// import { Typography, List, Box } from "@mui/material";
// import { StyledBox, NeonText, SubText } from "./PendingRentalsStyles";
// import { useNavigate } from "react-router-dom";
// import { useCart } from "../../context/CartContext";
// import calculateLeadCost from "../../utils/leadCostCalculator";
// import useFetchLeads from "../../hooks/useFetchLeads";
// import PurchaseModal from "../purchase/PurchaseModal";

// // Import the helper functions and components from leadUtils.jsx
// import {
//   filterExclusiveLeads,
//   RentalItem,
//   RentalModal,
//   filterMarketLeads
// } from "./leadUtils";

// const PendingRentals = () => {
//   const [purchaseModalOpen, setPurchaseModalOpen] = useState(false);
//   const [selectedRental, setSelectedRental] = useState(null);
//   const [declineReason, setDeclineReason] = useState("");
  
//   const navigate = useNavigate();
//   const { addToCart } = useCart();
//   const {
//     leads: pendingRentalsData,
//     userGeneratedLeads,
//     marketLeads,
//     loading,
//     error,
//     declineLead,
//     currentUser,
//   } = useFetchLeads();

//   if (loading) return <Typography>Loading leads...</Typography>;
//   if (error) return <Typography>Error loading leads: {error}</Typography>;
//   if (!pendingRentalsData || pendingRentalsData.length === 0) {
//     return <Typography>No pending rentals found.</Typography>;
//   }

//   const handleOpen = (rental) => {
//     if (!rental) {
//       console.error("Tried to open a null rental!");
//       return;
//     }

//     const leadCost = calculateLeadCost(
//       rental.dailyRate,
//       rental.numberOfDays,
//       1,
//       rental.unlockedCount,
//       rental.leadQualityScore,
//       rental.createdAt,
//       rental.ownerId,
//       currentUser?.uid
//     );

//     if (leadCost === 0) {
//       setSelectedRental({ ...rental, price: 0 });
//       setPurchaseModalOpen(true);
//     } else {
//       setSelectedRental(rental);
//     }
//     setDeclineReason("");
//   };

//   const handleClose = () => {
//     setSelectedRental(null);
//     setPurchaseModalOpen(false);
//   };

//   const handleApprove = () => {
//     if (!selectedRental) {
//       console.error("No rental selected!");
//       return;
//     }

//     const leadCost = calculateLeadCost(
//       selectedRental.dailyRate,
//       selectedRental.numberOfDays,
//       1,
//       selectedRental.unlockedCount,
//       selectedRental.leadQualityScore,
//       selectedRental.createdAt,
//       selectedRental.ownerId,
//       currentUser?.uid
//     );

//     if (leadCost === 0) {
//       setSelectedRental({ ...selectedRental, price: 0 });
//       return;
//     }

//     navigate("/purchase", {
//       state: {
//         cartItems: [{ ...selectedRental, price: leadCost }],
//         totalPrice: leadCost,
//       },
//     });

//     handleClose();
//   };

//   const handleDecline = async () => {
//     if (!declineReason.trim()) {
//       alert("Please provide a reason for declining the lead.");
//       return;
//     }
//     await declineLead(selectedRental.id, declineReason);
//     handleClose();
//   };

//   const handleAddToCart = (rental) => {
//     const leadCost = calculateLeadCost(
//       rental.dailyRate,
//       rental.numberOfDays,
//       1,
//       rental.unlockedCount,
//       rental.leadQualityScore,
//       rental.createdAt
//     );

//     const rentalItem = {
//       id: rental.id,
//       name: `${rental.brand} ${rental.model}`,
//       dailyRate: rental.dailyRate,
//       numberOfDays: rental.numberOfDays,
//       isExclusive: rental.unlockedCount === 0,
//       unlockedCount: rental.unlockedCount,
//       leadQualityScore: rental.leadQualityScore,
//       price: leadCost,
//       rentingCity: rental.rentingCity,
//       rentingState: rental.rentingState,
//     };

//     addToCart(rentalItem);
//   };

//   // Use utility to filter exclusive leads
//   // Use utility to filter exclusive leads
//   const exclusiveLeads = filterExclusiveLeads(pendingRentalsData);

//   // Use new utility function to exclude duplicates in Market Leads
//   const cleanMarketLeads = filterMarketLeads(marketLeads, exclusiveLeads);


//   return (
//     <StyledBox>
//       <NeonText variant="h4">Pending Leads</NeonText>
//       <SubText variant="subtitle1">
//         These are your rental leads awaiting confirmation:
//       </SubText>

//       {/* Exclusive Leads Section */}
//       <Typography variant="h6" sx={{ color: "#23d5ab", marginBottom: "10px" }}>
//         Exclusive Leads (72 hours):
//       </Typography>
//       <List>
//         {exclusiveLeads.map((rental) => {
//           const leadCost = calculateLeadCost(
//             rental.dailyRate,
//             rental.numberOfDays,
//             1,
//             rental.unlockedCount,
//             rental.leadQualityScore,
//             rental.createdAt
//           );
//           return (
//             <RentalItem
//               key={rental.id}
//               rental={rental}
//               cost={leadCost}
//               onOpen={handleOpen}
//               onAddToCart={handleAddToCart}
//               isFree={leadCost === 0}
//               section="exclusive"
//             />
//           );
//         })}
//       </List>

//       {/* User-Generated Leads Section */}
//       {userGeneratedLeads.length > 0 && (
//         <>
//           <Typography variant="h6" sx={{ color: "#23d5ab", marginBottom: "10px" }}>
//             Your Vehicle Listing Leads:
//           </Typography>
//           <List>
//             {userGeneratedLeads.map((rental) => {
//               const leadCost = calculateLeadCost(
//                 rental.dailyRate,
//                 rental.numberOfDays,
//                 1,
//                 rental.unlockedCount,
//                 rental.leadQualityScore,
//                 rental.createdAt,
//                 rental.ownerId,
//                 currentUser?.uid
//               );
//               return (
//                 <RentalItem
//                   key={rental.id}
//                   rental={rental}
//                   cost={leadCost}
//                   onOpen={handleOpen}
//                   onAddToCart={handleAddToCart}
//                   isFree={leadCost === 0}
//                   section="userGenerated"
//                 />
//               );
//             })}
//           </List>
//         </>
//       )}

//          {/* Market Leads Section */}
//          <Typography variant="h6" sx={{ color: "#f72585", marginBottom: "10px", marginTop: "20px" }}>
//         Market Leads:
//       </Typography>
//       <List>
//         {cleanMarketLeads.map((rental) => {
//           const leadCost = calculateLeadCost(
//             rental.dailyRate,
//             rental.numberOfDays,
//             1,
//             rental.unlockedCount,
//             rental.leadQualityScore,
//             rental.createdAt
//           );
//           return (
//             <RentalItem
//               key={rental.id}
//               rental={rental}
//               cost={leadCost}
//               onOpen={handleOpen}
//               onAddToCart={handleAddToCart}
//               isFree={false}
//               section="market"
//             />
//           );
//         })}
//       </List>


//       {/* Modal for Approve / Decline */}
//       <RentalModal
//         selectedRental={selectedRental}
//         declineReason={declineReason}
//         setDeclineReason={setDeclineReason}
//         handleApprove={handleApprove}
//         handleDecline={handleDecline}
//         handleClose={handleClose}
//       />

//       {purchaseModalOpen && selectedRental && (
//         <PurchaseModal
//           modalOpen={purchaseModalOpen}
//           handleModalClose={handleClose}
//           cartItems={[selectedRental]}
//           totalPrice={0}
//           copied={false}
//           handleCopyToClipboard={() => console.log("Copy to clipboard triggered")}
//         />
//       )}
//     </StyledBox>
//   );
// };

// export default PendingRentals;

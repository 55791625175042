// useFetchLeads.js

import { useState, useEffect } from 'react';
import { getFirestore, collection, query, where, getDocs, addDoc } from 'firebase/firestore';
import { useAuth } from './useAuth';

const useFetchLeads = () => {
  const [leads, setLeads] = useState([]);
  const [userGeneratedLeads, setUserGeneratedLeads] = useState([]);
  const [marketLeads, setMarketLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const db = getFirestore();
  const { currentUser } = useAuth(); // ✅ Get current user from auth

  useEffect(() => {
    const fetchLeads = async () => {
      setLoading(true);
      setError(null);

      try {
        if (!currentUser) {
          throw new Error("User not authenticated.");
        }

        // ✅ Fetch all leads from API
        const response = await fetch('https://vicecityvip-stripe.onrender.com/fetch-leads'); // Adjust URL for production
        if (!response.ok) {
          throw new Error(`Error fetching leads: ${response.statusText}`);
        }
        const allLeads = await response.json();

        // ✅ Fetch purchased leads from Firestore
        const purchasedQuery = query(
          collection(db, "purchases"),
          where("userId", "==", currentUser.uid)
        );
        const purchasedSnapshot = await getDocs(purchasedQuery);
        const purchasedLeadIds = new Set();
        purchasedSnapshot.forEach(doc => {
          doc.data().items.forEach(item => purchasedLeadIds.add(item.id));
        });

        // ✅ Fetch declined leads from Firestore
        const declinedQuery = query(
          collection(db, "declinedLeads"),
          where("userId", "==", currentUser.uid)
        );
        const declinedSnapshot = await getDocs(declinedQuery);
        const declinedLeadIds = new Set();
        declinedSnapshot.forEach(doc => declinedLeadIds.add(doc.data().leadId));

        console.log("🔥 Declined Leads:", Array.from(declinedLeadIds)); // Debugging

        // ✅ Separate User-Generated Leads & Market Leads
        const userLeads = allLeads.filter(lead => lead.ownerId === currentUser.uid);
        let marketLeadsFiltered = allLeads.filter(lead => lead.ownerId !== currentUser.uid);

        // ✅ Remove Purchased & Declined Leads from Market Leads
        marketLeadsFiltered = marketLeadsFiltered.filter(
          (lead) => !purchasedLeadIds.has(lead.id) && !declinedLeadIds.has(lead.id)
        );

        // ✅ Remove Declined Leads from User-Generated Leads as well
        const userLeadsFiltered = userLeads.filter(
          (lead) => !declinedLeadIds.has(lead.id)
        );

        // ✅ Update state variables
        setLeads(marketLeadsFiltered);
        setUserGeneratedLeads(userLeadsFiltered);
        setMarketLeads(marketLeadsFiltered);
      } catch (err) {
        console.error("❌ Error fetching leads:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (currentUser) {
      fetchLeads();
    }
  }, [currentUser]);

  // ✅ Function to decline a lead and store it in Firestore
  const declineLead = async (leadId, reason) => {
    try {
      if (!currentUser) throw new Error("User not authenticated.");

      const declinedData = {
        userId: currentUser.uid,
        leadId: leadId,
        reason: reason,
        declinedAt: new Date().toISOString(),
      };

      await addDoc(collection(db, "declinedLeads"), declinedData);

      // ✅ Properly remove declined lead from ALL state lists
      setLeads(prevLeads => prevLeads.filter(lead => lead.id !== leadId));
      setUserGeneratedLeads(prevLeads => prevLeads.filter(lead => lead.id !== leadId));
      setMarketLeads(prevLeads => prevLeads.filter(lead => lead.id !== leadId));

      console.log(`✅ Lead ${leadId} declined successfully.`);
    } catch (err) {
      console.error("❌ Error declining lead:", err);
      setError(err.message);
    }
  };

  // ✅ Return the correctly filtered lists
  return { leads, userGeneratedLeads, marketLeads, loading, error, declineLead, currentUser };
};

export default useFetchLeads;







// import { useState, useEffect } from 'react';
// import { getFirestore, collection, query, where, getDocs, addDoc } from 'firebase/firestore';
// import { useAuth } from './useAuth';

// const useFetchLeads = () => {
//   const [leads, setLeads] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const db = getFirestore();
//   const { currentUser } = useAuth();

//   useEffect(() => {
//     const fetchLeads = async () => {
//       setLoading(true);
//       setError(null);

//       try {
//         if (!currentUser) {
//           throw new Error("User not authenticated.");
//         }

//         // ✅ Fetch all leads from API
//         const response = await fetch('http://localhost:5000/fetch-leads'); // Adjust URL for production
//         if (!response.ok) {
//           throw new Error(`Error fetching leads: ${response.statusText}`);
//         }
//         const allLeads = await response.json();

//         // ✅ Fetch purchased leads from Firestore
//         const purchasedQuery = query(
//           collection(db, "purchases"),
//           where("userId", "==", currentUser.uid)
//         );
//         const purchasedSnapshot = await getDocs(purchasedQuery);

//         // Extract purchased lead IDs
//         const purchasedLeadIds = new Set();
//         purchasedSnapshot.forEach(doc => {
//           const purchaseData = doc.data();
//           purchaseData.items.forEach(item => purchasedLeadIds.add(item.id));
//         });

//         // ✅ Fetch declined leads from Firestore
//         const declinedQuery = query(
//           collection(db, "declinedLeads"),
//           where("userId", "==", currentUser.uid)
//         );
//         const declinedSnapshot = await getDocs(declinedQuery);

//         // Extract declined lead IDs
//         const declinedLeadIds = new Set();
//         declinedSnapshot.forEach(doc => {
//           const declinedData = doc.data();
//           declinedLeadIds.add(declinedData.leadId);
//         });

//         // ✅ Filter out both purchased and declined leads
//         const availableLeads = allLeads.filter(
//           (lead) => !purchasedLeadIds.has(lead.id) && !declinedLeadIds.has(lead.id)
//         );

//         setLeads(availableLeads);
//       } catch (err) {
//         console.error("❌ Error fetching leads:", err);
//         setError(err.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (currentUser) {
//       fetchLeads();
//     }
//   }, [currentUser]);

//   // ✅ Function to decline a lead and store it in Firestore
//   const declineLead = async (leadId, reason) => {
//     try {
//       if (!currentUser) throw new Error("User not authenticated.");

//       const declinedData = {
//         userId: currentUser.uid,
//         leadId: leadId,
//         reason: reason,
//         declinedAt: new Date().toISOString(),
//       };

//       await addDoc(collection(db, "declinedLeads"), declinedData);

//       // ✅ Update state by removing declined lead from UI
//       setLeads((prevLeads) => prevLeads.filter((lead) => lead.id !== leadId));

//       console.log(`✅ Lead ${leadId} declined successfully.`);
//     } catch (err) {
//       console.error("❌ Error declining lead:", err);
//       setError(err.message);
//     }
//   };

//   return { leads, loading, error, declineLead };
// };

// export default useFetchLeads;

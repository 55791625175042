// RentalHistory.js




import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
  Modal,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useStyles from "./RentalHistoryStyles"; // Custom styles
import useStorePurchase from "../../hooks/useStorePurchase"; // Import Firestore hook

const RentalHistory = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { fetchPurchases, purchases, loading } = useStorePurchase();
  const [selectedRental, setSelectedRental] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    fetchPurchases(); // Fetch purchases on load
  }, []);

  const handleOpenModal = (rental) => {
    setSelectedRental(rental);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Box className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        Purchased Leads
      </Typography>

      {loading ? (
        <Typography>Loading...</Typography>
      ) : purchases.length === 0 ? (
        <Typography>No rental history found.</Typography>
      ) : (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>Customer Name</TableCell>
                {!isMobile && <TableCell className={classes.tableHeader}>Phone</TableCell>}
                {!isMobile && <TableCell className={classes.tableHeader}>Address</TableCell>}
                {!isMobile && <TableCell className={classes.tableHeader}>Email</TableCell>}
                <TableCell className={classes.tableHeader}>Car</TableCell>
                <TableCell className={classes.tableHeader}>Purchased</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {purchases.map((purchase) =>
                purchase.items.map((item, index) => (
                  <TableRow
                    key={index}
                    className={classes.tableRow}
                    onClick={() => isMobile && handleOpenModal(item)}
                    style={{ cursor: isMobile ? "pointer" : "default" }}
                  >
                    <TableCell>{item.customerName}</TableCell>
                    {!isMobile && <TableCell>{item.phone}</TableCell>}
                    {!isMobile && <TableCell>{item.address}</TableCell>}
                    {!isMobile && <TableCell>{item.email}</TableCell>}
                    <TableCell>{item.brand} {item.model}</TableCell>
                    <TableCell>{item.purchasedAt.split("T")[0]}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Modal for mobile full view */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box className={classes.modalContent}>
          {selectedRental && (
            <>
              <Typography variant="h6" gutterBottom>
                Full Lead Details
              </Typography>
              <Typography variant="body1">Name: {selectedRental.customerName}</Typography>
              <Typography variant="body1">Phone: {selectedRental.phone}</Typography>
              <Typography variant="body1">Address: {selectedRental.address}</Typography>
              <Typography variant="body1">Email: {selectedRental.email}</Typography>
              <Typography variant="body1">Car: {selectedRental.brand} {selectedRental.model}</Typography>
              <Typography variant="body1">Purchased At: {selectedRental.purchasedAt.split("T")[0]}</Typography>
              <Button onClick={handleCloseModal} className={classes.closeButton}>
                Close
              </Button>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default RentalHistory;


// VehicleListingPage.js


import React, { useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography, Button, Container, Grid } from '@mui/material';
import { motion } from "framer-motion";
import { Helmet } from 'react-helmet';
import useStyles from './VehicleListingPageStyles';
import useCarListings from '../../hooks/useCarListings';
import CalendarModal from '../modals/CalendarModal';

const VehicleListingPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { carListings, loading, error } = useCarListings();
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const vehicle = location.state?.car || carListings.find((car) => car.id === id);

  if (loading) return <Typography variant="h6" className={classes.loadingText}>Loading...</Typography>;
  if (error) return <Typography variant="h6" color="error">{error}</Typography>;
  if (!vehicle) return <Typography variant="h4" className={classes.errorText}>Vehicle not found</Typography>;

  // SEO-Optimized Page Title & Meta Description
const title = `${vehicle.make} ${vehicle.model} Rentals ${vehicle.city} | Exotic & Luxury Cars`;
const description = `Looking for a ${vehicle.make} ${vehicle.model} rental in ${vehicle.city}? Get the best luxury and exotic car rentals in ${vehicle.city}, ${vehicle.state}. Book now!`;
  const handleGoBack = () => navigate(-1);
  const handleRentNow = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const handleThumbnailClick = (index) => setSelectedImageIndex(index);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link
          rel="canonical"
          href={`https://www.vicecityvip.com/vehicles/${vehicle.id}`}
        />
      </Helmet>

      {/* VEHICLE IMAGE GALLERY */}
      <Box className={classes.galleryContainer}>
        {vehicle.imageUrls.length > 0 && (
          <>
            <Box className={classes.mainImageContainer}>
              <img
                src={vehicle.imageUrls[selectedImageIndex]}
                alt={`${vehicle.make} ${vehicle.model}`}
                className={classes.mainImage}
              />
            </Box>
            <Box className={classes.thumbnailContainer}>
              {vehicle.imageUrls.map((url, index) => (
                <img
                  key={index}
                  src={url}
                  alt={`Thumbnail ${index}`}
                  className={`${classes.thumbnail} ${
                    selectedImageIndex === index ? classes.activeThumbnail : ""
                  }`}
                  onClick={() => handleThumbnailClick(index)}
                />
              ))}
            </Box>
          </>
        )}
      </Box>

      {/* VEHICLE TITLE & RENT BUTTON */}
      <Box className={classes.vehicleInfoContainer}>
        <Typography variant="h2" className={classes.vehicleTitle}>
          {vehicle.make} {vehicle.model}
        </Typography>

        <motion.button
          whileHover={{
            scale: 1.2,
            boxShadow: "0 0 50px #23d5ab, 0 0 90px #f72585",
          }}
          whileTap={{ scale: 0.95 }}
          className={classes.rentNowButton}
          onClick={handleRentNow}
        >
          🚀 Rent Now
        </motion.button>
      </Box>

      {/* VEHICLE DETAILS */}
      <Container className={classes.detailsContainer}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Box className={classes.detailBox}>
              <Typography variant="h6">Price Per Day</Typography>
              <Typography variant="h4" className={classes.priceText}>
                ${vehicle.pricePerDay}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className={classes.detailBox}>
              <Typography variant="h6">Location</Typography>
              <Typography variant="h5">
                {vehicle.city}, {vehicle.state}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className={classes.detailBox}>
              <Typography variant="h6">Delivery Available</Typography>
              <Typography variant="h5">
                {vehicle.deliveryOffered ? "Yes" : "No"}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Payment Methods Section */}
        {(vehicle.paymentMethods && vehicle.paymentMethods.length > 0) ||
        vehicle.cryptoAccepted ? (
          <Box className={classes.paymentMethodsBox}>
            <Typography variant="h6">Accepted Payment Methods</Typography>
            <Box className={classes.paymentMethodsList}>
              {vehicle.paymentMethods.map((method, index) => (
                <Typography key={index} className={classes.paymentMethod}>
                  {method}
                </Typography>
              ))}
            </Box>
            {/* Render crypto logo separately, below payment methods */}
            {vehicle.cryptoAccepted && (
              <Box className={classes.cryptoContainer}>
                <Box className={classes.cryptoWrapper}>
                  {/* Create 24 stacked layers for thickness */}
                  {[...Array(10)].map((_, i) => (
                    <Box
                      key={i}
                      className={classes.cryptoLayer}
                      style={{ transform: `translateZ(${i * 0.5}px)` }}
                    />
                  ))}
                  {/* Front and Back Faces of the Coin */}
                  <Box className={classes.cryptoFaceFront} />
                  <Box className={classes.cryptoFaceBack} />
                </Box>
              </Box>
            )}
          </Box>
        ) : null}

        <Box className={classes.descriptionBox}>
          <Typography variant="h6">About This Vehicle</Typography>

          <Typography paragraph>
            Experience the thrill of driving a{" "}
            <strong>
              {vehicle.make} {vehicle.model}
            </strong>
            . Whether you're cruising through{" "}
            <strong>
              {vehicle.city}, {vehicle.state}
            </strong>{" "}
            or planning a special trip, this rental ensures a premium
            experience.
          </Typography>

          {/* Dynamically include only available description */}
          {vehicle.description && (
            <Typography paragraph>{vehicle.description}</Typography>
          )}

          <Typography paragraph>
            Whether you're renting for a special occasion, a business trip, or
            just to experience something new, this{" "}
            <strong>
              {vehicle.make} {vehicle.model}
            </strong>{" "}
            is a fantastic choice.
          </Typography>

          <Typography paragraph style={{ fontStyle: "italic", color: "#888" }}>
            Book now to experience luxury and performance on the road!
          </Typography>
        </Box>

        {/* BUTTONS */}
        <Box className={classes.buttonContainer}>
          <Button className={classes.goBackButton} onClick={handleGoBack}>
            Go Back
          </Button>
        </Box>
      </Container>

      {/* RENT MODAL */}
      <CalendarModal
        open={isModalOpen}
        handleClose={handleCloseModal}
        selectedCar={vehicle}
      />
    </>
  );
};

export default VehicleListingPage;




// import React, { useState } from 'react';
// import { useParams, useNavigate, useLocation } from 'react-router-dom';
// import { Box, Typography, Button, Container, Grid } from '@mui/material';
// import { Helmet } from 'react-helmet';
// import useStyles from './VehicleListingPageStyles';
// import useCarListings from '../../hooks/useCarListings';
// import CalendarModal from '../modals/CalendarModal';

// const VehicleListingPage = () => {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { carListings, loading, error } = useCarListings();
//   const classes = useStyles();
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedImageIndex, setSelectedImageIndex] = useState(0);

//   const vehicle = location.state?.car || carListings.find((car) => car.id === id);

//   if (loading) return <Typography variant="h6" className={classes.loadingText}>Loading...</Typography>;
//   if (error) return <Typography variant="h6" color="error">{error}</Typography>;
//   if (!vehicle) return <Typography variant="h4" className={classes.errorText}>Vehicle not found</Typography>;

//   const title = `${vehicle.make} ${vehicle.model} Rentals | Exotic & Luxury Cars`;
//   const description = `Rent a ${vehicle.make} ${vehicle.model} now. Experience exotic car rentals with delivery options and premium services.`;

//   const handleGoBack = () => navigate(-1);
//   const handleRentNow = () => setIsModalOpen(true);
//   const handleCloseModal = () => setIsModalOpen(false);
//   const handleThumbnailClick = (index) => setSelectedImageIndex(index);

//   return (
//     <>
//       <Helmet>
//         <title>{title}</title>
//         <meta name="description" content={description} />
//       </Helmet>

//       {/* VEHICLE IMAGE GALLERY */}
//       <Box className={classes.galleryContainer}>
//         {vehicle.imageUrls.length > 0 && (
//           <>
//             <Box className={classes.mainImageContainer}>
//               <img 
//                 src={vehicle.imageUrls[selectedImageIndex]} 
//                 alt={`${vehicle.make} ${vehicle.model}`} 
//                 className={classes.mainImage} 
//               />
//             </Box>
//             <Box className={classes.thumbnailContainer}>
//               {vehicle.imageUrls.map((url, index) => (
//                 <img 
//                   key={index}
//                   src={url} 
//                   alt={`Thumbnail ${index}`} 
//                   className={`${classes.thumbnail} ${selectedImageIndex === index ? classes.activeThumbnail : ''}`}
//                   onClick={() => handleThumbnailClick(index)}
//                 />
//               ))}
//             </Box>
//           </>
//         )}
//       </Box>

//       {/* VEHICLE TITLE & RENT BUTTON - Now Properly Centered */}
//       <Box className={classes.vehicleInfoContainer}>
//         <Typography variant="h2" className={classes.vehicleTitle}>
//           {vehicle.make} {vehicle.model}
//         </Typography>
//         <Typography variant="h6" className={classes.vehicleSubtitle}>
//           {/* Power, Prestige, and Performance. */}
//         </Typography>
//         <Button className={classes.rentNowButton} onClick={handleRentNow}>
//           Rent Now
//         </Button>
//       </Box>

//       {/* VEHICLE DETAILS */}
//       <Container className={classes.detailsContainer}>
//         <Grid container spacing={4}>
//           <Grid item xs={12} md={4}>
//             <Box className={classes.detailBox}>
//               <Typography variant="h6">Price Per Day</Typography>
//               <Typography variant="h4" className={classes.priceText}>${vehicle.pricePerDay}</Typography>
//             </Box>
//           </Grid>
//           <Grid item xs={12} md={4}>
//             <Box className={classes.detailBox}>
//               <Typography variant="h6">Location</Typography>
//               <Typography variant="h5">{vehicle.city}, {vehicle.state}</Typography>
//             </Box>
//           </Grid>
//           <Grid item xs={12} md={4}>
//             <Box className={classes.detailBox}>
//               <Typography variant="h6">Delivery Available</Typography>
//               <Typography variant="h5">{vehicle.deliveryOffered ? "Yes" : "No"}</Typography>
//             </Box>
//           </Grid>
//         </Grid>

//         {/* Payment Methods Section */}
//         {vehicle.paymentMethods && vehicle.paymentMethods.length > 0 && (
//           <Box className={classes.paymentMethodsBox}>
//             <Typography variant="h6">Accepted Payment Methods</Typography>
//             <Box className={classes.paymentMethodsList}>
//               {vehicle.paymentMethods.map((method, index) => (
//                 <Typography key={index} className={classes.paymentMethod}>
//                   {method}
//                 </Typography>
//               ))}
//             </Box>
//           </Box>
//         )}

//         <Box className={classes.descriptionBox}>
//           <Typography variant="h6">About This Vehicle</Typography>
//           <Typography>{vehicle.description}</Typography>
//         </Box>

//         {/* BUTTONS */}
//         <Box className={classes.buttonContainer}>
//           <Button className={classes.goBackButton} onClick={handleGoBack}>
//             Go Back
//           </Button>
//         </Box>
//       </Container>

//       {/* RENT MODAL */}
//       <CalendarModal open={isModalOpen} handleClose={handleCloseModal} selectedCar={vehicle} />
//     </>
//   );
// };

// export default VehicleListingPage;


import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#000',
    color: '#fff',
    minHeight: '100vh',
    paddingBottom: '80px',
    textAlign: 'center',
    animation: '$fadeIn 1.5s ease-in-out',
  },
  heroSection: {
    height: '60vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'url("/images/hero-bg.jpg") center/cover',
    textAlign: 'center',
    padding: '20px',
    boxShadow: '0px 0px 80px rgba(247, 37, 133, 0.5)',
  },
  heroText: {
    color: '#f72585',
    fontSize: '4rem',
    fontWeight: 800,
    textShadow: '0 0 30px #f72585, 0 0 50px #23d5ab',
  },
  subText: {
    color: '#23d5ab',
    fontSize: '1.8rem',
    marginTop: '10px',
    textShadow: '0 0 20px #23d5ab',
  },
  contentContainer: {
    marginTop: '50px',
    padding: '0 20px',
  },
  section: {
    padding: '50px 30px',
    margin: '20px 0',
    background: 'linear-gradient(135deg, rgba(17, 17, 17, 0.95), rgba(0, 0, 0, 0.98))',
    border: '2px solid #23d5ab',
    borderRadius: '20px',
    boxShadow: '0 0 50px rgba(35, 213, 171, 0.5), 0 0 80px rgba(247, 37, 133, 0.4)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 0 100px rgba(35, 213, 171, 0.6), 0 0 120px rgba(247, 37, 133, 0.5)',
    },
  },
  sectionTitle: {
    color: '#f72585',
    fontSize: '2.5rem',
    marginBottom: '20px',
    textShadow: '0 0 25px #f72585, 0 0 40px #23d5ab',
  },
  sectionText: {
    color: '#c0c0c0',
    fontSize: '1.4rem',
    lineHeight: '1.8',
    textShadow: '0 0 10px #23d5ab',
  },
  ctaSection: {
    marginTop: '50px',
    textAlign: 'center',
  },
  ctaButton: {
    padding: '15px 50px',
    fontSize: '1.6rem',
    color: '#fff',
    backgroundColor: '#f72585',
    borderRadius: '50px',
    textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab',
    '&:hover': {
      backgroundColor: '#23d5ab',
      boxShadow: '0 0 50px #23d5ab, 0 0 80px #f72585',
    },
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  },
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
}));

export default useStyles;



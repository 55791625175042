// DashboardOverview.js



import React, { useState } from 'react';
import { Box, Typography, Grid, Button, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import useUserListings from '../../hooks/useUserListings';
import useStyles from './DashboardViewStyles';

const DashboardOverview = ({ userType }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { listings, loading, error, deleteListing } = useUserListings();
  const [selectedListing, setSelectedListing] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Calculate total rentals and past rentals dynamically
  const totalRentals = listings.length;
  const pastRentals = listings.filter((listing) => listing.status === 'completed').length;

  // Handle modal open
  const handleManageClick = (listing) => {
    setSelectedListing(listing);
    setIsModalOpen(true);
  };

  // Handle modal close
  const handleCloseModal = () => {
    setSelectedListing(null);
    setIsModalOpen(false);
  };

  // Handle upgrade (shows "Coming Soon")
  const handleUpgrade = () => {
    alert('Coming Soon!');
  };

  // Handle delete (removes listing from Firebase)
  const handleDelete = async () => {
    if (selectedListing) {
      await deleteListing(selectedListing.id);
      setIsModalOpen(false);
    }
  };

  // Navigate to listing details
  const handleNavigateToListing = (listing) => {
    navigate(`/vehicle/${listing.id}`, { state: { car: listing } });
  };

  return (
    <Box className={classes.dashboardContainer}>
      <Typography variant="h4" className={classes.dashboardTitle}>
        Welcome, {currentUser?.name || 'User'}
      </Typography>
      <Typography variant="subtitle1" className={classes.dashboardSubtitle}>
        {userType === 'business'
          ? 'Manage your rental leads, track performance, and create new listings.'
          : 'Check your current rentals, view history, and manage your account settings.'}
      </Typography>

      {/* Neon Stats Section */}
      <Grid container spacing={4} className={classes.statsContainer}>
        <Grid item xs={12} sm={6}>
          <Box className={classes.statCard}>
            <Typography className={classes.statLabel}>Total Rentals</Typography>
            <Typography className={classes.statValue}>{totalRentals}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className={classes.statCard}>
            <Typography className={classes.statLabel}>Past Rentals</Typography>
            <Typography className={classes.statValue}>{pastRentals}</Typography>
          </Box>
        </Grid>
      </Grid>

      {/* User Listings Section */}
      <Box className={classes.listingsContainer}>
        <Typography variant="h5" className={classes.sectionTitle}>
          Your Listings
        </Typography>

        {loading ? (
          <Typography className={classes.loadingText}>Loading listings...</Typography>
        ) : error ? (
          <Typography className={classes.errorText}>{error}</Typography>
        ) : listings.length === 0 ? (
          <Typography className={classes.noListingsText}>You have no listings yet.</Typography>
        ) : (
          <Grid container spacing={3} className={classes.listingsGrid}>
            {listings.map((listing) => (
              <Grid item xs={12} md={6} lg={4} key={listing.id}>
                <Box 
                  className={classes.listingCard} 
                  onClick={() => handleNavigateToListing(listing)}
                  style={{ cursor: 'pointer' }} // Make entire card clickable
                >
                  <img src={listing.imageUrls[0]} alt={listing.make} className={classes.listingImage} />
                  <Typography className={classes.listingTitle}>{listing.make} {listing.model}</Typography>
                  <Typography className={classes.listingDetails}>
                    {listing.city}, {listing.state} | ${listing.pricePerDay}/day
                  </Typography>
                  <Button 
                    className={classes.manageButton} 
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent navigation when clicking manage button
                      handleManageClick(listing);
                    }}
                  >
                    Manage
                  </Button>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      {/* Manage Listing Modal */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box className={classes.modalContainer}>
          <Typography variant="h5" className={classes.modalTitle}>
            Manage Listing
          </Typography>
          {selectedListing && (
            <>
              <Typography className={classes.modalText}>
                {selectedListing.make} {selectedListing.model}
              </Typography>
              <Box className={classes.modalButtons}>
                <Button className={classes.deleteButton} onClick={handleDelete}>
                  Delete Listing
                </Button>
                <Button className={classes.upgradeButton} onClick={handleUpgrade}>
                  Upgrade Listing
                </Button>
              </Box>
            </>
          )}
          <Button className={classes.closeModalButton} onClick={handleCloseModal}>
            Close
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default DashboardOverview;


// Cart.js


import React from "react";
import {
  Modal,
  Typography,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useCart } from "../../context/CartContext"; // Import useCart to clear the cart

const Cart = ({ cartItems = [], onClose, onRemoveItem, open }) => {
  const navigate = useNavigate();
  const { clearCart } = useCart(); // Get clearCart from the context

  // ✅ Ensure total price calculation is correct and handles missing prices
  const totalPrice = cartItems.reduce(
    (total, item) => total + (typeof item.price === "number" ? item.price : 0),
    0
  );

  const handleCheckout = () => {
    if (cartItems.length === 0) return; // Prevent checkout with empty cart

    // Navigate to the checkout page with the cartItems and totalPrice directly
    navigate("/purchase", {
      state: {
        cartItems, // ✅ Ensuring cartItems are passed correctly
        totalPrice, // ✅ Ensuring totalPrice is correct
      },
    });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "450px",
          bgcolor: "#1a1a1a",
          border: "2px solid #f72585",
          boxShadow: "0 0 50px #23d5ab",
          p: 4,
          borderRadius: "12px",
          color: "#ffffff",
          textAlign: "center",
        }}
      >
        <ShoppingCartIcon sx={{ fontSize: 50, color: "#f72585" }} />
        <Typography
          variant="h5"
          sx={{ mt: 2, mb: 3, textShadow: "0 0 10px #f72585" }}
        >
          Your Cart
        </Typography>
        <Divider sx={{ borderColor: "#23d5ab", mb: 2 }} />

        {cartItems.length > 0 ? (
          <>
            <List>
              {cartItems.map((item, index) => (
                <ListItem
                  key={index}
                  sx={{
                    bgcolor: "#111",
                    borderRadius: "8px",
                    mb: 2,
                    boxShadow: "0 0 15px #23d5ab",
                    "&:hover": {
                      transform: "scale(1.03)",
                      boxShadow: "0 0 25px #f72585",
                      transition: "0.3s",
                    },
                  }}
                >
                  <ListItemText
                    primary={item.name || "Unnamed Item"}
                    secondary={`Price: $${(item.price || 0).toFixed(2)}`} // ✅ Ensure price is always a number
                    primaryTypographyProps={{
                      fontWeight: "bold",
                      color: "#23d5ab",
                      textShadow: "0 0 10px #23d5ab",
                    }}
                    secondaryTypographyProps={{
                      color: "#f72585",
                      textShadow: "0 0 5px #f72585",
                    }}
                  />
                  <IconButton
                    edge="end"
                    color="secondary"
                    onClick={() => onRemoveItem(item.id)}
                  >
                    <DeleteIcon sx={{ color: "#f72585" }} />
                  </IconButton>
                </ListItem>
              ))}
            </List>

            {/* ✅ Display the total price */}
            <Typography variant="h6" sx={{ color: "#f72585", mt: 2, mb: 2 }}>
              Total: ${totalPrice.toFixed(2)}
            </Typography>

            <Box mt={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCheckout}
                sx={{
                  backgroundColor: "#23d5ab",
                  color: "#000",
                  boxShadow: "0 0 15px #23d5ab",
                  "&:hover": {
                    backgroundColor: "#1a1a1a",
                    color: "#23d5ab",
                    boxShadow: "0 0 25px #f72585",
                  },
                  mr: 2,
                }}
              >
                Checkout
              </Button>

              <Button
                variant="contained"
                color="secondary"
                onClick={clearCart} // ✅ Clear cart when needed
                sx={{
                  backgroundColor: "#f72585",
                  color: "#fff",
                  boxShadow: "0 0 15px #f72585",
                  "&:hover": {
                    backgroundColor: "#1a1a1a",
                    color: "#f72585",
                    boxShadow: "0 0 25px #23d5ab",
                  },
                }}
              >
                Clear Cart
              </Button>
            </Box>
          </>
        ) : (
          <Typography variant="body1" sx={{ color: "#f72585", mt: 2 }}>
            Your cart is empty.
          </Typography>
        )}

        <Button
          variant="outlined"
          color="secondary"
          onClick={onClose}
          sx={{
            color: "#f72585",
            borderColor: "#f72585",
            "&:hover": {
              borderColor: "#23d5ab",
              color: "#23d5ab",
              boxShadow: "0 0 15px #23d5ab",
            },
          }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default Cart;



// import React from 'react';
// import { Modal, Typography, Box, Button, List, ListItem, ListItemText, IconButton, Divider } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import DeleteIcon from '@mui/icons-material/Delete';
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import { useCart } from '../../context/CartContext'; // Import useCart to clear the cart

// // Helper function to check if a date is within a range
// const isDateInRange = (date, startDate, endDate) => {
//   return date >= startDate && date <= endDate;
// };

// // Helper function to check for major holidays and seasons
// const checkIfPeakSeason = () => {
//   const currentDate = new Date();
//   const month = currentDate.getMonth();

//   // Define date ranges for summer and winter seasons
//   const summerStart = new Date(currentDate.getFullYear(), 5, 1); // June 1st
//   const summerEnd = new Date(currentDate.getFullYear(), 8, 30);  // September 30th
//   const winterStart = new Date(currentDate.getFullYear(), 11, 1); // December 1st
//   const winterEnd = new Date(currentDate.getFullYear() + 1, 1, 28); // February 28th (next year)

//   // Check if it's during summer or winter season
//   if (isDateInRange(currentDate, summerStart, summerEnd) || isDateInRange(currentDate, winterStart, winterEnd)) {
//     return true;
//   }

//   // Check for specific holidays (New Year's, Thanksgiving, Christmas, etc.)
//   const holidays = [
//     new Date(currentDate.getFullYear(), 11, 25), // Christmas (December 25th)
//     new Date(currentDate.getFullYear(), 0, 1),   // New Year's Day (January 1st)
//     new Date(currentDate.getFullYear(), 10, 24), // Thanksgiving (approximation)
//   ];

//   return holidays.some(holiday => currentDate.getTime() === holiday.getTime());
// };

// const calculateLeadCost = (price, numberOfDays, locationFactor = 1, isExclusive = true, unlockedCount = 0) => {
//   if (price && numberOfDays) {
//     const pricePerDay = parseFloat(price.replace(/[^0-9.-]+/g, "")); // Extract numeric value from price

//     if (!isNaN(pricePerDay)) {
//       let dailyCost = 0;

//       // Lead pricing based on car rental price
//       if (pricePerDay < 500) {
//         dailyCost = 5;
//       } else if (pricePerDay >= 500 && pricePerDay < 1500) {
//         dailyCost = 10;
//       } else if (pricePerDay >= 1500 && pricePerDay < 2500) {
//         dailyCost = 15;
//       } else if (pricePerDay >= 2500 && pricePerDay < 5000) {
//         dailyCost = 25;
//       } else {
//         dailyCost = 50; // Lead cost for rentals above $5,000
//       }

//       // Calculate the base lead cost
//       let totalLeadCost = dailyCost * numberOfDays;

//       // Apply volume discounts based on rental duration
//       if (numberOfDays > 30) {
//         totalLeadCost *= 0.85; // 15% discount for rentals longer than 30 days
//       } else if (numberOfDays > 14) {
//         totalLeadCost *= 0.90; // 10% discount for rentals longer than 14 days
//       } else if (numberOfDays > 7) {
//         totalLeadCost *= 0.95; // 5% discount for rentals longer than 7 days
//       }

//       // Check for peak season adjustment
//       const isPeakSeason = checkIfPeakSeason();
//       if (isPeakSeason) {
//         totalLeadCost *= 1.10; // 10% increase for peak seasons
//       }

//       // Apply exclusive lead pricing adjustment
//       if (isExclusive) {
//         totalLeadCost *= 1.20; // 20% increase for exclusive leads
//       }

//       // Apply discount based on the number of times the lead was sold
//       if (unlockedCount > 0) {
//         // First time it's sold: 25% off
//         let discount = 0.25;

//         // Every subsequent sale: additional 10% off
//         if (unlockedCount > 1) {
//           discount += 0.10 * (unlockedCount - 1);
//         }

//         // Max discount of 50%
//         discount = Math.min(discount, 0.50);

//         totalLeadCost *= 1 - discount;
//       }

//       // Apply location factor (optional)
//       totalLeadCost *= locationFactor;

//       return totalLeadCost;
//     } else {
//       console.error("Invalid price format:", price);
//       return 0;
//     }
//   }

//   return 0;
// };

// const Cart = ({ cartItems, onClose, onRemoveItem, open }) => {
//   const navigate = useNavigate();
//   const { clearCart } = useCart(); // Get clearCart from the context

//   // Calculate the total price of the items in the cart using the new logic
//   const totalPrice = cartItems.reduce((total, item) => {
//     const leadCost = calculateLeadCost(item.price, item.numberOfDays, 1, item.isExclusive, item.unlockedCount);
//     return total + leadCost;
//   }, 0);

//   const handleCheckout = () => {
//     clearCart(); // Clear cart items after checkout
//     navigate('/checkout'); // Navigate to checkout page
//   };

//   return (
//     <Modal open={open} onClose={onClose}>
//       <Box sx={{
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         transform: 'translate(-50%, -50%)',
//         width: '450px',
//         bgcolor: '#1a1a1a',
//         border: '2px solid #f72585',
//         boxShadow: '0 0 50px #23d5ab',
//         p: 4,
//         borderRadius: '12px',
//         color: '#ffffff',
//         textAlign: 'center',
//       }}>
//         <ShoppingCartIcon sx={{ fontSize: 50, color: '#f72585' }} />
//         <Typography variant="h5" sx={{ mt: 2, mb: 3, textShadow: '0 0 10px #f72585' }}>
//           Your Cart
//         </Typography>
//         <Divider sx={{ borderColor: '#23d5ab', mb: 2 }} />
//         {cartItems.length > 0 ? (
//           <>
//             <List>
//               {cartItems.map((item, index) => {
//                 const leadCost = calculateLeadCost(item.price, item.numberOfDays, 1, item.isExclusive, item.unlockedCount);
//                 return (
//                   <ListItem
//                     key={index}
//                     sx={{
//                       bgcolor: '#111',
//                       borderRadius: '8px',
//                       mb: 2,
//                       boxShadow: '0 0 15px #23d5ab',
//                       '&:hover': {
//                         transform: 'scale(1.03)',
//                         boxShadow: '0 0 25px #f72585',
//                         transition: '0.3s',
//                       },
//                     }}
//                   >
//                     <ListItemText
//                       primary={item.name}
//                       secondary={`Price: $${leadCost.toFixed(2)}`}
//                       primaryTypographyProps={{
//                         fontWeight: 'bold',
//                         color: '#23d5ab',
//                         textShadow: '0 0 10px #23d5ab',
//                       }}
//                       secondaryTypographyProps={{
//                         color: '#f72585',
//                         textShadow: '0 0 5px #f72585',
//                       }}
//                     />
//                     <IconButton edge="end" color="secondary" onClick={() => onRemoveItem(item.id)}>
//                       <DeleteIcon sx={{ color: '#f72585' }} />
//                     </IconButton>
//                   </ListItem>
//                 );
//               })}
//             </List>

//             {/* Display the total price */}
//             <Typography variant="h6" sx={{ color: '#f72585', mt: 2, mb: 2 }}>
//               Total: ${totalPrice.toFixed(2)}
//             </Typography>

//             <Box mt={3}>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={handleCheckout}
//                 sx={{
//                   backgroundColor: '#23d5ab',
//                   color: '#000',
//                   boxShadow: '0 0 15px #23d5ab',
//                   '&:hover': {
//                     backgroundColor: '#1a1a1a',
//                     color: '#23d5ab',
//                     boxShadow: '0 0 25px #f72585',
//                   },
//                   mr: 2,
//                 }}
//               >
//                 Checkout
//               </Button>
//             </Box>
//           </>
//         ) : (
//           <Typography variant="body1" sx={{ color: '#f72585', mt: 2 }}>
//             Your cart is empty.
//           </Typography>
//         )}

//         <Button
//           variant="outlined"
//           color="secondary"
//           onClick={onClose}
//           sx={{
//             color: '#f72585',
//             borderColor: '#f72585',
//             '&:hover': {
//               borderColor: '#23d5ab',
//               color: '#23d5ab',
//               boxShadow: '0 0 15px #23d5ab',
//             },
//           }}
//         >
//           Close
//         </Button>
//       </Box>
//     </Modal>
//   );
// };

// export default Cart;

//routes/routes.jsx

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

import HomePage from '../components/home/HomePage';
import ExploreCarsPage from '../components/explorecars/ExploreCarsPage';
import AuthPage from '../components/auth/AuthPage';
import Dashboard from '../components/dashboard/DashBoard';
import VehicleListingPage from '../components/listings/VehicleListingPage';
import ThankYouPage from '../components/thankyou/ThankYouPage';
import CustomerInfoPage from '../components/customerinfo/CustomerInfoPage';
import TermsAndConditionsPage from '../components/terms/TermsAndConditionsPage';
import PurchasePage from '../components/purchase/PurchasePage';
import AboutUsScreen from '../components/about/AboutUsScreen';
import HowWeWorkScreen from '../components/about/HowWeWorkScreen';
import BlogPage from '../components/blog/BlogPage';
import SinglePostPage from '../components/blog/SinglePostPage';
import ContactUsScreen from '../components/Contact/ContactUsScreen';

const AppRoutes = () => {
  const { currentUser } = useAuth();

  return (
    <Routes>
      {/* Public routes - Accessible to everyone */}
      <Route path="/about-us" element={<AboutUsScreen />} />
      <Route path="/how-we-work" element={<HowWeWorkScreen />} />
      <Route path="/blog" element={<BlogPage />} />
      <Route path="/blog/:id" element={<SinglePostPage />} />
      <Route path="/contact-us" element={<ContactUsScreen />} />

      {/* Public routes for non-authenticated users */}
      {!currentUser ? (
        <>
          <Route path="/login" element={<AuthPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/explore-cars" element={<ExploreCarsPage />} />
          <Route path="/vehicle/:id" element={<VehicleListingPage />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="/customer-info" element={<CustomerInfoPage />} />
          <Route path="/purchase" element={<PurchasePage />} />
          <Route path="/contact-us" element={<ContactUsScreen />} />
        </>
      ) : (
        <>
          {/* Private routes - Only accessible when signed in */}
          <Route path="/" element={<HomePage />} />
          <Route path="/explore-cars" element={<ExploreCarsPage />} />
          <Route path="/dashboard" element={<Dashboard userType={currentUser.userType} />} />
          <Route path="/vehicle/:id" element={<VehicleListingPage />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="/customer-info" element={<CustomerInfoPage />} />
          <Route path="/purchase" element={<PurchasePage />} />
        </>
      )}
      
      {/* Catch-all redirect to login if no match */}
      <Route path="*" element={<Navigate to={currentUser ? '/dashboard' : '/login'} />} />
    </Routes>
  );
};

export default AppRoutes;

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  dashboardContainer: {
    backgroundColor: '#000',
    padding: '50px',
    color: '#fff',
    minHeight: '100vh',
    boxShadow: 'inset 0 0 20px #23d5ab',
  },
  dashboardTitle: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    textShadow: '0 0 10px #23d5ab, 0 0 20px #f72585',
    textAlign: 'center',
  },
  dashboardSubtitle: {
    textAlign: 'center',
    fontSize: '1.2rem',
    color: '#f72585',
    textShadow: '0 0 10px #f72585',
    marginBottom: '30px',
  },

  // Neon Stats Section
  statsContainer: {
    marginBottom: '50px',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  statCard: {
    backgroundColor: 'rgba(20, 20, 20, 0.9)',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center',
    boxShadow: '0 0 20px #23d5ab',
    transition: 'transform 0.3s ease',
    minWidth: '250px',
    '&:hover': {
      boxShadow: '0 0 40px #f72585',
      transform: 'scale(1.05)',
    },
  },
  statLabel: {
    color: '#23d5ab',
    fontSize: '1.2rem',
    marginBottom: '10px',
  },
  statValue: {
    color: '#f72585',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab',
  },

  // Listings Section
  listingsContainer: {
    marginTop: '50px',
  },
  sectionTitle: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    textShadow: '0 0 10px #f72585',
    marginBottom: '20px',
    textAlign: 'center',
  },
  listingsGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  loadingText: {
    textAlign: 'center',
    fontSize: '1.2rem',
    color: '#23d5ab',
  },
  errorText: {
    textAlign: 'center',
    fontSize: '1.2rem',
    color: '#ff4d4d',
  },
  noListingsText: {
    textAlign: 'center',
    fontSize: '1.2rem',
    color: '#bbb',
  },

  // Listing Cards
  listingCard: {
    backgroundColor: 'rgba(20, 20, 20, 0.9)',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center',
    boxShadow: '0 0 20px #23d5ab',
    transition: '0.3s ease',
    '&:hover': {
      boxShadow: '0 0 40px #f72585',
      transform: 'scale(1.02)',
    },
  },
  listingImage: {
    width: '100%',
    height: '180px',
    objectFit: 'cover',
    borderRadius: '10px',
    boxShadow: '0 0 10px #23d5ab',
    marginBottom: '15px',
  },
  listingTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#f72585',
    marginBottom: '5px',
  },
  listingDetails: {
    fontSize: '1rem',
    color: '#23d5ab',
  },
  manageButton: {
    marginTop: '10px',
    backgroundColor: '#f72585',
    color: '#fff',
    padding: '8px 20px',
    fontSize: '1rem',
    borderRadius: '5px',
    transition: '0.3s ease',
    boxShadow: '0px 0px 10px #f72585',
    '&:hover': {
      backgroundColor: '#d61c73',
    },
  },

  // Modal Styles for Manage Listings
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#111',
    padding: '30px',
    borderRadius: '10px',
    boxShadow: '0 0 30px #23d5ab',
    textAlign: 'center',
    width: '90%',
    maxWidth: '400px',
  },
  modalTitle: {
    fontSize: '1.8rem',
    color: '#f72585',
    textShadow: '0 0 10px #f72585',
  },
  modalText: {
    color: '#23d5ab',
    fontSize: '1.2rem',
    margin: '15px 0',
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '20px',
  },
  deleteButton: {
    backgroundColor: '#ff4d4d',
    color: '#fff',
    padding: '10px 15px',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#d63a3a',
    },
  },
  upgradeButton: {
    backgroundColor: '#f72585',
    color: '#fff',
    padding: '10px 15px',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#d61c73',
    },
  },
  closeModalButton: {
    marginTop: '15px',
    backgroundColor: '#23d5ab',
    color: '#fff',
    padding: '8px 20px',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#1cb993',
    },
  },
}));

export default useStyles;

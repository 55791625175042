// useEditProfile.js



import { useState, useEffect } from 'react';
import { updateDoc, doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { updateEmail, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';

const useEditProfile = () => {
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      setFetching(true);
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          throw new Error('User not authenticated');
        }

        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (!userDoc.exists()) {
          throw new Error('User data not found');
        }

        const data = userDoc.data();

        // Extract address and business address into separate fields
        const formattedData = {
          ...data,
          city: data.address?.city || '',
          state: data.address?.state || '',
          zip: data.address?.zip || '',
          country: data.address?.country || '',
          businessName: data.businessAddress?.businessName || '',
          businessPhone: data.businessAddress?.businessPhone || '',
          businessCity: data.businessAddress?.businessCity || '',
          businessState: data.businessAddress?.businessState || '',
          businessZip: data.businessAddress?.businessZip || '',
          businessCountry: data.businessAddress?.businessCountry || '',
        };

        setUserData(formattedData);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError(error.message);
      } finally {
        setFetching(false);
      }
    };

    fetchUserData();
  }, []);

  const reauthenticateUser = async (currentPassword) => {
    const currentUser = auth.currentUser;
    if (!currentUser) throw new Error('User not authenticated');

    const credential = EmailAuthProvider.credential(currentUser.email, currentPassword);
    await reauthenticateWithCredential(currentUser, credential);
  };

  const editProfile = async (profileData) => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('User not authenticated');
      }

      const userRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userRef);
      if (!userDoc.exists()) {
        throw new Error('User data not found');
      }

      const existingData = userDoc.data();
      const updates = {
        email: profileData.email || existingData.email,
        name: profileData.name || existingData.name,
        phone: profileData.phone || existingData.phone,
        username: profileData.username || existingData.username || '',
        profileImage: profileData.profileImage || existingData.profileImage || '',
      };

      // Convert address and business address back to object format
      updates.address = {
        city: profileData.city || existingData.address?.city || '',
        state: profileData.state || existingData.address?.state || '',
        zip: profileData.zip || existingData.address?.zip || '',
        country: profileData.country || existingData.address?.country || '',
      };

      updates.businessAddress = {
        businessName: profileData.businessName || existingData.businessAddress?.businessName || '',
        businessPhone: profileData.businessPhone || existingData.businessAddress?.businessPhone || '',
        businessCity: profileData.businessCity || existingData.businessAddress?.businessCity || '',
        businessState: profileData.businessState || existingData.businessAddress?.businessState || '',
        businessZip: profileData.businessZip || existingData.businessAddress?.businessZip || '',
        businessCountry: profileData.businessCountry || existingData.businessAddress?.businessCountry || '',
      };

      // Re-authenticate if email or password is being changed
      if (profileData.email && profileData.email !== currentUser.email) {
        if (!profileData.currentPassword) {
          throw new Error('Re-authentication required. Please enter your current password.');
        }
        await reauthenticateUser(profileData.currentPassword);
        await updateEmail(currentUser, profileData.email);
      }

      if (profileData.newPassword) {
        if (!profileData.currentPassword) {
          throw new Error('Re-authentication required. Please enter your current password.');
        }
        await reauthenticateUser(profileData.currentPassword);
        await updatePassword(currentUser, profileData.newPassword);
      }

      // Update Firestore with new data
      await updateDoc(userRef, updates);
      setSuccess('Profile updated successfully!');

      // Success message disappears smoothly after 3 seconds
      setTimeout(() => {
        setSuccess(null);
      }, 3000);

      setUserData({ ...existingData, ...updates });
    } catch (error) {
      console.error('Error updating profile:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { userData, editProfile, loading, fetching, error, success };
};

export default useEditProfile;

// App.jsx



import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { HelmetProvider } from "react-helmet-async";
import { Elements } from "@stripe/react-stripe-js"; // ✅ Import Elements
import { loadStripe } from "@stripe/stripe-js"; // ✅ Import Stripe

import { AuthProvider } from "./hooks/useAuth";
import { CartProvider } from "./context/CartContext";
import PWAInstallButton from "./utils/PWAInstallButton";
import AppRoutes from "./routes/routes"; 

// ✅ Load your live Stripe Public Key
const stripePromise = loadStripe(
  "pk_live_51MemPIEVc52nTNqBnotjPz4IPispE6HKUASHAkcNfN6c6Kqcbv0em0YbAXGrJRlrW5nsLFL1cVUdN5AxfG23PrJl005I35Puhu"
);

const theme = createTheme({
  palette: {
    primary: { main: "#23d5ab" },
    secondary: { main: "#f72585" },
    background: { default: "#000" },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
});

const App = () => {
  return (
    <HelmetProvider>
      <AuthProvider>
        <CartProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Elements stripe={stripePromise}> {/* ✅ Wrap Everything in Elements */}
              <Router>
                <AppRoutes />
              </Router>
              <PWAInstallButton />
            </Elements>
          </ThemeProvider>
        </CartProvider>
      </AuthProvider>
    </HelmetProvider>
  );
};

export default App;


// import React from 'react';
// import { BrowserRouter as Router } from 'react-router-dom';
// import { ThemeProvider, createTheme } from '@mui/material/styles';
// import CssBaseline from '@mui/material/CssBaseline';
// import { HelmetProvider } from 'react-helmet-async'; // ✅ Import HelmetProvider

// import { AuthProvider } from './hooks/useAuth';
// import { CartProvider } from './context/CartContext';
// import PWAInstallButton from './utils/PWAInstallButton';

// import AppRoutes from './routes/routes'; // Import the routes file

// const theme = createTheme({
//   palette: {
//     primary: { main: '#23d5ab' },
//     secondary: { main: '#f72585' },
//     background: { default: '#000' },
//   },
//   typography: {
//     fontFamily: 'Roboto, sans-serif',
//   },
// });

// const App = () => {
//   return (
//     <HelmetProvider> {/* ✅ Wrap the entire app */}
//       <AuthProvider>
//         <CartProvider>
//           <ThemeProvider theme={theme}>
//             <CssBaseline />
//             <Router>
//               <AppRoutes />
//             </Router>
//             <PWAInstallButton />
//           </ThemeProvider>
//         </CartProvider>
//       </AuthProvider>
//     </HelmetProvider>
//   );
// };

// export default App;

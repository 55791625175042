// CarDetailsModalStyles.js



import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '600px',
    backgroundColor: '#111',
    border: '2px solid #f72585',
    boxShadow: '0 0 40px #f72585, 0 0 60px #23d5ab',
    padding: '20px',
    borderRadius: '10px',
    zIndex: 1300,
    animation: '$zoomIn 0.5s ease-in-out',
    maxHeight: '80vh',  
    display: 'flex',
    flexDirection: 'column',
  },

  modalCarImage: {
    width: '100%',
    maxHeight: '250px',
    objectFit: 'cover',
    borderRadius: '10px',
    marginBottom: '10px',
    boxShadow: '0 0 20px #23d5ab',
  },

  modalContent: {
    overflowY: 'auto',
    maxHeight: '40vh',
    padding: '10px',
    textAlign: 'left',

    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#222', 
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#f72585',  
      borderRadius: '10px',
      boxShadow: '0 0 10px #f72585',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#23d5ab', 
      boxShadow: '0 0 15px #23d5ab',
    },
  },

  neonText: {
    color: '#f72585',
    fontWeight: 700,
    fontSize: '1.8rem',
    textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab',
    marginBottom: '10px',
    textAlign: 'center',
  },

  modalDescription: {
    color: '#fff',
    fontSize: '1rem',
    marginBottom: '10px',
    textAlign: 'justify',
  },

  modalPrice: {
    color: '#23d5ab',
    fontSize: '1.5rem',
    fontWeight: 700,
    textShadow: '0 0 10px #23d5ab, 0 0 20px #f72585',
    marginTop: '10px',
  },

  additionalInfo: {
    marginTop: '15px',
    color: '#fff',
    fontSize: '1rem',
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    gap: '15px',
  },

  buttonNeon: {
    color: '#23d5ab',
    border: '2px solid #23d5ab',
    textShadow: '0 0 5px #23d5ab, 0 0 15px #23d5ab',
    padding: '12px 25px',
    fontSize: '1.1rem',
    fontWeight: 700,
    '&:hover': {
      backgroundColor: '#f72585',
      borderColor: '#f72585',
      color: '#fff',
    },
  },

  /* 🔥 RENT NOW BUTTON - Supercharged! */
  rentNowButton: {
    background: 'linear-gradient(45deg, #f72585, #ff5733, #f72585)',
    color: '#fff',
    fontWeight: 800,
    fontSize: '1.2rem',
    padding: '14px 30px',
    border: '2px solid #23d5ab',
    textTransform: 'uppercase',
    textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab',
    borderRadius: '50px',
    boxShadow: '0 0 20px #f72585, 0 0 40px #23d5ab',
    cursor: 'pointer',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',

    /* 🔥 Glowing Pulse Effect */
    animation: '$pulseGlow 1.5s infinite alternate',

    '&:hover': {
      background: 'linear-gradient(45deg, #23d5ab, #3bff8a, #23d5ab)',
      boxShadow: '0 0 50px #23d5ab, 0 0 80px #f72585',
      transform: 'scale(1.1)',
    },
  },

  /* 🔥 Pulse Animation */
  '@keyframes pulseGlow': {
    '0%': {
      boxShadow: '0 0 20px #f72585, 0 0 40px #23d5ab',
    },
    '100%': {
      boxShadow: '0 0 40px #f72585, 0 0 60px #23d5ab',
    },
  },

  '@keyframes zoomIn': {
    '0%': {
      transform: 'scale(0.8)',
      opacity: 0,
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 1,
    },
  },
}));

export default useStyles;

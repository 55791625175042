// MyVehicleLeads.jsx

import React, { useState } from "react";
import { Typography, List } from "@mui/material";
import { StyledBox, NeonText, SubText } from "./PendingRentalsStyles";
import { useNavigate } from "react-router-dom";
import { useCart } from "../../context/CartContext";
import calculateLeadCost from "../../utils/leadCostCalculator";
import useFetchLeads from "../../hooks/useFetchLeads";
import PurchaseModal from "../purchase/PurchaseModal";

// Import shared lead components/functions from leadUtils
import {
  RentalItem,
  RentalModal,
} from "./leadUtils";

const MyVehicleLeads = () => {
  const [purchaseModalOpen, setPurchaseModalOpen] = useState(false);
  const [selectedRental, setSelectedRental] = useState(null);
  const [declineReason, setDeclineReason] = useState("");
  
  const navigate = useNavigate();
  const { addToCart } = useCart();
  const {
    userGeneratedLeads,
    loading,
    error,
    declineLead,
    currentUser,
  } = useFetchLeads();

  if (loading) return <Typography>Loading leads...</Typography>;
  if (error) return <Typography>Error loading leads: {error}</Typography>;
  if (!userGeneratedLeads || userGeneratedLeads.length === 0) {
    return <Typography>No vehicle listing leads found.</Typography>;
  }

  const handleOpen = (rental) => {
    if (!rental) {
      console.error("Tried to open a null rental!");
      return;
    }

    const leadCost = calculateLeadCost(
      rental.dailyRate,
      rental.numberOfDays,
      1,
      rental.unlockedCount,
      rental.leadQualityScore,
      rental.createdAt,
      rental.ownerId,
      currentUser?.uid
    );

    if (leadCost === 0) {
      setSelectedRental({ ...rental, price: 0 });
      setPurchaseModalOpen(true);
    } else {
      setSelectedRental(rental);
    }
    setDeclineReason("");
  };

  const handleClose = () => {
    setSelectedRental(null);
    setPurchaseModalOpen(false);
  };

  const handleApprove = () => {
    if (!selectedRental) {
      console.error("No rental selected!");
      return;
    }

    const leadCost = calculateLeadCost(
      selectedRental.dailyRate,
      selectedRental.numberOfDays,
      1,
      selectedRental.unlockedCount,
      selectedRental.leadQualityScore,
      selectedRental.createdAt,
      selectedRental.ownerId,
      currentUser?.uid
    );

    if (leadCost === 0) {
      // This means the lead is free to unlock
      setSelectedRental({ ...selectedRental, price: 0 });
      return;
    }

    navigate("/purchase", {
      state: {
        cartItems: [{ ...selectedRental, price: leadCost }],
        totalPrice: leadCost,
      },
    });

    handleClose();
  };

  const handleDecline = async () => {
    if (!declineReason.trim()) {
      alert("Please provide a reason for declining the lead.");
      return;
    }
    await declineLead(selectedRental.id, declineReason);
    handleClose();
  };

  const handleAddToCart = (rental) => {
    const leadCost = calculateLeadCost(
      rental.dailyRate,
      rental.numberOfDays,
      1,
      rental.unlockedCount,
      rental.leadQualityScore,
      rental.createdAt
    );

    const rentalItem = {
      id: rental.id,
      name: `${rental.brand} ${rental.model}`,
      dailyRate: rental.dailyRate,
      numberOfDays: rental.numberOfDays,
      isExclusive: rental.unlockedCount === 0,
      unlockedCount: rental.unlockedCount,
      leadQualityScore: rental.leadQualityScore,
      price: leadCost,
      rentingCity: rental.rentingCity,
      rentingState: rental.rentingState,
    };

    addToCart(rentalItem);
  };

  return (
    <StyledBox>
      <NeonText variant="h4">My Vehicle Leads</NeonText>
      <SubText variant="subtitle1">
        These are your leads from your vehicle listings:
      </SubText>

      <List>
        {userGeneratedLeads.map((rental) => {
          const leadCost = calculateLeadCost(
            rental.dailyRate,
            rental.numberOfDays,
            1,
            rental.unlockedCount,
            rental.leadQualityScore,
            rental.createdAt,
            rental.ownerId,
            currentUser?.uid
          );

          return (
            <RentalItem
              key={rental.id}
              rental={rental}
              cost={leadCost}
              onOpen={handleOpen}
              onAddToCart={handleAddToCart}
              isFree={leadCost === 0}
              section="userGenerated"
            />
          );
        })}
      </List>

      {/* Modal for Approve / Decline */}
      <RentalModal
        selectedRental={selectedRental}
        declineReason={declineReason}
        setDeclineReason={setDeclineReason}
        handleApprove={handleApprove}
        handleDecline={handleDecline}
        handleClose={handleClose}
      />

      {/* Purchase Modal for free leads */}
      {purchaseModalOpen && selectedRental && (
        <PurchaseModal
          modalOpen={purchaseModalOpen}
          handleModalClose={handleClose}
          cartItems={[selectedRental]}
          totalPrice={0}
          copied={false}
          handleCopyToClipboard={() => console.log("Copy to clipboard triggered")}
        />
      )}
    </StyledBox>
  );
};

export default MyVehicleLeads;

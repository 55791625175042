// AuthPage.jsx



import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import useStyles from "./FormStyles";
import logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import Registration from "./Registration"; // <-- Import the new component

const AuthPage = () => {
  const classes = useStyles();
  const { login, currentUser, authError } = useAuth();
  const navigate = useNavigate();

  // For login only
  const [isLogin, setIsLogin] = useState(true);
  const [loginFormData, setLoginFormData] = useState({
    email: "",
    password: "",
  });

  // If user is already logged in, navigate away
  useEffect(() => {
    if (currentUser) {
      navigate("/dashboard");
    }
  }, [currentUser, navigate]);

  const handleFormSwitch = () => setIsLogin(!isLogin);

  // Handle the login form
  const handleLoginInputChange = (e) => {
    const { name, value } = e.target;
    setLoginFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await login(loginFormData.email, loginFormData.password);
      navigate("/dashboard");
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  return (
    <Container className={classes.container}>
      <Box className={classes.formBox}>
        {/* LOGO */}
        <Box className={classes.logoContainer}>
          <img src={logo} alt="Vice City VIP" className={classes.logo} />
        </Box>

        {/* Conditional header */}
        <Typography variant="h4" className={classes.formTitle}>
          {isLogin ? "Login" : "Register"}
        </Typography>

        {/* If isLogin is true => show the login form; otherwise, show Registration */}
        {isLogin ? (
          <>
            <form className={classes.form} onSubmit={handleLogin}>
              <Typography variant="h6" className={classes.sectionTitle}>
                Account Information
              </Typography>
              <TextField
                name="email"
                label="Email"
                onChange={handleLoginInputChange}
                variant="outlined"
                fullWidth
                margin="normal"
                className={classes.input}
              />
              <TextField
                name="password"
                label="Password"
                onChange={handleLoginInputChange}
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                className={classes.input}
              />

              {authError && (
                <Typography color="error" className={classes.errorText}>
                  {authError}
                </Typography>
              )}

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                className={classes.submitButton}
              >
                Login
              </Button>
            </form>
          </>
        ) : (
          // Registration component holds *all* registration logic
          <Registration />
        )}

        {/* Switch form mode (Login <-> Register) */}
        <Button
          color="secondary"
          className={classes.switchButton}
          onClick={handleFormSwitch}
        >
          {isLogin
            ? "Don't have an account? Register"
            : "Already have an account? Login"}
        </Button>
      </Box>
    </Container>
  );
};

export default AuthPage;

// src/components/home/HeroSection.jsx


import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useStyles from './HomePageStyles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const HeroSection = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleExploreCars = () => {
    navigate('/explore-cars');
  };

  // Smooth scroll to FeaturedCars section
  const handleScrollDown = () => {
    const element = document.getElementById('featured-cars');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Box className={classes.hero}>
      {/* Background Video */}
      <video autoPlay loop muted playsInline className={classes.videoBackground}>
        <source src="/vice.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Overlay for Readability */}
      <Box className={classes.videoOverlay} />

      {/* Content */}
      <Box className={classes.content}>
        <Typography className={`${classes.neonText} ${classes.typingEffect}`}>
          Own the Road, Rule the Moment
        </Typography>
        <Typography variant="h6" className={classes.subText}>
          Drive the world&apos;s most exotic and luxurious cars — your dream
          ride is just a click away.
        </Typography>

        <Button
          className={`${classes.buttonNeon} ${classes.pulsatingButton}`}
          size="large"
          style={{ marginTop: '20px' }}
          onClick={handleExploreCars}
        >
          Explore the Fleet
        </Button>
      </Box>

      {/* Bouncing arrow to scroll down */}
      <Box className={classes.downArrowContainer} onClick={handleScrollDown}>
        <KeyboardArrowDownIcon className={classes.bounceArrow} />
      </Box>
    </Box>
  );
};

export default HeroSection;


// src/components/explorecars/ExploreCarsPage.jsx



// src/components/explorecars/ExploreCarsPage.jsx

import React, { useState } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  CardMedia,
  CircularProgress
} from '@mui/material';
import useStyles from './ExploreCarsPageStyles';
import useSearch from '../../hooks/useSearch';
import CarDetailsModal from '../modals/CarDetailsModal';
import ExploreCarsSearchSection from './ExploreCarsSearchSection';

const ExploreCarsPage = () => {
  const classes = useStyles();

  // Hook logic (make sure your useSearch includes cryptoAccepted)
  const {
    state,
    city,
    setCity,
    filteredCities,
    filteredCars,
    cars,
    loading,
    showAdvanced,
    setShowAdvanced,
    make,
    model,
    setModel,
    filteredModels,
    handleStateChange,
    handleMakeChange,
    handleSearch,
    carMakes,
    states,
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
    // Add these lines:
    cryptoAccepted,
    setCryptoAccepted
  } = useSearch();

  // Local state for the modal
  const [openModal, setOpenModal] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);

  const carsToRender = filteredCars.length > 0 ? filteredCars : cars;

  const handleSearchClick = () => {
    handleSearch();
    setHasSearched(true);
  };

  // Modal open/close
  const handleOpenModal = (car) => {
    setSelectedCar(car);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedCar(null);
  };

  return (
    <Box className={classes.root}>
      <Container maxWidth="lg" className={classes.contentWrapper}>
        <Typography variant="h4" className={classes.neonText}>
          Explore Exotic Cars
        </Typography>

        {/* Pass cryptoAccepted and setCryptoAccepted to the search section */}
        <ExploreCarsSearchSection
          classes={classes}
          state={state}
          city={city}
          setCity={setCity}
          filteredCities={filteredCities}
          showAdvanced={showAdvanced}
          setShowAdvanced={setShowAdvanced}
          make={make}
          model={model}
          setModel={setModel}
          filteredModels={filteredModels}
          handleStateChange={handleStateChange}
          handleMakeChange={handleMakeChange}
          handleSearchClick={handleSearchClick}
          carMakes={carMakes}
          states={states}
          minPrice={minPrice}
          setMinPrice={setMinPrice}
          maxPrice={maxPrice}
          setMaxPrice={setMaxPrice}
          cryptoAccepted={cryptoAccepted}
          setCryptoAccepted={setCryptoAccepted}
        />

        {loading ? (
          <Box textAlign="center" marginTop="40px">
            <CircularProgress color="inherit" className={classes.spinner} />
            <Typography variant="h6" className={classes.loadingText}>
              Searching cars...
            </Typography>
          </Box>
        ) : hasSearched && carsToRender.length > 0 ? (
          <Grid container spacing={4} className={classes.resultsContainer}>
            {carsToRender.map((car) => (
              <Grid item xs={12} sm={6} md={4} key={car.id}>
                <Paper
                  sx={{ backgroundColor: 'rgba(27, 27, 27, 0.7)' }}
                  className={classes.card}
                  onClick={() => handleOpenModal(car)}
                  elevation={3}
                >
                  <CardMedia
                    component="img"
                    className={classes.cardImage}
                    image={
                      car.imageUrls && car.imageUrls[0]
                        ? car.imageUrls[0]
                        : `${process.env.PUBLIC_URL}/logo.png`
                    }
                    alt={car.make || 'Car'}
                  />
                  <Typography variant="h6" className={classes.cardTitle}>
                    {car.make} {car.model}
                  </Typography>
                  <Typography variant="body2" className={classes.cardDescription}>
                    {car.city || 'Unknown city'}, {car.state || 'Unknown state'}
                  </Typography>
                  <Typography variant="body2" className={classes.cardDescription}>
                    {car.pricePerDay
                      ? `$${car.pricePerDay} / day`
                      : 'Price not available'}
                  </Typography>
                  {/* If you want to show that this listing accepts crypto */}
                  {car.cryptoAccepted && (
                    <Typography variant="body2" className={classes.cardDescription}>
                      Accepts Crypto
                    </Typography>
                  )}
                  <Button
                    className={classes.buttonNeon}
                    size="small"
                    style={{ marginTop: '10px' }}
                  >
                    Rent Now
                  </Button>
                </Paper>
              </Grid>
            ))}
          </Grid>
        ) : hasSearched ? (
          <Typography variant="h6" className={classes.noResultsText}>
            No cars found.
          </Typography>
        ) : null}
      </Container>

      {/* Car Details Modal */}
      {selectedCar && (
        <CarDetailsModal
          open={openModal}
          handleClose={handleCloseModal}
          selectedCar={selectedCar}
        />
      )}
    </Box>
  );
};

export default ExploreCarsPage;

// import React, { useState } from 'react';
// import {
//   Container,
//   Grid,
//   Paper,
//   Typography,
//   Button,
//   Box,
//   CardMedia,
//   CircularProgress
// } from '@mui/material';
// import useStyles from './ExploreCarsPageStyles';
// import useSearch from '../../hooks/useSearch';
// import CarDetailsModal from '../modals/CarDetailsModal';
// import ExploreCarsSearchSection from './ExploreCarsSearchSection';

// const ExploreCarsPage = () => {
//   // Local modal state management
//   const [openModal, setOpenModal] = useState(false);
//   const [selectedCar, setSelectedCar] = useState(null);
//   const classes = useStyles();

//   // Destructure search hook values and methods
//   const {
//     state,
//     city,
//     setCity,
//     filteredCities,
//     filteredCars,
//     cars,
//     loading,
//     showAdvanced,
//     setShowAdvanced,
//     make,
//     model,
//     setModel,
//     filteredModels,
//     handleStateChange,
//     handleMakeChange,
//     handleSearch,
//     carMakes,
//     states = [],
//     minPrice,
//     setMinPrice,
//     maxPrice,
//     setMaxPrice,
//   } = useSearch();

//   const [hasSearched, setHasSearched] = useState(false);
//   const carsToRender = filteredCars.length > 0 ? filteredCars : cars;

//   const handleSearchClick = () => {
//     handleSearch();
//     setHasSearched(true);
//   };

//   // Open/close modal functions
//   const handleOpenModal = (car) => {
//     setSelectedCar(car);
//     setOpenModal(true);
//   };

//   const handleCloseModal = () => {
//     setOpenModal(false);
//     setSelectedCar(null);
//   };

//   return (
//     <Box className={classes.root}>
//       <Container maxWidth="lg" className={classes.contentWrapper}>
//         <Typography variant="h4" className={classes.neonText}>
//           Explore Exotic Cars
//         </Typography>

//         {/* Search section imported from same directory */}
//         <ExploreCarsSearchSection
//           classes={classes}
//           state={state}
//           city={city}
//           setCity={setCity}
//           filteredCities={filteredCities}
//           showAdvanced={showAdvanced}
//           setShowAdvanced={setShowAdvanced}
//           make={make}
//           model={model}
//           setModel={setModel}
//           filteredModels={filteredModels}
//           handleStateChange={handleStateChange}
//           handleMakeChange={handleMakeChange}
//           handleSearchClick={handleSearchClick}
//           carMakes={carMakes}
//           states={states}
//           minPrice={minPrice}
//           setMinPrice={setMinPrice}
//           maxPrice={maxPrice}
//           setMaxPrice={setMaxPrice}
//         />

//         {/* Loading indicator and results */}
//         {loading ? (
//           <Box textAlign="center" marginTop="40px">
//             <CircularProgress color="inherit" className={classes.spinner} />
//             <Typography variant="h6" className={classes.loadingText}>
//               Searching cars...
//             </Typography>
//           </Box>
//         ) : hasSearched && carsToRender.length > 0 ? (
//           <Grid container spacing={4} className={classes.resultsContainer}>
//             {carsToRender.map((car) => (
//               <Grid item xs={12} sm={6} md={4} key={car.id}>
//                 <Paper
//                   sx={{ backgroundColor: 'rgba(27, 27, 27, 0.7)' }}
//                   className={classes.card}
//                   onClick={() => handleOpenModal(car)}
//                   elevation={3}
//                 >
//                   <CardMedia
//                     component="img"
//                     className={classes.cardImage}
//                     image={
//                       car.imageUrls && car.imageUrls[0]
//                         ? car.imageUrls[0]
//                         : `${process.env.PUBLIC_URL}/logo.png`
//                     }
//                     alt={car.make || 'Car'}
//                   />
//                   <Typography variant="h6" className={classes.cardTitle}>
//                     {car.make} {car.model}
//                   </Typography>
//                   <Typography
//                     variant="body2"
//                     className={classes.cardDescription}
//                   >
//                     {car.city || 'Unknown city'}, {car.state || 'Unknown state'}
//                   </Typography>
//                   <Typography
//                     variant="body2"
//                     className={classes.cardDescription}
//                   >
//                     {car.pricePerDay
//                       ? `$${car.pricePerDay} / day`
//                       : 'Price not available'}
//                   </Typography>
//                   <Button
//                     className={classes.buttonNeon}
//                     size="small"
//                     style={{ marginTop: '10px' }}
//                   >
//                     Rent Now
//                   </Button>
//                 </Paper>
//               </Grid>
//             ))}
//           </Grid>
//         ) : hasSearched ? (
//           <Typography variant="h6" className={classes.noResultsText}>
//             No cars found.
//           </Typography>
//         ) : null}
//       </Container>

//       {/* Car Details Modal */}
//       {selectedCar && (
//         <CarDetailsModal
//           open={openModal}
//           handleClose={handleCloseModal}
//           selectedCar={selectedCar}
//         />
//       )}
//     </Box>
//   );
// };

// export default ExploreCarsPage;




// useSubmitLead.js



import { ref, set } from 'firebase/database';
import { viceCityRealtimeDb } from '../firebase'; // Import Vice City Realtime Database
import { v4 as uuidv4 } from 'uuid'; // Generate unique IDs
import axios from 'axios'; // Axios for making API requests

const useSubmitLead = () => {
  // Function to calculate the number of days between two dates
  const calculateNumberOfDays = (fromDate, toDate) => {
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);
    const differenceInTime = endDate - startDate;
    return Math.ceil(differenceInTime / (1000 * 3600 * 24)); // Convert milliseconds to days
  };

  // Function to submit lead to Vice City VIP Firebase Realtime Database
  const submitLead = async (customerInfo, selectedCar, startDate, endDate) => {
    const numberOfDays = calculateNumberOfDays(startDate, endDate);
    const id = uuidv4(); // Generate a unique ID for the lead

    const leadData = {
      id,
      customerName: customerInfo.name,
      phone: customerInfo.phone,
      email: customerInfo.email,
      address: customerInfo.address,
      city: customerInfo.city,
      state: customerInfo.state,
      fromDate: startDate,
      toDate: endDate,
      numberOfDays: numberOfDays || 0, // Default to 0 if dates not selected
      unlockedCount: 0, // Default value
      brand: selectedCar.make, // Brand of the car
      model: selectedCar.model, // Model of the car
      dailyRate: selectedCar.pricePerDay || 0, // Daily rate of the car
      createdAt: new Date().toISOString(), // Date of lead creation
      rentingCity: selectedCar.city, // City where the car is located
      rentingState: selectedCar.state, // State where the car is located
      ownerId: selectedCar.uid ? selectedCar.uid : null
    };

    try {
      // Call the API to get the lead score
      const response = await axios.post(
        'https://vicecityvip-web.onrender.com/evaluate-lead',
        {
          name: customerInfo.name,
          email: customerInfo.email,
          phone: customerInfo.phone,
          carType: `${selectedCar.make} ${selectedCar.model}`,
          rentalDuration: numberOfDays,
          location: `${customerInfo.city}, ${customerInfo.state} renting in ${selectedCar.city}, ${selectedCar.state}`,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-API-Key': '{{token}}', // Replace {{token}} with your actual API key
          },
        }
      );

      // Extract confidence score from API response
      const leadQualityScore = response?.data?.confidenceScore || 0;
      leadData.leadQualityScore = leadQualityScore; // Include lead score in lead data
    } catch (error) {
      console.error('Error fetching lead score:', error);

      // Default the leadQualityScore to 0 in case of an error
      leadData.leadQualityScore = 0;
    }

    try {
      // Write the lead data to the Vice City VIP Firebase Realtime Database
      await set(ref(viceCityRealtimeDb, `leads/${id}`), leadData);
      console.log('✅ Lead submitted successfully to Vice City VIP:', leadData);
    } catch (error) {
      console.error('❌ Error submitting lead to Vice City VIP:', error);
    }
  };

  return {
    submitLead,
  };
};

export default useSubmitLead;

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#000',
    color: '#fff',
    minHeight: '100vh',
    paddingBottom: '100px',
    textAlign: 'center',
    animation: '$fadeIn 1.5s ease-in-out',
  },

  /* HERO SECTION */
  heroSection: {
    height: '60vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'url("/images/how-we-work-hero.jpg") center/cover',
    textAlign: 'center',
    padding: '20px',
    boxShadow: '0px 0px 100px rgba(247, 37, 133, 0.6)',
    borderBottom: '3px solid #23d5ab',
  },
  heroText: {
    color: '#f72585',
    fontSize: '4.5rem',
    fontWeight: 900,
    textTransform: 'uppercase',
    letterSpacing: '2px',
    textShadow: '0 0 40px #f72585, 0 0 70px #23d5ab',
    animation: '$glow 2s ease-in-out infinite alternate',
  },
  subText: {
    color: '#23d5ab',
    fontSize: '1.8rem',
    marginTop: '10px',
    textShadow: '0 0 25px #23d5ab',
  },

  /* CONTENT */
  contentContainer: {
    marginTop: '60px',
    padding: '0 30px',
  },

  /* SECTIONS */
  section: {
    padding: '45px 35px',
    margin: '25px 0',
    background: 'linear-gradient(135deg, rgba(20, 20, 20, 0.95), rgba(0, 0, 0, 0.98))',
    border: '3px solid #23d5ab',
    borderRadius: '25px',
    boxShadow: '0 0 60px rgba(35, 213, 171, 0.6), 0 0 90px rgba(247, 37, 133, 0.5)',
    transition: 'transform 0.4s ease, box-shadow 0.4s ease',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    '&:hover': {
      transform: 'scale(1.08)',
      boxShadow: '0 0 110px rgba(35, 213, 171, 0.7), 0 0 130px rgba(247, 37, 133, 0.6)',
    },
  },

  sectionTitle: {
    color: '#f72585',
    fontSize: '2.8rem',
    fontWeight: 800,
    marginBottom: '20px',
    textShadow: '0 0 30px #f72585, 0 0 50px #23d5ab',
  },

  sectionText: {
    color: '#c0c0c0',
    fontSize: '1.5rem',
    lineHeight: '1.9',
    textShadow: '0 0 12px #23d5ab',
  },

  sectionSubText: {
    marginTop: '15px',
    color: '#23d5ab',
    fontSize: '1.3rem',
    fontWeight: 600,
    textShadow: '0 0 12px #f72585',
  },

  /* ICONS */
  icon: {
    fontSize: '4rem',
    marginBottom: '25px',
    color: '#f72585',
    textShadow: '0 0 40px #f72585, 0 0 60px #23d5ab',
  },

  /* CTA SECTION */
  ctaSection: {
    marginTop: '60px',
    textAlign: 'center',
  },
  ctaButton: {
    padding: '18px 55px',
    fontSize: '1.7rem',
    fontWeight: 700,
    color: '#fff',
    backgroundColor: '#f72585',
    borderRadius: '50px',
    textShadow: '0 0 12px #f72585, 0 0 25px #23d5ab',
    border: '3px solid transparent',
    transition: 'transform 0.4s ease, box-shadow 0.4s ease, border 0.3s ease',
    '&:hover': {
      backgroundColor: '#23d5ab',
      boxShadow: '0 0 60px #23d5ab, 0 0 100px #f72585',
      border: '3px solid #f72585',
      transform: 'scale(1.08)',
    },
  },

  /* ANIMATIONS */
  '@keyframes glow': {
    '0%': {
      textShadow: '0 0 30px #f72585, 0 0 50px #23d5ab',
    },
    '100%': {
      textShadow: '0 0 50px #f72585, 0 0 80px #23d5ab',
    },
  },

  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
}));

export default useStyles;

// src/components/explorecars/SearchAdvancedOptions.jsx

import React from 'react';
import {
  Grid,
  TextField,
  Autocomplete,
  Collapse,
  Button,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

const SearchAdvancedOptions = ({
  classes,
  showAdvanced,
  setShowAdvanced,
  make,
  model,
  setModel,
  filteredModels,
  handleMakeChange,
  carMakes,
  minPrice,
  setMinPrice,
  maxPrice,
  setMaxPrice,
  cryptoAccepted,
  setCryptoAccepted
}) => {
  return (
    <>
      {/* Button to toggle Advanced Filters */}
      <Button
        className={classes.buttonNeon}
        onClick={() => setShowAdvanced(!showAdvanced)}
        endIcon={showAdvanced ? <ExpandLess /> : <ExpandMore />}
      >
        {showAdvanced ? 'Hide Advanced Filters' : 'Show Advanced Filters'}
      </Button>

      <Collapse in={showAdvanced} timeout="auto" unmountOnExit>
        {/* Make / Model */}
        <Grid container spacing={3} justifyContent="center" style={{ marginTop: '16px' }}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={carMakes || []}
              getOptionLabel={(option) => option.label || ''}
              value={make || null}
              onChange={handleMakeChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Make"
                  variant="outlined"
                  className={classes.inputField}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={filteredModels || []}
              getOptionLabel={(option) => option.label || ''}
              value={model || null}
              onChange={(event, value) => setModel(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Model"
                  variant="outlined"
                  className={classes.inputField}
                />
              )}
              disabled={!make}
            />
          </Grid>
        </Grid>

        {/* Price Range */}
        <Grid container spacing={3} justifyContent="center" style={{ marginTop: '16px' }}>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Min Price ($)"
              variant="outlined"
              value={minPrice || ''}
              onChange={(e) => setMinPrice(e.target.value)}
              className={classes.inputField}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Max Price ($)"
              variant="outlined"
              value={maxPrice || ''}
              onChange={(e) => setMaxPrice(e.target.value)}
              className={classes.inputField}
            />
          </Grid>
        </Grid>

        {/* Accepts Crypto */}
        <Grid container spacing={3} justifyContent="center" style={{ marginTop: '16px' }}>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                checked={cryptoAccepted}
                onChange={(e) => setCryptoAccepted(e.target.checked)}
                sx={{
                  color: "#23d5ab", // Neon green when unchecked
                  '&.Mui-checked': {
                    color: "#f72585", // Neon pink when checked
                  },
                }}
              />
              
              }
              label="Accepts Crypto"
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default SearchAdvancedOptions;

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '50px',
    textAlign: 'center',
    backgroundColor: '#111',
    color: '#fff',
    borderRadius: '15px',
    boxShadow: '0 0 60px #f72585, 0 0 80px #23d5ab',
    maxWidth: '600px',
    margin: 'auto',
    marginTop: '50px',
  },
  title: {
    color: '#23d5ab',
    textShadow: '0 0 10px #23d5ab, 0 0 20px #f72585',
  },
  leadCost: {
    color: '#f72585',
    textShadow: '0 0 10px #f72585',
  },
  textField: {
    backgroundColor: '#222',
    '& .MuiInputBase-input': {
      color: '#fff !important', // Force white text
    },
    '& .MuiInputLabel-root': {
      color: '#23d5ab !important', 
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#23d5ab !important',
      },
      '&:hover fieldset': {
        borderColor: '#f72585 !important',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#23d5ab !important',
      },
    },
  },
  cardElement: {
    backgroundColor: '#222 !important',
    padding: '15px !important',
    borderRadius: '8px !important',
    border: '1px solid #23d5ab !important',
    color: '#fff !important',
    fontSize: '18px !important',
    width: '100% !important',
    '&::placeholder': {
      color: '#bbb !important',
    },
  },
  confirmButton: {
    marginTop: '20px',
    backgroundColor: '#23d5ab',
    boxShadow: '0 0 20px #23d5ab',
    '&:hover': { backgroundColor: '#1cb993' },
    padding: '10px 20px',
    fontSize: '1.2rem',
  },
  loader: {
    color: '#23d5ab',
    marginTop: '20px',
  },
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '450px',
    backgroundColor: '#141414',
    color: '#fff',
    borderRadius: '15px',
    padding: '40px',
    boxShadow: '0 0 40px #f72585, 0 0 80px #23d5ab',
    textAlign: 'center',
    animation: '$modalGlow 2s infinite alternate',
  },
  modalTitle: {
    color: '#23d5ab',
    fontSize: '1.8rem',
    fontWeight: 'bold',
    textShadow: '0 0 10px #23d5ab, 0 0 20px #f72585',
  },
  modalText: {
    color: '#fff',
    marginBottom: '10px',
    fontSize: '1.1rem',
    textShadow: '0 0 10px rgba(255, 255, 255, 0.2)',
  },
  modalLeadCost: {
    color: '#f72585',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textShadow: '0 0 15px #f72585, 0 0 30px #23d5ab',
  },
  copyButton: {
    backgroundColor: '#f72585',
    color: '#fff',
    padding: '12px 25px',
    fontSize: '1.2rem',
    borderRadius: '8px',
    transition: '0.3s ease',
    cursor: 'pointer',
    boxShadow: '0px 0px 15px #f72585',
    border: 'none',
    '&:hover': {
      backgroundColor: '#23d5ab',
      color: '#000',
      boxShadow: '0px 0px 25px #23d5ab',
    },
  },
  
  closeButton: {
    backgroundColor: '#f72585',
    marginTop: '10px',
    marginLeft: '10px',
    boxShadow: '0 0 20px #f72585',
    '&:hover': { backgroundColor: '#f50057' },
    padding: '12px 20px',
    fontSize: '1.1rem',
    borderRadius: '8px',
  },
  '@keyframes modalGlow': {
    '0%': { boxShadow: '0 0 20px #23d5ab, 0 0 40px #f72585' },
    '100%': { boxShadow: '0 0 40px #f72585, 0 0 80px #23d5ab' },
  },
  disclaimerBox: {
    marginTop: '30px',
    padding: '20px',
    backgroundColor: '#222',
    borderRadius: '10px',
    boxShadow: '0 0 20px #f72585, 0 0 40px #23d5ab',
  },
  disclaimerTitle: {
    color: '#f72585',
    textShadow: '0 0 10px #f72585',
  },
  disclaimerText: {
    color: '#fff',
    textShadow: '0 0 10px #23d5ab',
  },
  exclusiveOption: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  infoIcon: {
    marginLeft: theme.spacing(1),
  },
}));

export default useStyles;

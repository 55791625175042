import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#111',
    padding: '40px',
    borderRadius: '15px',
    boxShadow: '0 0 60px #f72585, 0 0 80px #23d5ab',
    maxWidth: '800px',
    margin: 'auto',
    animation: '$fadeIn 0.8s ease-in-out',
  },
  neonText: {
    color: '#23d5ab',
    fontWeight: 700,
    fontSize: '2.5rem',
    textShadow: '0 0 10px #23d5ab, 0 0 20px #f72585',
    marginBottom: '30px',
  },
  form: {
    marginBottom: '30px',
  },
  textField: {
    backgroundColor: '#222',
    borderRadius: '8px',
    '& .MuiOutlinedInput-root': {
      color: '#fff',
      '& fieldset': {
        borderColor: '#23d5ab',
      },
      '&:hover fieldset': {
        borderColor: '#f72585',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#23d5ab',
      },
    },
    '& .MuiInputBase-input': {
      color: '#fff',
    },
  },
  switches: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  switchLabel: {
    color: '#fff',
    fontSize: '1rem',
  },
  saveButton: {
    backgroundColor: '#23d5ab',
    color: '#fff',
    fontWeight: 700,
    padding: '12px 25px',
    borderRadius: '8px',
    marginTop: '30px',
    boxShadow: '0 0 20px #23d5ab, 0 0 40px #f72585',
    '&:hover': {
      backgroundColor: '#f72585',
      boxShadow: '0 0 40px #23d5ab, 0 0 80px #f72585',
    },
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateY(50px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  uploadContainer: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imagePreviewContainer: {
    marginTop: '10px',
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    overflow: 'hidden',
    boxShadow: '0 0 10px #23d5ab',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#111',
  },
  profileThumbnail: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

export default useStyles;


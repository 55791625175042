// PurchaseModal.js



import React, { useState } from 'react';
import { Box, Typography, Button, Modal } from '@mui/material';
import { motion } from 'framer-motion'; // Added for smooth animations
import useStyles from './PurchasePageStyles';

const PurchaseModal = ({ modalOpen, handleModalClose, cartItems = [], totalPrice = 0 }) => {
  const classes = useStyles();
  const [copied, setCopied] = useState(false);

  // Create a formatted text block of lead details
  const generateLeadDetailsText = () => {
    if (cartItems.length === 0) return "No leads found.";

    return cartItems
      .map((item, index) => {
        const qualityPercentage = item.leadQualityScore
          ? `${item.leadQualityScore}%`
          : "N/A"; // Convert to percentage format

        return `
      Lead #${index + 1}:
      Customer: ${item.customerName || "N/A"}
      Car: ${item.brand || "Unknown"} ${item.model || ""}
      Rental Period: ${item.fromDate || "N/A"} to ${item.toDate || "N/A"}
      Address: ${item.address || "Not provided"}
      Email: ${item.email || "No email available"}
      Phone: ${item.phone || "No phone available"}
      Lead Quality: ${qualityPercentage}  ✅  // More intuitive label
      Renting In: ${item.rentingCity || "Unknown"}, ${
          item.rentingState || "Unknown"
        }
      Lead Cost: $${
        typeof item.price === "number" ? item.price.toFixed(2) : "0.00"
      }
      --------------------------------------
      `;
      })
      .join("\n");
  };
  const getLeadQualityLabel = (score) => {
    if (score >= 80) return "🔥 Excellent";
    if (score >= 60) return "✅ Good";
    if (score >= 40) return "⚠️ Fair";
    if (score > 0) return "❌ Poor";
    return "N/A";
  };


  // Copy to Clipboard Function
  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(generateLeadDetailsText());
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  };

  return (
    <Modal open={modalOpen} onClose={handleModalClose}>
      <Box className={classes.modalBox}>
        <Typography variant="h5" gutterBottom className={classes.modalTitle}>
          Purchase Successful!
        </Typography>

        {/* Display Cart Items */}
        {cartItems.length > 0 ? (
          cartItems.map((item, index) => (
            <Box key={index} mb={2}>
              <Typography variant="body1" gutterBottom className={classes.modalText}>
                Customer: {item.customerName || 'N/A'}
              </Typography>
              <Typography variant="body1" gutterBottom className={classes.modalText}>
                Car: {item.brand || 'Unknown'} {item.model || ''}
              </Typography>
              <Typography variant="body1" gutterBottom className={classes.modalText}>
                Rental Period: {item.fromDate || 'N/A'} to {item.toDate || 'N/A'}
              </Typography>
              <Typography variant="body1" gutterBottom className={classes.modalText}>
                Address: {item.address || 'Not provided'}
              </Typography>
              <Typography variant="body1" gutterBottom className={classes.modalText}>
                Email: {item.email || 'No email available'}
              </Typography>
              <Typography variant="body1" gutterBottom className={classes.modalText}>
                Phone: {item.phone || 'No phone available'}
              </Typography>
              <Typography variant="body1" gutterBottom className={classes.modalText}>
              Lead Quality: {getLeadQualityLabel(item.leadQualityScore)}

              </Typography>
              <Typography variant="body1" gutterBottom className={classes.modalText}>
                Renting In: {item.rentingCity || 'Unknown'}, {item.rentingState || 'Unknown'}
              </Typography>
              <Typography variant="h6" gutterBottom className={classes.modalLeadCost}>
                Lead Cost: ${typeof item.price === 'number' ? item.price.toFixed(2) : '0.00'}
              </Typography>
            </Box>
          ))
        ) : (
          <Typography variant="body1" className={classes.modalText}>
            No leads found.
          </Typography>
        )}

        {/* Display Total Price */}
        <Typography variant="h5" gutterBottom className={classes.totalCost}>
          Total Price: ${typeof totalPrice === 'number' ? totalPrice.toFixed(2) : '0.00'}
        </Typography>

        {/* Copy to Clipboard Button */}
        <motion.button
          whileHover={{ scale: 1.1, backgroundColor: "#23d5ab", color: "#000" }}
          whileTap={{ scale: 0.95 }}
          className={classes.copyButton}
          onClick={handleCopyToClipboard}
        >
          {copied ? "✅ Copied!" : "📋 Copy to Clipboard"}
        </motion.button>

        {/* Close Button */}
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className={classes.closeButton}
          onClick={handleModalClose}
        >
          ❌ Close
        </motion.button>
      </Box>
    </Modal>
  );
};

export default PurchaseModal;



// useStorePurchase.jsx


import { useState, useEffect } from "react";
import { getFirestore, collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { useAuth } from "./useAuth"; // Import useAuth to get currentUser

const useStorePurchase = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [purchases, setPurchases] = useState([]); // State to store fetched purchases
  const db = getFirestore();
  const { currentUser } = useAuth(); // Get authenticated user from context
  const currentUserEmail = currentUser?.email || ""; // ✅ Ensure email is always available

  // ✅ Function to store a new purchase
  const storePurchase = async (cartItems, totalPrice, paymentIntent) => {
    setLoading(true);
    setError(null);

    try {
      if (!currentUser) {
        throw new Error("User not authenticated");
      }

      if (!cartItems || cartItems.length === 0) {
        throw new Error("Cart is empty. No purchase to store.");
      }

      if (!paymentIntent || !paymentIntent.id) {
        throw new Error("Invalid paymentIntent. No payment ID found.");
      }

      // ✅ Store full lead details, not masked details
      const purchaseData = {
        userId: currentUser.uid,
        email: currentUser.email, // Store user email for reference
        items: cartItems.map(item => ({
          id: item.id || "N/A",
          name: item.name || "Unknown",
          brand: item.brand || "Unknown",
          model: item.model || "Unknown",
          rentingCity: item.rentingCity || "Unknown",
          rentingState: item.rentingState || "Unknown",
          price: typeof item.price === "number" ? item.price : 0, // Ensure price is a number
          purchasedAt: new Date().toISOString(),
          customerName: item.customerName || "N/A",
          phone: item.phone || "N/A",
          email: item.email || "N/A",
          address: item.address || "N/A",
        })),
        totalPrice: typeof totalPrice === "number" ? totalPrice : 0, // Ensure totalPrice is a number
        paymentId: paymentIntent.id,
      };

      // ✅ Remove any undefined values (Firestore does not accept undefined)
      Object.keys(purchaseData).forEach(key => {
        if (purchaseData[key] === undefined) {
          delete purchaseData[key];
        }
      });

      await addDoc(collection(db, "purchases"), purchaseData);

      console.log("✅ Purchase stored successfully:", purchaseData);
    } catch (err) {
      console.error("❌ Error storing purchase:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // ✅ Function to fetch purchases for the current user
  const fetchPurchases = async () => {
    setLoading(true);
    setError(null);

    try {
      if (!currentUser) {
        throw new Error("User not authenticated");
      }

      const q = query(collection(db, "purchases"), where("userId", "==", currentUser.uid));
      const querySnapshot = await getDocs(q);

      const userPurchases = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      setPurchases(userPurchases);
      console.log("✅ Purchases fetched successfully:", userPurchases);
    } catch (err) {
      console.error("❌ Error fetching purchases:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchPurchases();
    }
  }, [currentUser]); // Fetch purchases when user logs in

  return { storePurchase, fetchPurchases, purchases, currentUser, currentUserEmail, loading, error };
};

export default useStorePurchase;

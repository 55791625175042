
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  /* Main root container with black background and centered content */
  root: {
    backgroundColor: '#000',
    minHeight: '100vh',
    width: '100%',
    paddingTop: '40px',
    paddingBottom: '40px',
    display: 'flex',
    justifyContent: 'center',
  },

  /* Wraps the main content for consistent max-width and centering */
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    animation: '$fadeIn 1s ease-in-out',
  },

  /* Futuristic neon heading */
  neonText: {
    color: '#f72585',
    textShadow: '0 0 15px #f72585, 0 0 25px #23d5ab',
    fontWeight: 800,
    fontSize: '3rem',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    padding: '10px 20px',
    borderRadius: '10px',
    marginBottom: '30px',
    animation: '$pulse 2s infinite alternate',

    '&:hover': {
      textShadow: '0 0 20px #f72585, 0 0 30px #23d5ab',
      transition: 'text-shadow 0.3s ease-in-out',
    },
  },

  /* Container for search fields */
  searchContainer: {
    marginBottom: '20px',
    width: '100%',
    maxWidth: '1200px',
  },

  /* Input field styling: neon borders and labels */
  inputField: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#23d5ab',
      },
      '&:hover fieldset': {
        borderColor: '#f72585',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#23d5ab',
      },
    },
    '& .MuiInputBase-input': {
      color: '#fff',
    },
    '& .MuiInputLabel-outlined': {
      color: '#23d5ab',
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: '#23d5ab',
    },
  },

  /* Toggle advanced search button container */
  toggleAdvancedContainer: {
    textAlign: 'center',
    marginTop: '10px',
    marginBottom: '10px',
  },

  /* Advanced search fields container with subtle margin & transition if needed */
  advancedSearchContainer: {
    marginBottom: '20px',
    padding: '10px 0',
    backgroundColor: 'rgba(27, 27, 27, 0.5)',
    borderRadius: '10px',
  },

  /* Search button container below advanced options */
  searchButtonContainer: {
    textAlign: 'center',
    marginBottom: '20px',
  },

  /* Main neon button styling */
  buttonNeon: {
    color: '#23d5ab',
    border: '2px solid #23d5ab',
    textShadow: '0 0 5px #23d5ab, 0 0 15px #23d5ab',
    transition: 'box-shadow 0.4s ease, transform 0.4s ease',
    backgroundColor: 'transparent',

    '&:hover': {
      backgroundColor: '#f72585',
      borderColor: '#f72585',
      color: '#fff',
      boxShadow: '0 0 20px #f72585, 0 0 40px #23d5ab',
      transform: 'translateY(-2px)',
    },
  },

  /* Loading spinner styling */
  spinner: {
    color: '#23d5ab',
    width: '80px !important',
    height: '80px !important',
  },

  /* Container for the car cards */
  resultsContainer: {
    marginTop: '40px',
    width: '100%',
    maxWidth: '1200px',
  },

  /* Individual card styling */
  card: {
    position: 'relative',
    backgroundColor: 'rgba(27, 27, 27, 0.7)',
    border: '2px solid #23d5ab',
    padding: '20px',
    textAlign: 'center',
    color: '#fff',
    boxShadow:
      '0 0 40px #23d5ab, 0 0 60px #f72585, inset 0 0 10px rgba(255, 255, 255, 0.2)',
    transition: 'transform 0.4s ease, box-shadow 0.4s ease, color 0.4s ease',
    backdropFilter: 'blur(10px)',
    borderRadius: '15px',
    cursor: 'pointer',
    minHeight: '350px',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow:
        '0 0 60px #23d5ab, 0 0 90px #f72585, inset 0 0 20px rgba(255, 255, 255, 0.3)',
      backgroundColor: 'rgba(27, 27, 27, 0.9)',
      '& $cardTitle, & $cardDescription': {
        textShadow: '0 0 12px #23d5ab, 0 0 20px #f72585',
      },
    },
  },

  /* Car image styling */
  cardImage: {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
    borderRadius: '12px',
    boxShadow: '0 0 20px #23d5ab, 0 0 30px #f72585',
    transition: 'transform 0.4s ease, box-shadow 0.4s ease',
    '&:hover': {
      transform: 'scale(1.08)',
      boxShadow: '0 0 40px #23d5ab, 0 0 60px #f72585',
    },
  },

  /* Card title styling */
  cardTitle: {
    marginTop: '15px',
    marginBottom: '10px',
    color: '#fff',
    fontWeight: 700,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    padding: '5px 10px',
    borderRadius: '8px',
    display: 'inline-block',
    textShadow: '0 0 8px rgba(0, 0, 0, 0.5)',
    transition: 'color 0.3s ease, text-shadow 0.3s ease',
  },

  /* Card description styling */
  cardDescription: {
    color: '#fff',
    fontWeight: 400,
    padding: '3px 0',
    transition: 'color 0.3s ease, text-shadow 0.3s ease',
  },

  /* No results text styling */
  noResultsText: {
    color: '#f72585',
    textAlign: 'center',
    marginTop: '20px',
    textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab',
  },

  /* Loading text styling */
  loadingText: {
    color: '#f72585',
    textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab',
    marginTop: '10px',
  },

  /* Keyframes for subtle fade-in of the main content */
  '@keyframes fadeIn': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  },

  /* Keyframes for a glowing pulse on the heading */
  '@keyframes pulse': {
    '0%': {
      textShadow: '0 0 15px #f72585, 0 0 25px #23d5ab',
    },
    '100%': {
      textShadow: '0 0 25px #f72585, 0 0 45px #23d5ab',
    },
  },
}));

export default useStyles;


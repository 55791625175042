// SinglePostPage.js



import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import useStyles from "./SinglePostPageStyles";
import { ref, get } from "firebase/database";
import { thirdRealtimeDb } from "../../firebase";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import Footer from "../footer/Footer";

const SinglePostPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const defaultBanner = `${process.env.PUBLIC_URL}/news.webp`;

  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPost = async () => {
      const postRef = ref(thirdRealtimeDb, `articles/${id}`);
      const snapshot = await get(postRef);

      if (snapshot.exists()) {
        setPost(snapshot.val());
      } else {
        setPost(null);
      }

      setLoading(false);
    };

    fetchPost();
  }, [id]);

  if (loading) {
    return (
      <Box className={classes.loadingContainer}>
        <Typography variant="h4" className={classes.loadingText}>Loading...</Typography>
        <Box className={classes.loadingSpinner} />
      </Box>
    );
  }

  if (!post) {
    return (
      <Box className={classes.errorContainer}>
        <Typography variant="h4" className={classes.errorText}>Blog post not found.</Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.postContainer}>
      <Helmet>
        <title>{post.title.replace(/"/g, "")} | Vice City Blog</title>
        <meta name="description" content={post.content.substring(0, 150)} />
        <link rel="canonical" href={`https://vicecityvip.com/blog/${id}`} />
      </Helmet>

      <motion.img
        src={defaultBanner}
        alt="Blog Banner"
        className={classes.bannerImage}
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.6 }}
      />

      <motion.h2
        className={classes.postTitle}
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        {post.title.replace(/"/g, "")}
      </motion.h2>

      <Typography variant="body2" className={classes.postDate}>
        Published on: {post.date || "Unknown Date"}
      </Typography>

      <motion.div
        className={classes.postContent}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
        dangerouslySetInnerHTML={{ __html: post.content }}
      />

      <Box className={classes.socialIconsContainer}>
        <Typography variant="h6" className={classes.shareText}>
          Share this post:
        </Typography>
        <Box className={classes.socialIcons}>
          <motion.a
            whileHover={{ scale: 1.2 }}
            href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
              post.title
            )}&url=${window.location.href}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon className={classes.icon} />
          </motion.a>
          <motion.a
            whileHover={{ scale: 1.2 }}
            href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon className={classes.icon} />
          </motion.a>
          <motion.a
            whileHover={{ scale: 1.2 }}
            href="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon className={classes.icon} />
          </motion.a>
        </Box>
      </Box>

      <motion.button
        whileHover={{ scale: 1.1, backgroundColor: "#f72585", color: "#fff" }}
        whileTap={{ scale: 0.95 }}
        className={classes.goBackButton}
        onClick={() => navigate("/blog")}
      >
        ⬅️ Go Back to Blog
      </motion.button>

      <Footer />
    </Box>
  );
};

export default SinglePostPage;

// Registration.jsx

import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  Checkbox
} from "@mui/material";
import useStyles from "./FormStyles";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { states, cities } from "../data/statesAndCities"; // same data

const Registration = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { signup, authError, currentUser } = useAuth(); // Include currentUser

  const [isBusiness, setIsBusiness] = useState(false);
  const [includeBusinessDetails, setIncludeBusinessDetails] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
    phone: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    businessName: "",
    businessPhone: "",
    businessCity: "",
    businessState: "",
    businessZip: "",
    businessCountry: "",
  });


  const handleUserTypeChange = (event) => {
    setIsBusiness(event.target.value === "business");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleToggleBusinessDetails = (event) => {
    setIncludeBusinessDetails(event.target.checked);
  };

  const handleRegistration = async (e) => {
    e.preventDefault();
  
    if (!isBusiness) {
      return;
    }
  
    let businessAddress = null;
    if (isBusiness && includeBusinessDetails) {
      businessAddress = {
        businessName: formData.businessName,
        businessPhone: formData.businessPhone,
        businessCity: formData.businessCity,
        businessState: formData.businessState,
        businessZip: formData.businessZip,
        businessCountry: formData.businessCountry,
      };
    }
  
    try {
      await signup(formData.email, formData.password, {
        userType: isBusiness ? "business" : "personal",
        name: formData.name,
        phone: formData.phone,
        address: {
          city: formData.city,
          state: formData.state,
          zip: formData.zip,
          country: formData.country,
        },
        businessAddress,
      });
  
      // Introduce a 500ms delay before navigation
      setTimeout(() => {
        navigate("/dashboard");
      }, 500);
  
    } catch (error) {
      console.error("Registration error:", error);
    }
  };
  
  

  return (
    <Box>
      <RadioGroup
        row
        onChange={handleUserTypeChange}
        value={isBusiness ? "business" : "personal"}
        className={classes.radioGroup}
      >
        <FormControlLabel
          value="personal"
          control={<Radio />}
          label="Personal"
        />
        <FormControlLabel
          value="business"
          control={<Radio />}
          label="Business / Vehicle Owner"
        />
      </RadioGroup>

      {/* If user tries personal, show them a blocking message */}
      {!isBusiness && (
        <Typography variant="h6" color="error" className={classes.sectionTitle}>
          Personal accounts are not available at this time, please check back later.
        </Typography>
      )}

      {/* Only show the full form if isBusiness is chosen */}
      {isBusiness && (
        <form className={classes.form} onSubmit={handleRegistration}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Personal Information
          </Typography>
          <TextField
            name="name"
            label="Name"
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            margin="normal"
            className={classes.input}
          />
          <TextField
            name="phone"
            label="Phone"
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            margin="normal"
            className={classes.input}
          />

          {/* Personal State & City with Autocomplete */}
          <Autocomplete
            options={states.map((s) => s.label)}
            value={formData.state}
            onChange={(event, newValue) =>
              setFormData((prev) => ({ ...prev, state: newValue, city: "" }))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="State"
                variant="outlined"
                fullWidth
                margin="normal"
                className={classes.dropdown}
              />
            )}
          />
          <Autocomplete
            options={cities
              .filter((c) => c.state === formData.state)
              .map((c) => c.label)}
            value={formData.city}
            onChange={(event, newValue) =>
              setFormData((prev) => ({ ...prev, city: newValue }))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="City"
                variant="outlined"
                fullWidth
                margin="normal"
                className={classes.dropdown}
              />
            )}
          />
          <TextField
            name="zip"
            label="Zip"
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            margin="normal"
            className={classes.input}
          />
          <TextField
            name="country"
            label="Country"
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            margin="normal"
            className={classes.input}
          />

          <Typography variant="h6" className={classes.sectionTitle}>
            Optional Business / Vehicle Details
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={includeBusinessDetails}
                onChange={handleToggleBusinessDetails}
                color="primary"
              />
            }
            label="Include additional business or vehicle details?"
          />

          {/* Show business fields only if user wants to include them */}
          {includeBusinessDetails && (
            <>
              <TextField
                name="businessName"
                label="Business Name"
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                margin="normal"
                className={classes.input}
              />
              <TextField
                name="businessPhone"
                label="Business Phone"
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                margin="normal"
                className={classes.input}
              />

              <Autocomplete
                options={states.map((s) => s.label)}
                value={formData.businessState}
                onChange={(event, newValue) =>
                  setFormData((prev) => ({
                    ...prev,
                    businessState: newValue,
                    businessCity: "",
                  }))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Business State"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    className={classes.dropdown}
                  />
                )}
              />
              <Autocomplete
                options={cities
                  .filter((c) => c.state === formData.businessState)
                  .map((c) => c.label)}
                value={formData.businessCity}
                onChange={(event, newValue) =>
                  setFormData((prev) => ({ ...prev, businessCity: newValue }))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Business City"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    className={classes.dropdown}
                  />
                )}
              />
              <TextField
                name="businessZip"
                label="Business Zip"
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                margin="normal"
                className={classes.input}
              />
              <TextField
                name="businessCountry"
                label="Business Country"
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                margin="normal"
                className={classes.input}
              />
            </>
          )}

          <Typography variant="h6" className={classes.sectionTitle}>
            Account Information
          </Typography>
          <TextField
            name="email"
            label="Email"
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            margin="normal"
            className={classes.input}
          />
          <TextField
            name="password"
            label="Password"
            onChange={handleInputChange}
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            className={classes.input}
          />

          {authError && (
            <Typography color="error" className={classes.errorText}>
              {authError}
            </Typography>
          )}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.submitButton}
          >
            Register
          </Button>
        </form>
      )}
    </Box>
  );
};

export default Registration;

// PurchasePage.js




import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, CircularProgress, TextField } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import PurchaseModal from './PurchaseModal';
import useStyles from './PurchasePageStyles';
import Disclaimer from './Disclaimer';
import useStorePurchase from "../../hooks/useStorePurchase"; 

// ✅ Load Stripe Public Key
// const stripePromise = loadStripe('pk_live_51MemPIEVc52nTNqBnotjPz4IPispE6HKUASHAkcNfN6c6Kqcbv0em0YbAXGrJRlrW5nsLFL1cVUdN5AxfG23PrJl005I35Puhu');

const PurchasePage = () => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const location = useLocation();
  const { storePurchase, purchases, currentUser } = useStorePurchase();
  const currentUserEmail = purchases.length > 0 ? purchases[0].email : currentUser?.email || ""; // ✅ Use fallback from useAuth
  

  const { cartItems: initialCartItems = [], totalPrice: initialTotalPrice = 0 } = location.state || {}; 

  const [cartItems, setCartItems] = useState(initialCartItems.map(item => ({
    ...item,
    price: typeof item.price === 'number' ? item.price : 0
  })));

  const [totalPrice, setTotalPrice] = useState(
    initialTotalPrice || cartItems.reduce((acc, item) => acc + (item.price || 0), 0)
  );

  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [billingDetails, setBillingDetails] = useState({ name: '', billingZip: '' });

  useEffect(() => {
    fetchUpdatedLeads();
  }, []);

  const handleBillingDetailsChange = (e) => {
    const { name, value } = e.target;
    setBillingDetails({ ...billingDetails, [name]: value });
  };

  const handleConfirmPurchase = async () => {
    if (!stripe || !elements) return;
  
    setLoading(true);
  
    try {
      let finalAmount = parseFloat(totalPrice);
      if (isNaN(finalAmount) || finalAmount <= 0) {
        alert("Invalid total price. Please try again.");
        setLoading(false);
        return;
      }
  
      finalAmount = Math.round(finalAmount * 100);
  
      console.log("Sending request with email:", currentUserEmail);
  
      // ✅ Send email along with amount
      const response = await fetch("https://vicecityvip-stripe.onrender.com/create-payment-intent", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          amount: finalAmount,
          email: currentUserEmail,
        }),
      });
  
      const responseData = await response.json();
      console.log("Response from server:", responseData);
  
      if (!response.ok) {
        throw new Error(responseData.error || "Error creating payment intent");
      }
  
      const { clientSecret } = responseData;
      if (!clientSecret) {
        throw new Error("Failed to retrieve clientSecret from Stripe API");
      }
  
      const cardElement = elements.getElement(CardElement);
  
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: billingDetails.name,
            email: currentUserEmail,
            address: { postal_code: billingDetails.billingZip },
          },
        },
      });
  
      if (error) {
        console.error("Payment failed:", error);
        alert(`Payment Failed: ${error.message}`); // ✅ Show Alert
        setLoading(false);
        return;
      }
  
      console.log("Payment successful:", paymentIntent);
      await storePurchase(cartItems, totalPrice, paymentIntent);
  
      fetchUpdatedLeads();
      setLoading(false);
      setModalOpen(true);
    } catch (err) {
      console.error("Error processing payment:", err);
      alert(`Error: ${err.message}`); // ✅ Show alert on network or server error
      setLoading(false);
    }
  };
  
  
  
  // ✅ Fetch leads from the API
  const fetchUpdatedLeads = async () => {
    try {
      const response = await fetch('https://vicecityvip-stripe.onrender.com/fetch-leads'); 

      if (!response.ok) {
        throw new Error(`Error fetching leads: ${response.statusText}`);
      }

      const data = await response.json();

      const updatedCartItems = cartItems.map(item => {
        const matchedLead = data.find(lead => lead.id === item.id);
        return matchedLead ? { ...matchedLead, price: matchedLead.price ?? item.price } : item;
      });

      setCartItems(updatedCartItems);
      setTotalPrice(updatedCartItems.reduce((acc, item) => acc + (item.price || 0), 0));
    } catch (err) {
      console.error("Error fetching leads:", err);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    navigate('/dashboard');
  };

  const maskLeadDetails = (lead) => ({
    ...lead,
    customerName: lead.customerName
      ? `${lead.customerName.split(" ")[0]} ${lead.customerName.charAt(0)}.`
      : "N/A",
    phone: lead.phone ? `XXX-XXX-${lead.phone.slice(-4)}` : "XXX-XXX-XXXX",
    email: lead.email ? `${lead.email.charAt(0)}***@${lead.email.split("@")[1]}` : "*****@*****",
    address: "Hidden until purchase",
  });

  return (
    <Box className={classes.container}>
      <Typography variant="h4" gutterBottom className={classes.title}>
        Confirm Your Purchase
      </Typography>

      {/* ✅ Display Masked Details Before Purchase */}
      {cartItems.map((item, index) => {
        const maskedItem = maskLeadDetails(item);

        return (
          <Box key={index} mb={2}>
            <Typography variant="body1" gutterBottom>
              Customer: {maskedItem.customerName}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Car: {maskedItem.brand || "Unknown"} {maskedItem.model || ""}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Address: {maskedItem.address}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Email: {maskedItem.email}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Phone: {maskedItem.phone}
            </Typography>
            <Typography variant="h6" gutterBottom className={classes.leadCost}>
              Lead Cost: ${maskedItem.price.toFixed(2)}
            </Typography>
          </Box>
        );
      })}

      <Typography variant="h5" gutterBottom className={classes.totalPrice}>
        Total Price: ${totalPrice.toFixed(2)}
      </Typography>

      {/* ✅ Payment Inputs */}
      {/* ✅ Display User Email (Read-Only) */}
      <TextField
        label="Email Address"
        name="email"
        type="email"
        value={currentUserEmail || ""}
        fullWidth
        required
        className={classes.textField}
        style={{ marginBottom: "16px" }}
        disabled // Prevent user from editing email
        sx={{
          input: {
            WebkitTextFillColor: "#f72585 !important", // ✅ Ensures color applies to disabled text
            opacity: 1, // ✅ Prevents default Material-UI opacity for disabled inputs
          },
        }}
      />

      <TextField
        label="Name on Card"
        name="name"
        value={billingDetails.name}
        onChange={handleBillingDetailsChange}
        fullWidth
        required
        className={classes.textField}
        style={{ marginBottom: "16px" }}
      />

      <Box className={classes.cardForm}>
        <CardElement
          className={classes.cardElement}
          options={{
            style: {
              base: {
                color: "#fff", // Force white text
                fontSize: "18px",
                "::placeholder": {
                  color: "#f42579", // Light pink placeholder
                },
              },
              invalid: {
                color: "#f72585", // Error state in bright pink
                hidePostalCode: false,
              },
            },
          }}
        />
      </Box>

      {loading ? (
        <CircularProgress className={classes.loader} />
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={handleConfirmPurchase}
          className={classes.confirmButton}
          disabled={!stripe || loading}
        >
          Confirm Purchase
        </Button>
      )}

      <Disclaimer />

      <PurchaseModal
        modalOpen={modalOpen}
        handleModalClose={handleModalClose}
        cartItems={cartItems}
        totalPrice={totalPrice}
        copied={copied}
      />
    </Box>
  );
};

const WrappedPurchasePage = () => (
  // <Elements stripe={stripePromise}>
    <PurchasePage />
  // </Elements>
);

export default WrappedPurchasePage;

// HomePage.js


import React from 'react';
import { Box, Container, Typography, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import useStyles from './HomePageStyles';
import HeroSection from './HeroSection';
import Header from './Header.';
import Footer from '../footer/Footer';
import FeaturedCars from './FeaturedCars';

const HomePage = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate("/explore-cars");
  };

  return (

    <>
    <Helmet>
  {/* Primary Meta Tags */}
  <title>Exotic Car Rentals & Luxury Marketplace | Vice City VIP</title>
  <meta
    name="description"
    content="Explore the ultimate luxury marketplace for exotic car rentals, yachts, and high-end properties. Experience top-tier service and exclusive vehicles."
  />
  <meta
    name="keywords"
    content="Exotic car rentals, Luxury rentals, Supercars, High-end vehicles, VIP car rentals, Yacht rentals, Luxury marketplace"
  />
  <meta name="author" content="Vice City VIP" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://www.vicecityvip.com/" />

  {/* Open Graph Meta Tags for Facebook, LinkedIn, etc. */}
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://www.vicecityvip.com/" />
  <meta property="og:title" content="Exotic Car Rentals & Luxury Marketplace | Vice City VIP" />
  <meta property="og:description" content="Rent luxury and exotic cars, yachts, and high-end properties with ease. Vice City VIP is your trusted luxury marketplace." />
  <meta property="og:image" content="https://www.vicecityvip.com/logo.png" />
  <meta property="og:site_name" content="Vice City VIP" />

  {/* Twitter Meta Tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:url" content="https://x.com/Vice_VIP" />
  <meta name="twitter:title" content="Exotic Car Rentals & Luxury Marketplace | Vice City VIP" />
  <meta name="twitter:description" content="Discover the elite rental marketplace for exotic cars, yachts, and luxury properties." />
  <meta name="twitter:image" content="https://www.vicecityvip.com/twitter-image.png" />

  {/* Structured Data (Schema.org JSON-LD for SEO) */}
  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "CarRental",
      "name": "Vice City VIP",
      "url": "https://www.vicecityvip.com/",
      "description": "Luxury and exotic car rental service offering high-end vehicles, yachts, and properties.",
      "image": "https://www.vicecityvip.com/icon.png",
      "contactPoint": {
        "@type": "ContactPoint",
        "email": "support@vicecityvip.com",
        "contactType": "Customer Service"
      }
    })}
  </script>

  {/* Performance & Security Enhancements */}
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://www.vicecityvip.com" />
</Helmet>

    <div className={classes.root}>
      <Header />

      <HeroSection />

      {/* Featured Cars Section */}
      <Box id="featured-cars" className={classes.featuredCarsSection}>
        <Container maxWidth="lg" style={{ padding: "50px 0" }}>
          <FeaturedCars />
        </Container>
      </Box>

      {/* "Why Choose Us?" Section for a better user experience */}
      <Box className={classes.whyChooseSection}>
        <Container maxWidth="lg">
          <Typography
            variant="h4"
            className={`${classes.neonText} ${classes.sectionTitle}`}
            gutterBottom
          >
            Why Choose Us?
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {/* Extensive Fleet */}
            <Grid item xs={12} md={4}>
              <Box className={classes.featureItem}>
                <img
                  src="/extensive.webp"
                  alt="Wide Selection"
                  className={classes.featureIcon}
                />
                <Typography variant="h6" className={classes.featureTitle}>
                  Extensive Fleet
                </Typography>
                <Typography className={classes.featureDescription}>
                  From Lamborghinis to Ferraris, access an unmatched selection
                  of exotic cars for any occasion.
                </Typography>
              </Box>
            </Grid>

            {/* Seamless Booking */}
            <Grid item xs={12} md={4}>
              <Box className={classes.featureItem}>
                <img
                  src="/booking.webp"
                  alt="Seamless Booking"
                  className={classes.featureIcon}
                />
                <Typography variant="h6" className={classes.featureTitle}>
                  Seamless Booking
                </Typography>
                <Typography className={classes.featureDescription}>
                  Browse and book your dream car effortlessly with a fast and
                  hassle-free rental process.
                </Typography>
              </Box>
            </Grid>

            {/* Luxury at the Best Price */}
            <Grid item xs={12} md={4}>
              <Box className={classes.featureItem}>
                <img
                  src="/deals.webp"
                  alt="Luxury for Less"
                  className={classes.featureIcon}
                />
                <Typography variant="h6" className={classes.featureTitle}>
                  Luxury at the Best Price
                </Typography>
                <Typography className={classes.featureDescription}>
                  Experience high-end exotic cars at competitive rates, ensuring
                  you get the best deal on luxury rentals.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Final Call to Action Section */}
      <Box
        style={{
          backgroundColor: "#000",
          padding: "50px 0",
          textAlign: "center",
        }}
      >
        <Typography variant="h4" className={classes.neonText}>
          Ready to Ride?
        </Typography>
        <Button
          className={classes.buttonNeon}
          size="large"
          style={{ marginTop: "20px" }}
          onClick={handleGetStarted}
        >
          Get Started
        </Button>
      </Box>

      <Footer />
    </div>
    </>
  );
};

export default HomePage;

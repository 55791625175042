// src/components/explorecars/ExploreCarsSearchSection.jsx



// src/components/explorecars/ExploreCarsSearchSection.jsx

import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Button,
  Autocomplete,
  Box,
  Collapse,
  Typography,
  LinearProgress
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { motion } from 'framer-motion';
import SearchAdvancedOptions from './SearchAdvancedOptions'; // <-- Import your new helper

const ExploreCarsSearchSection = ({
  classes,
  state,
  city,
  setCity,
  filteredCities,
  showAdvanced,
  setShowAdvanced,
  make,
  model,
  setModel,
  filteredModels,
  handleStateChange,
  handleMakeChange,
  handleSearchClick,
  carMakes,
  states,
  minPrice,
  setMinPrice,
  maxPrice,
  setMaxPrice,
  // NEW PROPS for crypto filter
  cryptoAccepted,
  setCryptoAccepted,
}) => {
  const [step, setStep] = useState(1);
  const progress = (step / 4) * 100;

  const handleNextStep = () => {
    setStep((prev) => prev + 1);
  };
  const handleBackStep = () => {
    setStep((prev) => prev - 1);
  };
  const handleReset = () => {
    setStep(1);
    setShowAdvanced(false);
  };
  const handleSkipCity = () => {
    setCity(null);
    handleNextStep();
  };
  const handleSkipAdvanced = () => {
    handleMakeChange(null, "");
    setModel(null);
    setMinPrice('');
    setMaxPrice('');
    setCryptoAccepted(false);
    setShowAdvanced(false);
    handleNextStep();
  };

  // Keyboard "Enter" navigation
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        if (step === 1 && state) {
          handleNextStep();
        } else if (step === 2) {
          handleNextStep();
        } else if (step === 3) {
          handleNextStep();
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [step, state]);

  return (
    <Box
      className={classes.searchWrapper}
      style={{ textAlign: 'center', padding: '30px', position: 'relative' }}
    >
      {/* Progress Bar */}
      <LinearProgress
        variant="determinate"
        value={progress}
        className={classes.progressBar}
      />

      {/* Title / Step Headline */}
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Typography
          variant="h5"
          className={classes.guidedTitle}
          style={{ marginBottom: '30px' }}
        >
          Let’s Find Your Perfect Ride 🚀
        </Typography>
      </motion.div>

      {/* STEP 1: State */}
      {step === 1 && (
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={states}
                getOptionLabel={(option) => option.label || ''}
                value={state || null}
                onChange={handleStateChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select State"
                    variant="outlined"
                    placeholder="Where do you need the car?"
                    className={classes.inputField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} className={classes.buttonContainer}>
              <Button
                className={classes.buttonNeon}
                onClick={handleNextStep}
                disabled={!state}
              >
                Next →
              </Button>
            </Grid>
          </Grid>
        </motion.div>
      )}

      {/* STEP 2: City */}
      {step === 2 && (
        <motion.div
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={filteredCities}
                getOptionLabel={(option) => option.label || ''}
                value={city || null}
                onChange={(event, value) => setCity(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select City"
                    variant="outlined"
                    placeholder="Choose your pickup location"
                    className={classes.inputField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} className={classes.buttonContainer}>
              <Button className={classes.buttonNeon} onClick={handleBackStep}>
                ← Back
              </Button>
              <Button
                className={classes.buttonNeon}
                onClick={handleNextStep}
                disabled={!city}
              >
                Next →
              </Button>
              <Button className={classes.buttonNeon} onClick={handleSkipCity}>
                Skip →
              </Button>
            </Grid>
          </Grid>
        </motion.div>
      )}

      {/* STEP 3: Advanced Options (moved to helper) */}
      {step === 3 && (
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <SearchAdvancedOptions
                classes={classes}
                showAdvanced={showAdvanced}
                setShowAdvanced={setShowAdvanced}
                make={make}
                model={model}
                setModel={setModel}
                filteredModels={filteredModels}
                handleMakeChange={handleMakeChange}
                carMakes={carMakes}
                minPrice={minPrice}
                setMinPrice={setMinPrice}
                maxPrice={maxPrice}
                setMaxPrice={setMaxPrice}
                cryptoAccepted={cryptoAccepted}
                setCryptoAccepted={setCryptoAccepted}
              />
            </Grid>
            <Grid item xs={12} className={classes.buttonContainer}>
              <Button className={classes.buttonNeon} onClick={handleBackStep}>
                ← Back
              </Button>
              <Button className={classes.buttonNeon} onClick={handleNextStep}>
                Next →
              </Button>
              <Button className={classes.buttonNeon} onClick={handleSkipAdvanced}>
                Skip →
              </Button>
            </Grid>
          </Grid>
        </motion.div>
      )}

      {/* STEP 4: Final Confirmation */}
      {step === 4 && (
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.confirmText}>
                All set! Click below to search for cars.
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.buttonContainer}>
              <Button className={classes.buttonNeon} onClick={handleBackStep}>
                ← Back
              </Button>
              <motion.button
                whileHover={{
                  scale: 1.1,
                  boxShadow: '0px 0px 15px rgba(0,255,0,0.8)'
                }}
                whileTap={{ scale: 0.95 }}
                style={{
                  background: 'linear-gradient(45deg, #0f0, #00f)',
                  color: '#fff',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  padding: '12px 24px',
                  borderRadius: '8px',
                  border: 'none',
                  cursor: 'pointer',
                  transition: 'all 0.3s ease',
                  boxShadow: '0px 0px 10px rgba(0,255,0,0.5)'
                }}
                onClick={handleSearchClick}
              >
                🚀 Search Now!
              </motion.button>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.buttonContainer}
              style={{ marginTop: '20px' }}
            >
              <Button className={classes.buttonNeon} onClick={handleReset}>
                Start Over
              </Button>
            </Grid>
          </Grid>
        </motion.div>
      )}
    </Box>
  );
};

export default ExploreCarsSearchSection;


// import React, { useState, useEffect } from 'react';
// import {
//   Grid,
//   TextField,
//   Button,
//   Autocomplete,
//   Box,
//   Collapse,
//   Typography,
//   LinearProgress
// } from '@mui/material';
// import { ExpandMore, ExpandLess } from '@mui/icons-material';
// import { motion } from 'framer-motion';

// const ExploreCarsSearchSection = ({
//   classes,
//   state,
//   city,
//   setCity,
//   filteredCities,
//   showAdvanced,
//   setShowAdvanced,
//   make,
//   model,
//   setModel,
//   filteredModels,
//   handleStateChange,
//   handleMakeChange,
//   handleSearchClick,
//   carMakes,
//   states,
//   minPrice,
//   setMinPrice,
//   maxPrice,
//   setMaxPrice,
// }) => {
//   const [step, setStep] = useState(1); // Step-based navigation

//   // Progress percentage (based on 4 steps)
//   const progress = (step / 4) * 100;

//   // Move to next step
//   const handleNextStep = () => {
//     setStep((prev) => prev + 1);
//   };

//   // Move to previous step
//   const handleBackStep = () => {
//     setStep((prev) => prev - 1);
//   };

//   // Reset the search process
//   const handleReset = () => {
//     setStep(1);
//     setShowAdvanced(false);
//   };

//   // Function to skip Step 2 (City selection)
//   const handleSkipCity = () => {
//     // Optionally clear city if skipping
//     setCity(null);
//     handleNextStep();
//   };

//   // Function to skip Step 3 (Advanced filters)
//   const handleSkipAdvanced = () => {
//     // Clear advanced fields (if needed)
//     handleMakeChange(null, ""); // Ensure safe handling of null
//     setModel(null);
//     setMinPrice('');
//     setMaxPrice('');
//     setShowAdvanced(false);
//     handleNextStep();
//   };
  

//   // Handle keyboard "Enter" navigation:
//   // - Step 1: only move on if state is selected.
//   // - Steps 2 and 3: always advance (i.e. skip) if Enter is pressed.
//   useEffect(() => {
//     const handleKeyDown = (event) => {
//       if (event.key === 'Enter') {
//         if (step === 1 && state) {
//           handleNextStep();
//         } else if (step === 2) {
//           // For step 2, pressing Enter advances without checking city.
//           handleNextStep();
//         } else if (step === 3) {
//           // For step 3, pressing Enter advances without affecting advanced filters.
//           handleNextStep();
//         }
//       }
//     };
//     document.addEventListener('keydown', handleKeyDown);
//     return () => document.removeEventListener('keydown', handleKeyDown);
//   }, [step, state]);

//   return (
//     <Box
//       className={classes.searchWrapper}
//       style={{ textAlign: "center", padding: "30px", position: "relative" }}
//     >
//       {/* Progress Bar */}
//       <LinearProgress
//         variant="determinate"
//         value={progress}
//         className={classes.progressBar}
//       />

//       <motion.div
//         initial={{ opacity: 0, y: -10 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 0.5 }}
//       >
//         <Typography
//           variant="h5"
//           className={classes.guidedTitle}
//           style={{ marginBottom: "30px" }}
//         >
//           Let’s Find Your Perfect Ride 🚀
//         </Typography>
//       </motion.div>

//       {/* Step 1: Select State (mandatory) */}
//       {step === 1 && (
//         <motion.div
//           initial={{ opacity: 0, x: -50 }}
//           animate={{ opacity: 1, x: 0 }}
//           transition={{ duration: 0.5 }}
//         >
//           <Grid
//             container
//             spacing={3}
//             justifyContent="center"
//             alignItems="center"
//           >
//             <Grid item xs={12} sm={6}>
//               <Autocomplete
//                 options={states}
//                 getOptionLabel={(option) => option.label || ""}
//                 value={state || null}
//                 onChange={handleStateChange}
//                 renderInput={(params) => (
//                   <TextField
//                     {...params}
//                     label="Select State"
//                     variant="outlined"
//                     placeholder="Where do you need the car?"
//                     className={classes.inputField}
//                   />
//                 )}
//               />
//             </Grid>
//             <Grid item xs={12} className={classes.buttonContainer}>
//               <Button
//                 className={classes.buttonNeon}
//                 onClick={handleNextStep}
//                 disabled={!state}
//               >
//                 Next →
//               </Button>
//             </Grid>
//           </Grid>
//         </motion.div>
//       )}

//       {/* Step 2: Select City (skippable) */}
//       {step === 2 && (
//         <motion.div
//           initial={{ opacity: 0, x: 50 }}
//           animate={{ opacity: 1, x: 0 }}
//           transition={{ duration: 0.5 }}
//         >
//           <Grid
//             container
//             spacing={3}
//             justifyContent="center"
//             alignItems="center"
//           >
//             <Grid item xs={12} sm={6}>
//               <Autocomplete
//                 options={filteredCities}
//                 getOptionLabel={(option) => option.label || ""}
//                 value={city || null}
//                 onChange={(event, value) => setCity(value)}
//                 renderInput={(params) => (
//                   <TextField
//                     {...params}
//                     label="Select City"
//                     variant="outlined"
//                     placeholder="Choose your pickup location"
//                     className={classes.inputField}
//                   />
//                 )}
//               />
//             </Grid>
//             <Grid item xs={12} className={classes.buttonContainer}>
//               <Button className={classes.buttonNeon} onClick={handleBackStep}>
//                 ← Back
//               </Button>
//               <Button
//                 className={classes.buttonNeon}
//                 onClick={handleNextStep}
//                 disabled={!city}
//               >
//                 Next →
//               </Button>
//               <Button className={classes.buttonNeon} onClick={handleSkipCity}>
//                 Skip →
//               </Button>
//             </Grid>
//           </Grid>
//         </motion.div>
//       )}

//       {/* Step 3: Advanced Search Options (skippable) */}
//       {step === 3 && (
//         <motion.div
//           initial={{ opacity: 0, scale: 0.9 }}
//           animate={{ opacity: 1, scale: 1 }}
//           transition={{ duration: 0.5 }}
//         >
//           <Grid
//             container
//             spacing={3}
//             justifyContent="center"
//             alignItems="center"
//           >
//             <Grid item xs={12}>
//               <Button
//                 className={classes.buttonNeon}
//                 onClick={() => setShowAdvanced(!showAdvanced)}
//                 endIcon={showAdvanced ? <ExpandLess /> : <ExpandMore />}
//               >
//                 {showAdvanced
//                   ? "Hide Advanced Filters"
//                   : "Show Advanced Filters"}
//               </Button>
//             </Grid>

//             <Collapse in={showAdvanced} timeout="auto" unmountOnExit>
//               {/* Advanced Search Fields in two rows for better width */}
//               <Grid
//                 container
//                 spacing={3}
//                 justifyContent="center"
//                 style={{ marginTop: "16px" }}
//               >
//                 <Grid item xs={12} sm={6}>
//                   <Autocomplete
//                     options={carMakes || []}
//                     getOptionLabel={(option) => option.label || ""}
//                     value={make || null}
//                     onChange={handleMakeChange}
//                     renderInput={(params) => (
//                       <TextField
//                         {...params}
//                         label="Make"
//                         variant="outlined"
//                         className={classes.inputField}
//                       />
//                     )}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Autocomplete
//                     options={filteredModels || []}
//                     getOptionLabel={(option) => option.label || ""}
//                     value={model || null}
//                     onChange={(event, value) => setModel(value)}
//                     renderInput={(params) => (
//                       <TextField
//                         {...params}
//                         label="Model"
//                         variant="outlined"
//                         className={classes.inputField}
//                       />
//                     )}
//                     disabled={!make}
//                   />
//                 </Grid>
//               </Grid>
//               <Grid
//                 container
//                 spacing={3}
//                 justifyContent="center"
//                 style={{ marginTop: "16px" }}
//               >
//                 <Grid item xs={12} sm={3}>
//                   <TextField
//                     label="Min Price ($)"
//                     variant="outlined"
//                     value={minPrice || ""}
//                     onChange={(e) => setMinPrice(e.target.value)}
//                     className={classes.inputField}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={3}>
//                   <TextField
//                     label="Max Price ($)"
//                     variant="outlined"
//                     value={maxPrice || ""}
//                     onChange={(e) => setMaxPrice(e.target.value)}
//                     className={classes.inputField}
//                   />
//                 </Grid>
//               </Grid>
//             </Collapse>

//             <Grid item xs={12} className={classes.buttonContainer}>
//               <Button className={classes.buttonNeon} onClick={handleBackStep}>
//                 ← Back
//               </Button>
//               <Button className={classes.buttonNeon} onClick={handleNextStep}>
//                 Next →
//               </Button>
//               <Button
//                 className={classes.buttonNeon}
//                 onClick={handleSkipAdvanced}
//               >
//                 Skip →
//               </Button>
//             </Grid>
//           </Grid>
//         </motion.div>
//       )}

//       {/* Step 4: Final Confirmation and Search */}
//       {step === 4 && (
//         <motion.div
//           initial={{ opacity: 0, y: 30 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 0.5 }}
//         >
//           <Grid
//             container
//             spacing={3}
//             justifyContent="center"
//             alignItems="center"
//           >
//             <Grid item xs={12}>
//               <Typography variant="h6" className={classes.confirmText}>
//                 All set! Click below to search for cars.
//               </Typography>
//             </Grid>
//             <Grid item xs={12} className={classes.buttonContainer}>
//               <Button className={classes.buttonNeon} onClick={handleBackStep}>
//                 ← Back
//               </Button>
//               <motion.button
//                 whileHover={{
//                   scale: 1.1,
//                   boxShadow: "0px 0px 15px rgba(0,255,0,0.8)",
//                 }}
//                 whileTap={{ scale: 0.95 }}
//                 style={{
//                   background: "linear-gradient(45deg, #0f0, #00f)",
//                   color: "#fff",
//                   fontSize: "18px",
//                   fontWeight: "bold",
//                   padding: "12px 24px",
//                   borderRadius: "8px",
//                   border: "none",
//                   cursor: "pointer",
//                   transition: "all 0.3s ease",
//                   boxShadow: "0px 0px 10px rgba(0,255,0,0.5)",
//                 }}
//                 onClick={handleSearchClick}
//               >
//                 🚀 Search Now!
//               </motion.button>
//             </Grid>
//             <Grid
//               item
//               xs={12}
//               className={classes.buttonContainer}
//               style={{ marginTop: "20px" }}
//             >
//               <Button className={classes.buttonNeon} onClick={handleReset}>
//                 Start Over
//               </Button>
//             </Grid>
//           </Grid>
//         </motion.div>
//       )}
//     </Box>
//   );
// };

// export default ExploreCarsSearchSection;



// useUserListings.jsx



import { useState, useEffect } from 'react';
import { ref as dbRef, onValue, off, remove } from 'firebase/database';
import { realtimeDb } from '../firebase';
import { useAuth } from './useAuth'; // Import useAuth hook

const useUserListings = () => {
  const { currentUser } = useAuth(); // Get authenticated user
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!currentUser?.uid) {
      setLoading(false);
      setError('User not authenticated');
      return;
    }

    const userListingRef = dbRef(realtimeDb, `listings/${currentUser.uid}`);

    const unsubscribe = onValue(
      userListingRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const listingsArray = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          setListings(listingsArray);
        } else {
          setListings([]);
        }
        setLoading(false);
      },
      (error) => {
        setError(error.message);
        setLoading(false);
      }
    );

    return () => off(userListingRef, 'value', unsubscribe);
  }, [currentUser?.uid]);

  // Function to delete a listing
  const deleteListing = async (listingId) => {
    try {
      await remove(dbRef(realtimeDb, `listings/${currentUser.uid}/${listingId}`));
      setListings((prevListings) => prevListings.filter((listing) => listing.id !== listingId));
    } catch (error) {
      console.error('Error deleting listing:', error);
    }
  };

  return { listings, loading, error, deleteListing };
};

export default useUserListings;


// DeclinedRentals.js

import React, { useEffect, useState } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useStyles from './DeclinedRentalsStyles'; // Custom styles
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { useAuth } from '../../hooks/useAuth';

const DeclinedRentals = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [declinedLeads, setDeclinedLeads] = useState([]);
  const { currentUser } = useAuth();
  const db = getFirestore();

  useEffect(() => {
    const fetchDeclinedLeads = async () => {
      if (!currentUser) return;

      try {
        const declinedQuery = query(
          collection(db, "declinedLeads"),
          where("userId", "==", currentUser.uid)
        );
        const declinedSnapshot = await getDocs(declinedQuery);

        const declinedData = declinedSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        setDeclinedLeads(declinedData);
      } catch (err) {
        console.error("❌ Error fetching declined leads:", err);
      }
    };

    if (currentUser) {
      fetchDeclinedLeads();
    }
  }, [currentUser]);

  return (
    <Box className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        Declined Leads
      </Typography>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              {!isMobile && <TableCell className={classes.tableHeader}>Lead ID</TableCell>}
              <TableCell className={classes.tableHeader}>Reason for Decline</TableCell>
              <TableCell className={classes.tableHeader}>Declined At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {declinedLeads.length > 0 ? (
              declinedLeads.map((lead) => (
                <TableRow key={lead.id} className={classes.tableRow}>
                  {!isMobile && <TableCell>{lead.leadId}</TableCell>}
                  <TableCell>{lead.reason}</TableCell>
                  <TableCell>{new Date(lead.declinedAt).toLocaleString()}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No declined leads found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DeclinedRentals;

// HowWeWorkScreen.js


import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Typography, Grid, Button, Collapse, Container } from '@mui/material';
import useStyles from './HowWeWorkStyles';
import { AiOutlineCar, AiOutlineDollarCircle, AiOutlineClockCircle, AiOutlineBulb } from 'react-icons/ai';

const HowWeWorkScreen = () => {
  const classes = useStyles();
  const [expandedSection, setExpandedSection] = useState(null);

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  return (
    <>
      <Helmet>
        <title>How We Work | Vice City VIP</title>
        <meta name="description" content="Discover how Vice City VIP connects luxury car owners with high-quality renters through AI-powered lead ranking and an exclusive lead marketplace." />
        <link rel="canonical" href="https://vicecityvip.com/how-we-work" />
      </Helmet>

      <Box className={classes.root}>

        {/* Hero Section */}
        <Box className={classes.heroSection}>
          <Typography variant="h3" className={classes.heroText}>
            The Future of Exotic Car Rentals
          </Typography>
          <Typography variant="h5" className={classes.subText}>
            AI-Driven Leads, Exclusive Access, and Smart Monetization
          </Typography>
        </Box>
{/* Video Overview Section */}
<Box className={classes.videoSection}>
  <Container maxWidth="md">
    <Typography variant="h4" className={classes.sectionTitle} align="center" gutterBottom>
      Welcome to Vice City VIP
    </Typography>
    <Typography variant="body1" className={classes.sectionSubText} align="center" gutterBottom>
      Discover how Vice City VIP transforms luxury car rentals with AI-driven lead ranking and exclusive access to high-quality renters. 
      Watch the video to see how you can maximize your vehicle’s earning potential.
    </Typography>

    {/* Video Wrapper */}
    <Box className={classes.videoWrapper} sx={{ position: "relative", paddingTop: "56.25%" }}>
      <iframe
        src="https://www.youtube.com/embed/4hBciUePAbM?si=EsqAis_v3VxWVLeB" // Replace with actual video URL
        title="Vice City VIP Overview"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          borderRadius: "12px",
          boxShadow: "0 8px 20px rgba(0, 0, 0, 0.3)",
        }}
      />
    </Box>

    {/* Call to Action */}
    <Box display="flex" justifyContent="center" mt={4}>
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.ctaButton}
        onClick={() => {
          window.location.href = '/explore-cars'; // Replace with your desired URL
        }}
        sx={{
          background: "linear-gradient(90deg, #ff416c, #ff4b2b)",
          color: "#fff",
          padding: "12px 24px",
          fontSize: "18px",
          fontWeight: "bold",
          borderRadius: "8px",
          boxShadow: "0 4px 12px rgba(255, 65, 108, 0.3)",
          "&:hover": {
            background: "linear-gradient(90deg, #ff4b2b, #ff416c)",
          },
        }}
      >
        Start Listing Your Vehicles
      </Button>
    </Box>
  </Container>
</Box>


        <Container>
          {/* Step-by-Step Process */}
          <Grid container spacing={6} className={classes.contentContainer}>

            {/* Step 1: Owners List Their Vehicles */}
            <Grid item xs={12} md={6}>
              <Box className={classes.section} onClick={() => toggleSection('list-vehicles')}>
                <AiOutlineCar className={classes.icon} />
                <Typography variant="h5" className={classes.sectionTitle}>
                  Step 1: List Your Exotic Car
                </Typography>
                <Collapse in={expandedSection === 'list-vehicles'}>
                  <Typography variant="body1" className={classes.sectionText}>
                    **List your luxury vehicles** on Vice City VIP. Simply provide details about the car, its availability, and pricing, and we'll handle the rest.
                  </Typography>
                  <Typography variant="body2" className={classes.sectionSubText}>
                    Once listed, your vehicle is exposed to verified, high-quality renters seeking luxury rentals. Your car stands out to potential clients with its premium listing.
                  </Typography>
                </Collapse>
              </Box>
            </Grid>

            {/* Step 2: AI-Powered Lead Ranking */}
            <Grid item xs={12} md={6}>
              <Box className={classes.section} onClick={() => toggleSection('lead-ranking')}>
                <AiOutlineBulb className={classes.icon} />
                <Typography variant="h5" className={classes.sectionTitle}>
                  Step 2: AI-Powered Lead Ranking
                </Typography>
                <Collapse in={expandedSection === 'lead-ranking'}>
                  <Typography variant="body1" className={classes.sectionText}>
                    Our platform uses **AI-driven algorithms** to **rank and evaluate leads** based on several factors like renter history, preferences, and trustworthiness. This ensures that you are matched with only the most suitable and high-quality renters.
                  </Typography>
                  <Typography variant="body2" className={classes.sectionSubText}>
                    By analyzing behavior patterns, financial backgrounds, and risk scores, we ensure that you're always engaging with the best potential renters for your vehicle.
                  </Typography>
                </Collapse>
              </Box>
            </Grid>

            {/* Step 3: Exclusive Lead Access */}
            <Grid item xs={12} md={6}>
              <Box className={classes.section} onClick={() => toggleSection('exclusive-leads')}>
                <AiOutlineClockCircle className={classes.icon} />
                <Typography variant="h5" className={classes.sectionTitle}>
                  Step 3: Exclusive Access to Leads
                </Typography>
                <Collapse in={expandedSection === 'exclusive-leads'}>
                  <Typography variant="body1" className={classes.sectionText}>
                    Once your car listing generates **leads** (renters expressing interest), you’ll receive **exclusive access** to them for up to **3 days**. This gives you the **first opportunity** to claim these leads and lock in a rental deal.
                  </Typography>
                  <Typography variant="body2" className={classes.sectionSubText}>
                    **Leads are free** within this period, and there are **no additional costs** for claiming these renters, ensuring that you can secure rentals with minimal risk and effort.
                  </Typography>
                </Collapse>
              </Box>
            </Grid>

            {/* Step 4: Leads Enter the Open Marketplace */}
            <Grid item xs={12} md={6}>
              <Box className={classes.section} onClick={() => toggleSection('lead-marketplace')}>
                <AiOutlineDollarCircle className={classes.icon} />
                <Typography variant="h5" className={classes.sectionTitle}>
                  Step 4: Leads Enter the Open Marketplace
                </Typography>
                <Collapse in={expandedSection === 'lead-marketplace'}>
                  <Typography variant="body1" className={classes.sectionText}>
                    After the exclusive 3-day window, **unclaimed leads** are released into the **open marketplace**. Here, other rental agencies and owners can claim them.
                  </Typography>
                  <Typography variant="body2" className={classes.sectionSubText}>
                    The marketplace ensures that you get access to a **wider pool of renters**, maximizing your chances of securing a profitable rental.
                  </Typography>
                </Collapse>
              </Box>
            </Grid>

          </Grid>

          {/* Call to Action */}
          <Box className={classes.ctaSection}>
            <Button className={classes.ctaButton}>
              Start Listing Your Vehicles Today
            </Button>
          </Box>

        </Container>
      </Box>
    </>
  );
};

export default HowWeWorkScreen;

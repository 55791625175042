// AccountSettings.js



import React, { useState, useEffect } from 'react';
import { 
  Box, Typography, TextField, Button, Switch, FormControlLabel, 
  Grid, CircularProgress 
} from '@mui/material';
import useStyles from './AccountSettingsStyles';
import useEditProfile from '../../hooks/useEditProfile';

const AccountSettings = () => {
  const classes = useStyles();
  const { userData, editProfile, loading, fetching, error, success } = useEditProfile();

  // Form state
  const [formData, setFormData] = useState({
    username: '',
    profileImage: '',
    email: '',
    name: '',
    phone: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    businessName: '',
    businessPhone: '',
    businessCity: '',
    businessState: '',
    businessZip: '',
    businessCountry: '',
    currentPassword: '', // Needed for re-authentication
    newPassword: '',
  });

  const [notificationEnabled, setNotificationEnabled] = useState(true);

  // Load existing user data when available
  useEffect(() => {
    if (userData) {
      setFormData({
        username: userData.username || '',
        profileImage: userData.profileImage || '',
        email: userData.email || '',
        name: userData.name || '',
        phone: userData.phone || '',
        city: userData.city || '',
        state: userData.state || '',
        zip: userData.zip || '',
        country: userData.country || '',
        businessName: userData.businessName || '',
        businessPhone: userData.businessPhone || '',
        businessCity: userData.businessCity || '',
        businessState: userData.businessState || '',
        businessZip: userData.businessZip || '',
        businessCountry: userData.businessCountry || '',
        currentPassword: '',
        newPassword: '',
      });
    }
  }, [userData]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle profile image upload (Base64)
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prev) => ({ ...prev, profileImage: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  // Save settings
  const handleSave = () => {
    editProfile(formData);
  };

  return (
    <Box className={classes.container}>
      <Typography variant="h4" className={classes.neonText}>
        Account Settings
      </Typography>

      {/* Loading Spinner */}
      {fetching && <CircularProgress className={classes.loadingSpinner} />}

      {/* Error & Success Messages */}
      {error && <Typography className={classes.errorText}>{error}</Typography>}
      {success && <Typography className={classes.successText}>{success}</Typography>}

      {!fetching && (
        <Grid container spacing={4} className={classes.form}>
          {/* User Info */}
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.sectionTitle}>User Information</Typography>
          </Grid>
          {[
            { label: "Username", name: "username" },
            { label: "Full Name", name: "name" },
            { label: "Email Address", name: "email" },
            { label: "Phone Number", name: "phone" },
          ].map((field) => (
            <Grid item xs={12} md={6} key={field.name}>
              <TextField
                label={field.label}
                name={field.name}
                variant="outlined"
                fullWidth
                className={classes.textField}
                value={formData[field.name]}
                onChange={handleInputChange}
              />
            </Grid>
          ))}

          {/* Location */}
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.sectionTitle}>Home Address</Typography>
          </Grid>
          {[
            { label: "City", name: "city" },
            { label: "State", name: "state" },
            { label: "ZIP Code", name: "zip" },
            { label: "Country", name: "country" },
          ].map((field) => (
            <Grid item xs={12} md={6} key={field.name}>
              <TextField
                label={field.label}
                name={field.name}
                variant="outlined"
                fullWidth
                className={classes.textField}
                value={formData[field.name]}
                onChange={handleInputChange}
              />
            </Grid>
          ))}

          {/* Business Address */}
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.sectionTitle}>Business Address</Typography>
          </Grid>
          {[
            { label: "Business Name", name: "businessName" },
            { label: "Business Phone", name: "businessPhone" },
            { label: "City", name: "businessCity" },
            { label: "State", name: "businessState" },
            { label: "ZIP Code", name: "businessZip" },
            { label: "Country", name: "businessCountry" },
          ].map((field) => (
            <Grid item xs={12} md={6} key={field.name}>
              <TextField
                label={field.label}
                name={field.name}
                variant="outlined"
                fullWidth
                className={classes.textField}
                value={formData[field.name]}
                onChange={handleInputChange}
              />
            </Grid>
          ))}

          {/* Password Update */}
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.sectionTitle}>Change Password</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Current Password (Required for Email/Password Change)"
              name="currentPassword"
              type="password"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={formData.currentPassword}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="New Password"
              name="newPassword"
              type="password"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={formData.newPassword}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
      )}

      {/* Profile Image Upload */}
      <Box className={classes.uploadContainer}>
        <input type="file" accept="image/*" onChange={handleImageUpload} />
        {formData.profileImage && (
          <Box className={classes.imagePreviewContainer}>
            <img src={formData.profileImage} alt="Profile Preview" className={classes.profileThumbnail} />
          </Box>
        )}
      </Box>

      {/* Notification Toggle */}
      <Box className={classes.switches}>
        <FormControlLabel
          control={
            <Switch 
              checked={notificationEnabled} 
              onChange={() => setNotificationEnabled(!notificationEnabled)} 
            />
          }
          label="Enable Notifications"
          className={classes.switchLabel}
        />
      </Box>

      <Button 
        variant="contained" 
        className={classes.saveButton} 
        onClick={handleSave} 
        disabled={loading}
      >
        {loading ? 'Saving...' : 'Save Settings'}
      </Button>
    </Box>
  );
};

export default AccountSettings;



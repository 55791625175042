import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#000",
    color: "#fff",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: "80px 20px",
    animation: "$fadeIn 1.5s ease-in-out",
  },
  container: {
    maxWidth: "900px",
  },
  headerBox: {
    marginBottom: "50px",
  },
  neonText: {
    color: "#f72585",
    fontSize: "3.8rem",
    fontWeight: 800,
    textShadow: "0 0 30px #f72585, 0 0 50px #23d5ab",
  },
  highlight: {
    color: "#23d5ab",
    textShadow: "0 0 40px #23d5ab",
  },
  subText: {
    fontSize: "1.6rem",
    marginTop: "10px",
    color: "#23d5ab",
    textShadow: "0 0 20px #23d5ab",
  },
  contactContainer: {
    marginTop: "30px",
    padding: "0 20px",
  },
  infoBox: {
    padding: "40px",
    background: "rgba(17, 17, 17, 0.95)",
    borderRadius: "20px",
    boxShadow: "0 0 50px rgba(35, 213, 171, 0.5), 0 0 80px rgba(247, 37, 133, 0.4)",
  },
  infoTitle: {
    color: "#f72585",
    fontSize: "2rem",
    textShadow: "0 0 20px #f72585",
    marginBottom: "20px",
  },
  infoItem: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    fontSize: "1.4rem",
    margin: "10px 0",
  },
  icon: {
    fontSize: "2rem",
    color: "#f72585",
  },
  formBox: {
    padding: "40px",
    background: "linear-gradient(135deg, rgba(17, 17, 17, 0.95), rgba(0, 0, 0, 0.98))",
    borderRadius: "20px",
    boxShadow: "0 0 50px rgba(35, 213, 171, 0.5), 0 0 80px rgba(247, 37, 133, 0.4)",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      color: "#fff",
      "& fieldset": {
        borderColor: "#23d5ab",
      },
      "&:hover fieldset": {
        borderColor: "#f72585",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#f72585",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#23d5ab",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#f72585",
    },
    "& .MuiInputBase-input": {
      color: "#fff",
    },
  },
  submitButton: {
    marginTop: "20px",
    padding: "15px 50px",
    fontSize: "1.5rem",
    color: "#fff",
    backgroundColor: "#f72585",
    borderRadius: "50px",
    textShadow: "0 0 10px #f72585, 0 0 20px #23d5ab",
    "&:hover": {
      backgroundColor: "#23d5ab",
      boxShadow: "0 0 50px #23d5ab, 0 0 80px #f72585",
    },
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
  },
  successMessage: {
    color: "#23d5ab",
    fontSize: "1.6rem",
    textShadow: "0 0 20px #23d5ab",
  },
  "@keyframes fadeIn": {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
}));

export default useStyles;

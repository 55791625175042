// AboutUsScreen.js


import React from 'react';
import { Box, Typography, Grid, Button, Container, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useStyles from './AboutUsStyles';

const AboutUsScreen = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleNavigateToHowWeWork = () => {
    navigate('/how-we-work');
  };

  return (
    <Box className={classes.root}>
      {/* Hero Section */}
      <Box className={classes.heroSection}>
        <Typography variant="h3" className={classes.heroText}>
          Redefining Luxury Rentals with AI
        </Typography>
        <Typography variant="h5" className={classes.subText}>
          Experience the future of exotic car rentals with Vice City VIP
        </Typography>
      </Box>

      <Container>
        <Grid container spacing={6} className={classes.contentContainer}>
          {/* Who We Are */}
          <Grid item xs={12} md={6}>
            <Box className={classes.section}>
              <Typography variant="h5" className={classes.sectionTitle}>
                Who We Are
              </Typography>
              <Typography variant="body1" className={classes.sectionText}>
                Vice City VIP is the **premier exotic car rental marketplace**,
                seamlessly linking vehicle owners and rental agencies with elite
                clientele. We are more than just a platform—we are a **curated
                luxury experience** powered by **AI-driven security and
                decentralized data intelligence**.
              </Typography>
            </Box>
          </Grid>

          {/* How We Operate */}
          <Grid item xs={12} md={6}>
            <Box className={classes.section}>
              <Typography variant="h5" className={classes.sectionTitle}>
                How We Operate
              </Typography>
              <Typography variant="body1" className={classes.sectionText}>
                Our cutting-edge AI evaluates **renter reliability, risk
                factors, and lead quality**, ensuring that only **verified,
                high-quality clients** access your luxury vehicles. Owners
                benefit from **secure, pre-screened leads**, while renters gain
                access to the **world’s most exclusive exotic cars**—all within
                a seamless, AI-enhanced process.
              </Typography>
            </Box>
          </Grid>

          {/* AI-Driven Security with Hive Forensics */}
          <Grid item xs={12} md={6}>
            <Box className={classes.section} textAlign="center">
              <img
                src="/logo.webp"
                alt="Hive Forensics"
                style={{ width: "140px", height: "auto", marginBottom: "15px" }}
              />
              <Typography variant="h5" className={classes.sectionTitle}>
                AI-Powered Security with Hive Forensics
              </Typography>
              <Typography variant="body1" className={classes.sectionText}>
                We have partnered with{" "}
                <Link
                  href="https://hiveforensics.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                >
                  Hive Forensics
                </Link>
                , a leader in **fraud detection, identity verification, and risk
                analysis**. Their advanced AI technology allows us to **analyze
                rental leads in real time**, assessing behavioral patterns,
                financial credibility, and other key risk indicators. This
                insight helps **optimize lead quality and dynamically adjust
                lead costs**, ensuring that vehicle owners have access to
                **higher-value rental opportunities** while making informed
                decisions.
              </Typography>
            </Box>
          </Grid>

          {/* Decentralized AI Embeddings with Vektaris */}
          <Grid item xs={12} md={6}>
            <Box className={classes.section} textAlign="center">
              <img
                src="/logo_vektaris.png"
                alt="Vektaris"
                style={{ width: "300px", height: "auto", marginBottom: "15px" }}
              />
              <Typography variant="h5" className={classes.sectionTitle}>
                Decentralized AI Embeddings with Vektaris
              </Typography>
              <Typography variant="body1" className={classes.sectionText}>
                We leverage{" "}
                <Link
                  href="https://vektaris.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                >
                  Vektaris
                </Link>
                , a **decentralized vector database** built for high-performance
                AI models. This ensures **faster, more secure retrieval of
                AI-driven insights**, allowing us to make **real-time risk
                assessments** while maintaining **full data privacy and
                transparency**.
              </Typography>
            </Box>
          </Grid>

          {/* Redefining Luxury & Security */}
          <Grid item xs={12} md={6}>
            <Box className={classes.section}>
              <Typography variant="h5" className={classes.sectionTitle}>
                Redefining Luxury with Security
              </Typography>
              <Typography variant="body1" className={classes.sectionText}>
                **Exclusivity meets security**—our AI filters out high-risk
                individuals, giving you **complete peace of mind** when renting
                out your vehicles. With Vice City VIP, you’re not just listing
                your car—you’re **partnering with the future of AI-driven luxury
                rentals**.
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Call to Action */}
        <Box className={classes.ctaSection}>
          <Button
            className={classes.ctaButton}
            onClick={handleNavigateToHowWeWork}
          >
            Discover How We Work
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default AboutUsScreen;

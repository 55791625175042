// CustomDrawer.js



import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Toolbar
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import { useMediaQuery } from '@mui/material';

// Icons
import DashboardIcon from '@mui/icons-material/Dashboard';
import PendingIcon from '@mui/icons-material/HourglassEmpty';
import CancelIcon from '@mui/icons-material/Cancel';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import SettingsIcon from '@mui/icons-material/Settings';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'; // <-- New icon import

const drawerWidth = 280;

const DrawerStyled = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    background: 'rgba(17, 17, 17, 0.85)', // Dark glass effect
    backdropFilter: 'blur(10px)', // Frosted blur effect
    borderRight: '2px solid rgba(247, 37, 133, 0.4)', // Soft neon border
    color: '#fff',
    boxShadow: '0 0 20px rgba(247, 37, 133, 0.8)', // Stronger neon glow
    transition: 'transform 0.4s ease-in-out, box-shadow 0.3s ease-in-out',
  },
}));

const CustomDrawer = ({
  mobileOpen,
  drawerOpen,
  handleDrawerToggle,
  selectedMenuItem,
  setSelectedMenuItem,
  userType,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const menuItems = [
    { text: 'Dashboard', value: 'overview', icon: <DashboardIcon /> },
    ...(userType === 'business'
      ? [
          { text: 'Pending Leads', value: 'pending', icon: <PendingIcon /> },
          {
            text: 'My Vehicle Leads',
            value: 'myLeads',
            icon: <DirectionsCarIcon />,
          },
          { text: 'Declined Leads', value: 'declined', icon: <CancelIcon /> },
          { text: 'Purchased Leads', value: 'history', icon: <AssignmentTurnedInIcon /> },
        ]
      : []),
    { text: 'User Profile', value: 'settings', icon: <SettingsIcon /> },
  ];

  const handleItemClick = (value) => {
    setSelectedMenuItem(value);
    if (isMobile) handleDrawerToggle(); // Close drawer on mobile after selection
  };

  const drawerContent = (
    <List>
      {menuItems.map((item) => (
        <ListItem
          button
          key={item.value}
          onClick={() => handleItemClick(item.value)}
          selected={selectedMenuItem === item.value}
          sx={{
            color: selectedMenuItem === item.value ? '#f72585' : '#23d5ab',
            textShadow: '0 0 10px #23d5ab',
            cursor: 'pointer',
            borderRadius: '10px',
            transition: 'all 0.3s ease-in-out',
            '&:hover': {
              background: 'rgba(247, 37, 133, 0.15)',
              color: '#f72585',
              textShadow: '0 0 15px #f72585',
              transform: 'scale(1.05)',
            },
          }}
        >
          <ListItemIcon
            sx={{
              color: selectedMenuItem === item.value ? '#f72585' : '#23d5ab',
              minWidth: 50,
              transition: 'all 0.3s ease',
            }}
          >
            {React.cloneElement(item.icon, { sx: { fontSize: 26 } })}
          </ListItemIcon>
          <ListItemText
            primary={item.text}
            sx={{
              fontSize: '1rem',
              fontWeight: '500',
              textTransform: 'uppercase',
            }}
          />
        </ListItem>
      ))}
    </List>
  );

  return (
    <>
      {isMobile && (
        <IconButton
          onClick={handleDrawerToggle}
          sx={{
            color: '#f72585',
            position: 'absolute',
            top: 15,
            left: 15,
            zIndex: 2000,
            background: 'rgba(0, 0, 0, 0.6)',
            padding: '8px',
            borderRadius: '10px',
            transition: 'all 0.3s ease',
            '&:hover': {
              background: 'rgba(247, 37, 133, 0.3)',
              transform: 'scale(1.1)',
            },
          }}
        >
          <MenuIcon />
        </IconButton>
      )}

      {/* Mobile Drawer */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            background: 'rgba(17, 17, 17, 0.85)',
            backdropFilter: 'blur(10px)',
            boxShadow: '0 0 20px #f72585',
          },
        }}
      >
        <IconButton
          onClick={handleDrawerToggle}
          sx={{
            color: '#f72585',
            position: 'absolute',
            top: 15,
            right: 15,
            background: 'rgba(0, 0, 0, 0.6)',
            padding: '8px',
            borderRadius: '10px',
            transition: 'all 0.3s ease',
            '&:hover': {
              background: 'rgba(247, 37, 133, 0.3)',
              transform: 'scale(1.1)',
            },
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
        {drawerContent}
      </Drawer>

      {/* Desktop Drawer */}
      <DrawerStyled variant="persistent" open={drawerOpen}>
        <Toolbar />
        {drawerContent}
      </DrawerStyled>
    </>
  );
};

export default CustomDrawer;


// import React from 'react';
// import { Drawer, List, ListItem, ListItemText, ListItemIcon, IconButton, Toolbar } from '@mui/material';
// import { styled, useTheme } from '@mui/material/styles';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import MenuIcon from '@mui/icons-material/Menu';
// import { useMediaQuery } from '@mui/material';

// // Icons
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import PendingIcon from '@mui/icons-material/HourglassEmpty';
// import CancelIcon from '@mui/icons-material/Cancel';
// import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
// import SettingsIcon from '@mui/icons-material/Settings';

// const drawerWidth = 280;

// const DrawerStyled = styled(Drawer)(({ theme }) => ({
//   width: drawerWidth,
//   flexShrink: 0,
//   '& .MuiDrawer-paper': {
//     width: drawerWidth,
//     background: 'rgba(17, 17, 17, 0.85)', // Dark glass effect
//     backdropFilter: 'blur(10px)', // Frosted blur effect
//     borderRight: '2px solid rgba(247, 37, 133, 0.4)', // Soft neon border
//     color: '#fff',
//     boxShadow: '0 0 20px rgba(247, 37, 133, 0.8)', // Stronger neon glow
//     transition: 'transform 0.4s ease-in-out, box-shadow 0.3s ease-in-out',
//   },
// }));

// const CustomDrawer = ({
//   mobileOpen,
//   drawerOpen,
//   handleDrawerToggle,
//   selectedMenuItem,
//   setSelectedMenuItem,
//   userType,
// }) => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//   const menuItems = [
//     { text: 'Dashboard', value: 'overview', icon: <DashboardIcon /> },
//     ...(userType === 'business'
//       ? [
//           { text: 'Pending Leads', value: 'pending', icon: <PendingIcon /> },
//           { text: 'Declined Leads', value: 'declined', icon: <CancelIcon /> },
//           { text: 'Approved Leads', value: 'history', icon: <AssignmentTurnedInIcon /> },
//         ]
//       : []),
//     { text: 'User Profile', value: 'settings', icon: <SettingsIcon /> },
//   ];

//   const handleItemClick = (value) => {
//     setSelectedMenuItem(value);
//     if (isMobile) handleDrawerToggle(); // Close drawer on mobile after selection
//   };

//   const drawerContent = (
//     <List>
//       {menuItems.map((item) => (
//         <ListItem
//           button
//           key={item.value}
//           onClick={() => handleItemClick(item.value)}
//           selected={selectedMenuItem === item.value}
//           sx={{
//             color: selectedMenuItem === item.value ? '#f72585' : '#23d5ab',
//             textShadow: '0 0 10px #23d5ab',
//             cursor: 'pointer',
//             borderRadius: '10px',
//             transition: 'all 0.3s ease-in-out',
//             '&:hover': {
//               background: 'rgba(247, 37, 133, 0.15)', // Subtle hover effect
//               color: '#f72585',
//               textShadow: '0 0 15px #f72585',
//               transform: 'scale(1.05)', // Small hover scale for a premium feel
//             },
//           }}
//         >
//           <ListItemIcon
//             sx={{
//               color: selectedMenuItem === item.value ? '#f72585' : '#23d5ab',
//               minWidth: 50,
//               transition: 'all 0.3s ease',
//             }}
//           >
//             {React.cloneElement(item.icon, { sx: { fontSize: 26 } })}
//           </ListItemIcon>
//           <ListItemText
//             primary={item.text}
//             sx={{ fontSize: '1rem', fontWeight: '500', textTransform: 'uppercase' }}
//           />
//         </ListItem>
//       ))}
//     </List>
//   );

//   return (
//     <>
//       {isMobile && (
//         <IconButton
//           onClick={handleDrawerToggle}
//           sx={{
//             color: '#f72585',
//             position: 'absolute',
//             top: 15,
//             left: 15,
//             zIndex: 2000,
//             background: 'rgba(0, 0, 0, 0.6)',
//             padding: '8px',
//             borderRadius: '10px',
//             transition: 'all 0.3s ease',
//             '&:hover': {
//               background: 'rgba(247, 37, 133, 0.3)',
//               transform: 'scale(1.1)',
//             },
//           }}
//         >
//           <MenuIcon />
//         </IconButton>
//       )}

//       {/* Mobile Drawer */}
//       <Drawer
//         variant="temporary"
//         open={mobileOpen}
//         onClose={handleDrawerToggle}
//         ModalProps={{ keepMounted: true }}
//         sx={{
//           display: { xs: 'block', sm: 'none' },
//           '& .MuiDrawer-paper': {
//             boxSizing: 'border-box',
//             width: drawerWidth,
//             background: 'rgba(17, 17, 17, 0.85)',
//             backdropFilter: 'blur(10px)',
//             boxShadow: '0 0 20px #f72585',
//           },
//         }}
//       >
//         <IconButton
//           onClick={handleDrawerToggle}
//           sx={{
//             color: '#f72585',
//             position: 'absolute',
//             top: 15,
//             right: 15,
//             background: 'rgba(0, 0, 0, 0.6)',
//             padding: '8px',
//             borderRadius: '10px',
//             transition: 'all 0.3s ease',
//             '&:hover': {
//               background: 'rgba(247, 37, 133, 0.3)',
//               transform: 'scale(1.1)',
//             },
//           }}
//         >
//           <ChevronLeftIcon />
//         </IconButton>
//         {drawerContent}
//       </Drawer>

//       {/* Desktop Drawer */}
//       <DrawerStyled variant="persistent" open={drawerOpen}>
//         <Toolbar />
//         {drawerContent}
//       </DrawerStyled>
//     </>
//   );
// };

// export default CustomDrawer;

// useAuth.js




import { useState, useEffect, useContext, createContext } from 'react';
import { auth, db } from '../firebase';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore'; // Import getDoc for fetching Firestore data

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState(null);

  // Fetch additional user data (including userType) from Firestore after signup/login
  const fetchUserData = async (user) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        setCurrentUser({ uid: user.uid, email: user.email, ...userDoc.data() }); // Set user data with Firestore fields
      }
    } catch (error) {
      console.error('Error fetching user data from Firestore:', error);
    }
  };

  const signup = async (email, password, userData) => {
    setLoading(true);
    setAuthError(null);
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Store additional user info in Firestore
      await setDoc(doc(db, 'users', user.uid), {
        email: user.email,
        userType: userData.userType,
        name: userData.name,
        phone: userData.phone,
        ...(userData.address && { address: userData.address }),
        ...(userData.businessAddress && { businessAddress: userData.businessAddress }),
      });

      // Fetch and set complete user data
      await fetchUserData(user);
    } catch (error) {
      console.error('Error during signup:', error);
      setAuthError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const login = async (email, password) => {
    setLoading(true);
    setAuthError(null);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Fetch and set complete user data
      await fetchUserData(user);
    } catch (error) {
      console.error('Error during login:', error);
      setAuthError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    setLoading(true);
    try {
      await signOut(auth);
      setCurrentUser(null);
    } catch (error) {
      console.error('Error during logout:', error);
    } finally {
      setLoading(false);
    }
  };

  // Automatically fetch user data when auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await fetchUserData(user); // Fetch user data after auth state change
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signup,
    login,
    logout,
    loading,
    authError,
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
};

// CarDetailsModal.js


import React, { useState } from 'react';
import { Modal, Box, Typography, Button, Backdrop, Fade } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useStyles from './CarDetailsModalStyles';
import CalendarModal from './CalendarModal';

const CarDetailsModal = ({ open, handleClose, selectedCar }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [calendarOpen, setCalendarOpen] = useState(false);

  const defaultImage = `${process.env.PUBLIC_URL}/logo.png`;

  const handleCalendarOpen = () => setCalendarOpen(true);
  const handleCalendarClose = () => setCalendarOpen(false);

  const navigateToListing = (e) => {
    e.stopPropagation();
    if (!e.target.classList.contains('rentNowButton')) {
      navigate(`/vehicle/${selectedCar.id}`, { state: { car: selectedCar } });
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <Box className={classes.modalContainer} onClick={navigateToListing}>
            <Typography variant="h4" className={classes.neonText}>
              {selectedCar?.make || 'Car'} {selectedCar?.model || 'Details'}
            </Typography>

            <img
              src={selectedCar?.imageUrls?.[0] || defaultImage}
              alt={`${selectedCar?.make || 'Car'} ${selectedCar?.model || 'Image'}`}
              className={classes.modalCarImage}
            />

            <Box className={classes.modalContent}>
              <Typography className={classes.modalDescription}>
                {selectedCar?.description || 'Description not available'}
              </Typography>
              <Typography className={classes.modalPrice}>
                {selectedCar?.pricePerDay ? `$${selectedCar?.pricePerDay} / day` : 'Price not available'}
              </Typography>

              <Box className={classes.additionalInfo}>
                <Typography variant="body1" className={classes.neonText}>
                  Delivery Offered: {selectedCar?.deliveryOffered ? 'Yes' : 'No'}
                </Typography>
                <Typography variant="body1" className={classes.neonText}>
                  Location: {selectedCar?.city || 'Unknown city'}, {selectedCar?.state || 'Unknown state'}
                </Typography>
              </Box>
            </Box>

            <Box className={classes.buttonContainer}>
              <Button
                className={classes.rentNowButton}
                size="large"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCalendarOpen();
                }}
              >
                Rent Now
              </Button>

              <Button
                className={classes.buttonNeon}
                size="large"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClose();
                }}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>

      <CalendarModal open={calendarOpen} handleClose={handleCalendarClose} selectedCar={selectedCar} />
    </>
  );
};

export default CarDetailsModal;


// HomePageStyles.js


import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  // Overall page container
  root: {
    backgroundColor: '#000', 
    minHeight: '100vh',
    color: '#fff',
  },

  /*
   * HEADER / FOOTER
   */
  footer: {
    backgroundColor: '#111',
    padding: '20px 0',
    textAlign: 'center',
    color: '#23d5ab',
    borderTop: '2px solid #f72585',
  },

  /*
   * HERO SECTION
   */
  hero: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    position: 'relative',
    minHeight: '100vh', // Ensures full-screen coverage on all devices
    width: '100%',
    zIndex: 1,
    overflow: 'hidden',
    padding: '0 5%', // Adds padding to avoid text cutoff on smaller screens
  },

  videoBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: -1,
  },

  videoOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: `
      linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.85) 0%,   /* Darker at the top */
        rgba(0, 0, 0, 0.65) 40%,  /* Smooth transition */
        rgba(0, 0, 0, 0.85) 70%,  /* Begins deepening */
        rgba(0, 0, 0, 0.98) 90%,  /* Almost black */
        rgba(0, 0, 0, 1) 100%     /* Fully black */
      )
    `,
    zIndex: 0,
  },

  content: {
    position: 'relative',
    zIndex: 3,
    color: '#fff',
    textAlign: 'center',
    maxWidth: '800px', // Keeps content neatly centered on all screens
    padding: '20px',
  },

  downArrowContainer: {
    position: 'absolute',
    bottom: '5%', // Adjusted for better responsiveness
    zIndex: 4,
    cursor: 'pointer',
  },

  bounceArrow: {
    fontSize: '2.5rem',
    color: '#f72585',
    animation: '$bounce 1.5s infinite',
  },

  '@keyframes bounce': {
    '0%, 100%': { transform: 'translateY(0)' },
    '50%': { transform: 'translateY(-10px)' },
  },

  /*
   * RESPONSIVE STYLING
   */
  '@media (max-width: 1024px)': {
    hero: {
      minHeight: '85vh', // Adjusted for tablets
      padding: '0 3%',
    },
    content: {
      fontSize: '1.2rem',
      maxWidth: '650px',
    },
  },

  '@media (max-width: 768px)': {
    hero: {
      minHeight: '80vh', // Adjusted for smaller tablets & large phones
      padding: '0 3%',
    },
    content: {
      fontSize: '1rem',
      maxWidth: '90%',
    },
    bounceArrow: {
      fontSize: '2rem',
    },
  },

  '@media (max-width: 480px)': {
    hero: {
      minHeight: '75vh', // Adjusted for mobile
      padding: '0 5%',
    },
    content: {
      fontSize: '0.9rem',
      maxWidth: '100%',
    },
    bounceArrow: {
      fontSize: '1.8rem',
    },
  },
  /*
   * "Why Choose Us" SECTION
   */
  whyChooseSection: {
    backgroundColor: '#111',
    padding: '60px 0',
    textAlign: 'center',
  },
  sectionTitle: {
    marginBottom: '40px',
    textTransform: 'uppercase',
    fontSize: '2.5rem',
    letterSpacing: '2px',
  },
  featureItem: {
    backgroundColor: '#000',
    padding: '30px',
    borderRadius: '12px',
    border: '2px solid #f72585',
    boxShadow: '0 0 20px #23d5ab',
    transition: 'transform 0.3s ease',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 0 40px #f72585, 0 0 60px #23d5ab',
    },
  },
  featureIcon: {
    width: '120px', // Increased size
    height: '120px', // Ensures uniformity
    borderRadius: '50%', // Makes images perfectly round
    objectFit: 'cover', // Ensures images look clean within the rounded shape
    marginBottom: '15px',
    boxShadow: '0 0 15px #23d5ab', // Adds neon glow to images
  },
  featureTitle: {
    color: '#f72585',
    textShadow: '0 0 10px #f72585',
    marginBottom: '10px',
  },
  featureDescription: {
    color: '#23d5ab',
    lineHeight: 1.6,
    fontSize: '1rem',
  },

  /*
   * ANIMATIONS
   */
  '@keyframes gradientShimmer': {
    '0%': {
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    '50%': {
      backgroundSize: '105%',
    },
    '100%': {
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
  },

  /*
   * TYPING EFFECT
   */
  typingEffect: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    borderRight: '3px solid #f72585',
    width: '34ch', 
    animation: '$typing 3s steps(34), $blink 0.75s step-end infinite alternate',
  },
  '@keyframes typing': {
    from: { width: '0' },
    to: { width: '34ch' },
  },
  '@keyframes blink': {
    '50%': { borderColor: 'transparent' },
  },

  /*
   * GLOW / PULSE
   */
  '@keyframes glow': {
    '0%': {
      textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab, 0 0 30px #23d5ab',
    },
    '100%': {
      textShadow: '0 0 20px #f72585, 0 0 40px #23d5ab, 0 0 60px #23d5ab',
    },
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
      boxShadow: '0 0 10px #23d5ab, 0 0 20px #23d5ab',
    },
    '50%': {
      transform: 'scale(1.05)',
      boxShadow: '0 0 20px #f72585, 0 0 40px #f72585',
    },
    '100%': {
      transform: 'scale(1)',
      boxShadow: '0 0 10px #23d5ab, 0 0 20px #23d5ab',
    },
  },

  /*
   * HEADINGS
   */
  neonText: {
    color: '#f72585',
    textShadow: '0 0 15px #f72585, 0 0 25px #23d5ab',
    fontWeight: 800,
    fontSize: '3rem',
  },

  /*
   * FLICKER FOR "Featured Cars"
   */
  '@keyframes neonFlicker': {
    '0%': { opacity: 1 },
    '5%': { opacity: 0.8 },
    '10%': { opacity: 1 },
    '15%': { opacity: 0.9 },
    '20%': { opacity: 0.75 },
    '25%': { opacity: 1 },
    '100%': { opacity: 1 },
  },
  flickerText: {
    animation: '$neonFlicker 3s infinite',
  },

  /*
   * FEATURED CARS SECTION
   */
  featuredCarsSection: {
    position: 'relative',
    padding: '50px 0',
    background: 'radial-gradient(circle, rgba(23,213,171,0.08) 0%, rgba(0,0,0,1) 70%)',
  },

  featuredCarCard: {
    backgroundColor: 'rgba(17, 17, 17, 0.7)',
    border: '2px solid #23d5ab',
    padding: '20px',
    textAlign: 'center',
    color: '#fff',
    boxShadow: '0 0 40px #23d5ab, 0 0 60px #f72585, inset 0 0 10px rgba(255, 255, 255, 0.2)',
    transition: 'transform 0.4s ease, box-shadow 0.4s ease',
    backdropFilter: 'blur(10px)',
    borderRadius: '15px',
    overflow: 'hidden',
    '&:hover': {
      transform: 'scale(1.1) rotateY(4deg)',
      boxShadow: '0 0 60px #23d5ab, 0 0 90px #f72585, inset 0 0 20px rgba(255, 255, 255, 0.3)',
      backgroundColor: '#556',
    },
  },
  carImage: {
    width: '100%',
    height: '220px',
    objectFit: 'cover',
    borderRadius: '12px',
    boxShadow: '0 0 20px #23d5ab, 0 0 30px #f72585',
    transition: 'transform 0.4s ease, boxShadow 0.4s ease',
    '&:hover': {
      transform: 'scale(1.08)',
      boxShadow: '0 0 40px #23d5ab, 0 0 60px #f72585',
    },
  },

  /*
   * PRICING / BUTTONS / LOADING
   */
  priceText: {
    color: '#23d5ab',
    fontSize: '1.5rem',
    fontWeight: 700,
    marginBottom: '15px',
    textShadow: '0 0 10px #23d5ab, 0 0 20px #f72585',
  },
  buttonNeon: {
    color: '#23d5ab',
    border: '2px solid #23d5ab',
    textShadow: '0 0 10px #23d5ab, 0 0 20px #f72585',
    '&:hover': {
      backgroundColor: '#f72585',
      borderColor: '#f72585',
      color: '#fff',
      boxShadow: '0 0 40px #f72585, 0 0 80px #23d5ab',
    },
    transition: 'transform 0.4s ease, box-shadow 0.4s ease',
    marginTop: '15px',
  },
  pulsatingButton: {
    animation: '$pulse 2s infinite',
  },
  spinner: {
    color: '#23d5ab',
    width: '80px !important',
    height: '80px !important',
    boxShadow: '0 0 30px #23d5ab, 0 0 50px #f72585',
  },
  loadingText: {
    color: '#f72585',
    textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab',
    marginTop: '10px',
    fontWeight: 700,
  },

  /*
   * FADE-IN ANIMATION FOR CAR CARDS
   */
  '@keyframes fadeInUp': {
    from: {
      opacity: 0,
      transform: 'translateY(30px)',
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  fadeInUp: {
    animation: '$fadeInUp 0.8s ease forwards',
  },
}));

export default useStyles;

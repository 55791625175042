// Contact/ContactUsScreen.jsx

import React, { useState } from "react";
import { Box, TextField, Button, Typography, Grid, Container } from "@mui/material";
import useStyles from "./ContactUsStyles";
import { AiOutlineMail, AiOutlineEnvironment } from "react-icons/ai";

const ContactUsScreen = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState({ name: "", email: "", message: "" });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formAction = "https://formspree.io/f/xvgzvbjd"; // Replace with your Formspree ID

    const response = await fetch(formAction, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      setSubmitted(true);
      setFormData({ name: "", email: "", message: "" });
    }
  };

  return (
    <Box className={classes.root}>
      <Container className={classes.container}>
        {/* Headline */}
        <Box className={classes.headerBox}>
          <Typography variant="h3" className={classes.neonText}>
            Get in Touch with <span className={classes.highlight}>Vice City VIP</span>
          </Typography>
          <Typography variant="h6" className={classes.subText}>
            Let’s connect. Whether you have a question, need assistance, or want to partner with us, we’re here for you.
          </Typography>
        </Box>

        <Grid container spacing={4} className={classes.contactContainer}>
          {/* Contact Information */}
          <Grid item xs={12} md={5}>
            <Box className={classes.infoBox}>
              <Typography variant="h5" className={classes.infoTitle}>
                Contact Information
              </Typography>
              <Box className={classes.infoItem}>
                <AiOutlineMail className={classes.icon} />
                <Typography variant="body1">support@vicecityvip.com</Typography>
              </Box>
              <Box className={classes.infoItem}>
                <AiOutlineEnvironment className={classes.icon} />
                <Typography variant="body1">Miami, FL, USA</Typography>
              </Box>
            </Box>
          </Grid>

          {/* Contact Form */}
          <Grid item xs={12} md={7}>
            <Box className={classes.formBox}>
              {submitted ? (
                <Typography variant="h5" className={classes.successMessage}>
                  ✅ Message sent! We’ll get back to you shortly.
                </Typography>
              ) : (
                <form onSubmit={handleSubmit} className={classes.form}>
                  <TextField
                    label="Your Name"
                    name="name"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                  <TextField
                    label="Your Email"
                    name="email"
                    type="email"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <TextField
                    label="Your Message"
                    name="message"
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                  <Button type="submit" className={classes.submitButton}>
                    Send Message
                  </Button>
                </form>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ContactUsScreen;

// Dashboard.js



import React, { useState, useEffect } from 'react';
import { CssBaseline, Toolbar, CircularProgress, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomDrawer from './CustomDrawer';
import DashboardOverview from './DashboardOverview';
import PendingRentals from './PendingRentals';
import DeclinedRentals from './DeclinedRentals';
import RentalHistory from './RentalHistory';
import AccountSettings from './AccountSettings';
import CreateListing from './CreateListing';
import MyVehicleLeads from './MyVehicleLeads'; // <-- Import your new leads page
import { useAuth } from '../../hooks/useAuth';
import NavBar from './NavBar';
import { Content, Root } from './DashboardStyles';
import { useCart } from '../../context/CartContext';

const Dashboard = () => {
  const { currentUser } = useAuth();
  const { cartItems } = useCart();
  const navigate = useNavigate();
  const [selectedMenuItem, setSelectedMenuItem] = useState('overview');
  const [mobileOpen, setMobileOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [loading, setLoading] = useState(true);

  // Ensure authentication is loaded before rendering the dashboard
  useEffect(() => {
    if (!currentUser) {
      setTimeout(() => {
        navigate('/');
      }, 500); // Delay for smooth redirection
    } else {
      setLoading(false);
    }
  }, [currentUser, navigate]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const renderContent = () => {
    switch (selectedMenuItem) {
      case 'pending':
        return <PendingRentals />;
      case 'myLeads':
        return <MyVehicleLeads />;
      case 'declined':
        return <DeclinedRentals />;
      case 'history':
        return currentUser?.userType === 'business'
          ? <RentalHistory />
          : <DashboardOverview />;
      case 'settings':
        return <AccountSettings />;
      case 'create-listing':
        return currentUser?.userType === 'business'
          ? <CreateListing />
          : <DashboardOverview />;
      default:
        return <DashboardOverview />;
    }
  };

  // Show a loading spinner while waiting for authentication
  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Root>
      <CssBaseline />

      <NavBar
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
        cartItems={cartItems}
        currentUser={currentUser}
        setSelectedMenuItem={setSelectedMenuItem}
      />

      <CustomDrawer
        mobileOpen={mobileOpen}
        drawerOpen={drawerOpen}
        handleDrawerToggle={handleDrawerToggle}
        selectedMenuItem={selectedMenuItem}
        setSelectedMenuItem={setSelectedMenuItem}
        userType={currentUser?.userType}
      />

      <Content>
        <Toolbar />
        {renderContent()}
      </Content>
    </Root>
  );
};

export default Dashboard;

// import React, { useState, useEffect } from 'react';
// import { CssBaseline, Toolbar, CircularProgress, Box } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import CustomDrawer from './CustomDrawer';
// import DashboardOverview from './DashboardOverview';
// import PendingRentals from './PendingRentals';
// import DeclinedRentals from './DeclinedRentals';
// import RentalHistory from './RentalHistory';
// import AccountSettings from './AccountSettings';
// import CreateListing from './CreateListing';
// import { useAuth } from '../../hooks/useAuth';
// import NavBar from './NavBar';
// import { Content, Root } from './DashboardStyles';
// import { useCart } from '../../context/CartContext';

// const Dashboard = () => {
//   const { currentUser } = useAuth();
//   const { cartItems } = useCart();
//   const navigate = useNavigate();
//   const [selectedMenuItem, setSelectedMenuItem] = useState('overview');
//   const [mobileOpen, setMobileOpen] = useState(false);
//   const [drawerOpen, setDrawerOpen] = useState(true);
//   const [loading, setLoading] = useState(true);

//   // Ensure authentication is loaded before rendering the dashboard
//   useEffect(() => {
//     if (!currentUser) {
//       setTimeout(() => {
//         navigate('/');
//       }, 500); // Delay for smooth redirection
//     } else {
//       setLoading(false);
//     }
//   }, [currentUser, navigate]);

//   const handleDrawerToggle = () => {
//     setMobileOpen(!mobileOpen);
//   };

//   const renderContent = () => {
//     switch (selectedMenuItem) {
//       case 'pending':
//         return <PendingRentals />;
//       case 'declined':
//         return <DeclinedRentals />;
//       case 'history':
//         return currentUser?.userType === 'business' ? <RentalHistory /> : <DashboardOverview />;
//       case 'settings':
//         return <AccountSettings />;
//       case 'create-listing':
//         return currentUser?.userType === 'business' ? <CreateListing /> : <DashboardOverview />;
//       default:
//         return <DashboardOverview />;
//     }
//   };

//   // Show a loading spinner while waiting for authentication
//   if (loading) {
//     return (
//       <Box
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         height="100vh"
//       >
//         <CircularProgress />
//       </Box>
//     );
//   }

//   return (
//     <Root>
//       <CssBaseline />

//       <NavBar
//         handleDrawerToggle={handleDrawerToggle}
//         mobileOpen={mobileOpen}
//         cartItems={cartItems}
//         currentUser={currentUser}
//         setSelectedMenuItem={setSelectedMenuItem}
//       />

//       <CustomDrawer
//         mobileOpen={mobileOpen}
//         drawerOpen={drawerOpen}
//         handleDrawerToggle={handleDrawerToggle}
//         handleDrawerOpenClose={() => setDrawerOpen(!drawerOpen)}
//         selectedMenuItem={selectedMenuItem}
//         setSelectedMenuItem={setSelectedMenuItem}
//         userType={currentUser?.userType}
//       />

//       <Content>
//         <Toolbar />
//         {renderContent()}
//       </Content>
//     </Root>
//   );
// };

// export default Dashboard;

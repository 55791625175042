// SinglePostPageStyles.js



import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  postContainer: {
    backgroundColor: '#000',
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    height: '100%',
    color: '#fff',
    textAlign: 'left',
    lineHeight: '1.5',
    fontFamily: 'Arial, sans-serif',
  },

  bannerImage: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    marginBottom: '20px',
    boxShadow: '0 0 10px #23d5ab, 0 0 20px #f72585',
    borderRadius: '5px',
  },

  postTitle: {
    fontSize: '1.8rem',
    color: '#f72585',
    textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab',
    marginBottom: '10px',
    fontWeight: 'bold',
  },

  postDate: {
    fontSize: '0.9rem',
    color: '#aaa',
    fontStyle: 'italic',
    marginBottom: '15px',
  },

  postContent: {
    fontSize: '1rem',
    color: '#ddd',
    marginBottom: '30px',
    textAlign: 'left',
    lineHeight: '1.6',
    letterSpacing: '0.02em',
    padding: '10px',
    background: 'rgba(0, 0, 0, 0.6)',
    borderRadius: '5px',
    backdropFilter: 'blur(5px)',
    boxShadow: '0px 0px 5px rgba(35, 213, 171, 0.3)',
  },

  goBackButton: {
    backgroundColor: '#23d5ab',
    color: '#000',
    padding: '8px 20px',
    boxShadow: '0 0 10px #23d5ab',
    fontSize: '0.9rem',
    marginTop: '10px',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: '#f72585',
      color: '#fff',
      boxShadow: '0 0 15px #f72585',
      transform: 'scale(1.05)',
    },
  },

  socialIconsContainer: {
    marginTop: '20px',
    textAlign: 'center',
  },

  socialIcons: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  shareText: {
    marginBottom: '5px',
    color: '#23d5ab',
    fontSize: '0.9rem',
  },

  iconButton: {
    margin: '0 8px',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      color: '#23d5ab',
      transform: 'scale(1.1)',
    },
  },

  icon: {
    color: '#f72585',
    fontSize: '1.5rem',
    transition: 'color 0.3s ease-in-out, transform 0.3s ease-in-out',
    '&:hover': {
      color: '#23d5ab',
    },
  },

  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#000',
  },

  loadingText: {
    color: '#23d5ab',
    fontSize: '1rem',
    marginBottom: '10px',
  },

  loadingSpinner: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    border: '3px solid #23d5ab',
    borderTop: '3px solid transparent',
    animation: 'spin 1s linear infinite',
  },

  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
}));

export default useStyles;

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#000',
    minHeight: '100vh', 
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '20px',
    boxSizing: 'border-box',
    zIndex: 1,
    overflowY: 'auto',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  formBox: {
    backgroundColor: '#111',
    padding: '30px',
    borderRadius: '15px',
    boxShadow: '0 0 20px #23d5ab, 0 0 40px #f72585',
    maxWidth: '500px',
    width: '100%',
    textAlign: 'center',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  logoContainer: {
    marginBottom: '20px',
  },
  logo: {
    width: '150px',
    height: 'auto',
    animation: 'neon-glow 2s infinite alternate',
  },
  formTitle: {
    color: '#f72585',
    marginBottom: '20px',
    fontWeight: 'bold',
    textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab',
  },
  sectionTitle: {
    color: '#23d5ab',
    marginTop: '15px',
    fontWeight: 'bold',
  },

  /** ✅ Input Field Styling **/
  input: {
    backgroundColor: '#000',
    color: '#fff',
    marginBottom: '15px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#23d5ab',
      },
      '&:hover fieldset': {
        borderColor: '#f72585',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#23d5ab',
      },
    },
    '& .MuiInputBase-input': {
      color: '#fff',
    },
    '& .MuiInputLabel-outlined': {
      color: '#23d5ab !important',
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: '#f72585 !important',
    },
  },

  /** ✅ Updated Dropdown (Autocomplete) Styling **/
  dropdown: {
    backgroundColor: '#000',
    color: '#fff',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#23d5ab', 
      },
      '&:hover fieldset': {
        borderColor: '#f72585', 
      },
      '&.Mui-focused fieldset': {
        borderColor: '#23d5ab', 
      },
    },
    '& .MuiInputBase-input': {
      color: '#fff !important',
    },
    '& .MuiAutocomplete-input': {
      color: '#fff !important',
    },
    '& .MuiAutocomplete-popupIndicator': {
      color: '#23d5ab !important', // Dropdown arrow color
    },
    '& .MuiInputLabel-outlined': {
      color: '#23d5ab !important',
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: '#f72585 !important',
    },
  },

  /** ✅ Submit Button Styling **/
  submitButton: {
    backgroundColor: '#23d5ab',
    color: '#fff',
    marginTop: '20px',
    boxShadow: '0 0 10px #23d5ab, 0 0 20px #f72585',
    '&:hover': {
      backgroundColor: '#f72585',
      boxShadow: '0 0 20px #f72585, 0 0 30px #23d5ab',
    },
  },

  /** ✅ Switch Button **/
  switchButton: {
    marginTop: '20px',
    color: '#23d5ab',
    textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab',
  },

  /** ✅ Error Text **/
  errorText: {
    marginTop: '10px',
    color: '#ff0033', 
  },

  '@keyframes neon-glow': {
    '0%': {
      filter: 'drop-shadow(0 0 10px #f72585) drop-shadow(0 0 20px #23d5ab)',
    },
    '100%': {
      filter: 'drop-shadow(0 0 20px #f72585) drop-shadow(0 0 40px #23d5ab)',
    },
  },

  /** ✅ Mobile Optimization **/
  '@media (max-width: 600px)': {
    formBox: {
      padding: '20px',
      overflowY: 'visible',
    },
    input: {
      marginBottom: '10px',
    },
    dropdown: {
      marginBottom: '10px',
    },
  },
}));

export default useStyles;

